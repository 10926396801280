import { SheetAgreement } from './sheet-agreement'
import { SheetAuth } from './sheet-auth'
import { SheetChain } from './sheet-chain'
import { SheetConsent } from './sheet-consent'
import { SheetImageUploader } from './sheet-image-uploader'
import { SheetPasscode } from './sheet-passcode'
import { SheetPermissions } from './sheet-permissions'
import { SheetReceive } from './sheet-receive'


const Sheet = {
    Consent: SheetConsent,
    Permissions: SheetPermissions,
    ImageUploader: SheetImageUploader,
    Agreement: SheetAgreement,
    PassCode: SheetPasscode,
    Auth: SheetAuth,
    Receive: SheetReceive,
    Chain: SheetChain
}

export default Sheet
