import { atom, useSetAtom } from 'jotai'
import { nanoid } from 'nanoid'
import { useCallback } from 'react'

import Constants from '../libs/helper/helper.constants'
import { useSheetAuth } from './use-sheet-auth'


export interface ToastProps {
    id: string
    text: string
    subtext?: string
    icon?: string
    iconClassName?: string
    src?: string
    ttl?: number
    onClick?: () => void
}

export const toastAlarmsAtom = atom<ToastProps[]>([])

export const useToasts = () => {
    const setAlarms = useSetAtom(toastAlarmsAtom)
    const { showAuthSheet } = useSheetAuth()

    const showAlarm = useCallback((props: Omit<ToastProps, 'id'>) => {
        const alarm = { ...props, id: nanoid() }
        setAlarms((prev) => prev.length < 5 ? [alarm, ...prev] : [alarm, ...prev.slice(0, 4)])
    }, [setAlarms])

    const showOopsAlarm = useCallback(() => {
        showAlarm({
            icon: 'fluent-emoji:face-screaming-in-fear',
            text: 'Oops!',
            subtext: 'Something went wrong'
        })
    }, [showAlarm])

    const showCopiedAlarm = useCallback((text?: string) => {
        showAlarm({
            icon: 'mingcute:check-circle-line',
            text: text ?? 'Copied to clipboard'
        })
    }, [showAlarm])

    const showWrongPasswordAlarm = useCallback(() => {
        showAlarm({
            icon: 'fluent-emoji:thinking-face',
            text: 'Wrong password!',
            subtext: 'Please check your password'
        })
    }, [showAlarm])

    const showCreateWalletAlarm = useCallback(() => {
        const alarm = {
            id: Constants.createWalletToastId,
            icon: 'mingcute:key-2-fill',
            text: 'Create Your Wallet',
            subtext: 'Use it to manage and control your data securely',
            onClick: () => showAuthSheet(),
            ttl: 0
        }

        setAlarms((prev) => {
            const exists = prev.find(each => each.id === Constants.createWalletToastId)
            if (!exists) {
                return prev.length < 5 ? [alarm, ...prev] : [alarm, ...prev.slice(0, 4)]
            }
            return prev
        })
    }, [setAlarms, showAuthSheet])

    const hideCreateWalletAlarm = useCallback(() => {
        setAlarms((prev) => prev.filter(each => each.id !== Constants.createWalletToastId))
    }, [setAlarms])

    return {
        showAlarm,
        showOopsAlarm,
        showCopiedAlarm,
        showWrongPasswordAlarm,
        showCreateWalletAlarm,
        hideCreateWalletAlarm
    }
}
