export interface Adapter {
    id: string
    icon: string
    name: string
    description?: string
    scheme: AdapterScheme
    cta: string
    tokenizer: AdapterTokenizer
}

export interface AdapterScheme {
    android?: string
    googlePlay?: string
    apple?: string
    appStore?: string
    web?: string
}

export interface AdapterTokenizer {
    version: 'community' | 'alliance'
    rewards: AdapterRewards[]
    path: {
        profile: string
        consent?: string
        nonce?: string
        data: string
        play?: string
        task?: string
    }
    fallback?: {
        display?: { color: string, stroke: string, icon: string }
    }
}

export interface AdapterRewards {
    key: string
    unit: string
    value: string
    repeat: boolean
}

export interface AdapterProfile {
    username?: string
    displayUrl?: string
    collected?: number
    updatedAt?: number
    availableAt?: number
    tz?: number

    [key: string]: any
}

export interface AdapterConsent {
    status: boolean
    timestamp: number
    signature?: string
}

export interface AdapterTask {
    id: string
    uid: string
    status: AdapterStatus
    timestamp?: number
    points?: string
}

export enum AdapterStatus {
    Error = 'error',
    Request = 'req',
    Running = 'run',
    Ok = 'ok',
}
