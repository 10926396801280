import { HDNodeWallet, Mnemonic } from 'ethers'
import { isEmpty } from 'lodash'
import secureLocalStorage from 'react-secure-storage'

import { bridgeCall, BridgeSig } from '../../hooks/use-bridge'
import { Keyring, Share1, Share2, Share3 } from '../types/account.types'
import Constants from './helper.constants'
import { combineSecretShares, decryptData } from './helper.crypto'
import { isWebEnv } from './helper.env'
import logger from './helper.logger'


export const getCachedKeyring = async (): Promise<Keyring | null> => {
    if (isWebEnv) {
        const value = secureLocalStorage.getItem(Constants.keyring)
        return value !== null ? value as Keyring : null
    }
    return await bridgeCall(BridgeSig.keyringGet)
}

export const setCachedKeyring = async (keyring: Keyring) => {
    if (isWebEnv) {
        secureLocalStorage.setItem(Constants.keyring, keyring)
        return
    }
    return await bridgeCall(BridgeSig.keyringSet, keyring)
}

export const clearCachedKeyring = async () => {
    if (isWebEnv) {
        secureLocalStorage.removeItem(Constants.keyring)
        return
    }
    return await bridgeCall(BridgeSig.keyringClear)
}

export const getKeyringShare1 = async (): Promise<string> => {
    if (isWebEnv) {
        const value = secureLocalStorage.getItem(Constants.keyringShare1)
        return value !== null ? value as string : ''
    }
    return await bridgeCall(BridgeSig.keyringGetShare1) ?? ''
}

export const setKeyringShare1 = async (share1: string) => {
    if (isWebEnv) {
        secureLocalStorage.setItem(Constants.keyringShare1, share1)
        return
    }
    return await bridgeCall(BridgeSig.keyringSetShare1, { share1 })
}

export const clearKeyringShare1 = async () => {
    if (isWebEnv) {
        secureLocalStorage.removeItem(Constants.keyringShare1)
        return
    }
    return await bridgeCall(BridgeSig.keyringClearShare1)
}

export const validateKeyring = async (keyring: Keyring | null): Promise<Keyring | null> => {
    if (!keyring) return null
    if (isEmpty(keyring.s0)) return null
    if (isEmpty(keyring.avatar)) return null
    if (isEmpty(keyring.name)) return null
    try {
        const mnemonic = Mnemonic.fromEntropy(keyring.s0)
        const wallet = HDNodeWallet.fromMnemonic(mnemonic)
        return wallet.address.toLowerCase() === keyring.address.toLowerCase() ? keyring : null
    } catch (e) {
        console.log('spawnWallet', e)
        return null
    }
}

export const tryRecoverKeyringShares = async (shares: Partial<Share1 & Share2 & Share3>, passcode?: string): Promise<Keyring | null> => {
    if (isEmpty(shares.avatar)) return null
    if (isEmpty(shares.name)) return null
    if (!shares.s2 || isEmpty(shares.s2)) return null

    if (passcode && shares.s3encrypted && !isEmpty(shares.s3encrypted)) {
        try {
            const s3 = await decryptData(shares.s3encrypted, passcode)
            const entropy = await combineSecretShares([shares.s2, s3])
            const mnemonic = Mnemonic.fromEntropy(entropy)
            const wallet = HDNodeWallet.fromMnemonic(mnemonic)
            if (wallet.address.toLowerCase() !== shares.address?.toLowerCase()) return null

            return {
                address: shares.address,
                avatar: shares.avatar,
                name: shares.name,
                uid: shares.uid,
                s0: entropy
            } as Keyring
        } catch (e) {
            logger.error('recoverKeyringShares:s2+s3', e)
            return null
        }
    }

    try {
        if (!shares.s1 || isEmpty(shares.s1)) return null

        const entropy = await combineSecretShares([shares.s2, shares.s1])
        const mnemonic = Mnemonic.fromEntropy(entropy)
        const wallet = HDNodeWallet.fromMnemonic(mnemonic)
        if (wallet.address.toLowerCase() !== shares.address?.toLowerCase()) return null

        return {
            address: shares.address,
            avatar: shares.avatar,
            name: shares.name,
            uid: shares.uid,
            s0: entropy
        } as Keyring
    } catch (e) {
        logger.error('recoverKeyringShares:s2+s1', e)
        return null
    }
}

export const tryValidatePasscode = async (address: string, s3encrypted: string, passcode: string): Promise<boolean> => {
    const s1 = await getKeyringShare1()
    try {
        const s3 = await decryptData(s3encrypted, passcode)
        const entropy = await combineSecretShares([s1, s3])
        const mnemonic = Mnemonic.fromEntropy(entropy)
        const wallet = HDNodeWallet.fromMnemonic(mnemonic)
        return wallet.address.toLowerCase() !== address.toLowerCase()
    } catch (e) {
        logger.error('recoverKeyringShares:s2+s3', e)
        return false
    }
}
