import { AnimatePresence } from 'framer-motion'
import React from 'react'

import { NrcPageCollect } from './nrc.page-collect'


export const AdapterNrcSteps = () => {
    return (
        <AnimatePresence initial={false}>
            <NrcPageCollect/>
        </AnimatePresence>
    )
}
