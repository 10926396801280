import { Icon } from '@iconify/react'
import React, { useCallback } from 'react'
import { useNavigate } from 'react-router-dom'

import { Adapters } from '../../adapter/adapter.list'
import Badge from '../../components/badge'
import Button from '../../components/button'
import Card from '../../components/card'
import Label from '../../components/label'
import Page from '../../components/page'
import Profile from '../../components/profile'
import { useAdapterConsent } from '../../hooks/use-adapter'
import { useBridgeClipboard } from '../../hooks/use-bridge'
import { useKeyring } from '../../hooks/use-keyring'
import { useSheetAgreement } from '../../hooks/use-sheet-agreement'
import { useSheetConsent } from '../../hooks/use-sheet-consent'
import { cn } from '../../libs/utils'


export const ViewMainSettings = () => {
    const navigate = useNavigate()
    const { keyring } = useKeyring()

    const { consentOf, updateConsentOf } = useAdapterConsent()
    const { showSignOutAgreementAsync, showOptOutAgreementAsync } = useSheetAgreement()
    const { showConsentSheetAsync } = useSheetConsent()

    const { clipboardSet } = useBridgeClipboard()
    const { signOut } = useKeyring()

    const handleCopyAddress = useCallback(async () => {
        if (!keyring) return
        await clipboardSet(keyring.address, 'Copied!')
    }, [clipboardSet, keyring])

    const handleSignOut = useCallback(async () => {
        const agreed = await showSignOutAgreementAsync()
        if (!agreed) return

        await signOut()
        setTimeout(() => navigate('/'), 30)
    }, [navigate, showSignOutAgreementAsync, signOut])

    const renderAdapters = useCallback(() => {
        return Adapters.map((adapter, idx: number) => {
            const pathConsent = adapter.tokenizer.path.consent
            if (!pathConsent) return null

            const consent = consentOf(adapter.id)
            return (
                <Card.Adapter
                    key={`${adapter.id}+${idx}`}
                    adapter={adapter}
                    action={
                        <Button.Switch
                            checked={consent?.status === true}
                            onBeforeOn={async () => showConsentSheetAsync({ adapterId: adapter.id })}
                            onBeforeOff={showOptOutAgreementAsync}
                            onChange={(value => updateConsentOf(adapter.id, value))}
                        />
                    }/>
            )
        })
    }, [consentOf, showConsentSheetAsync, showOptOutAgreementAsync, updateConsentOf])

    return (
        <Page.AnimatedTab>
            <div className={cn(
                'w-full',
                'flex flex-col gap-y-4'
            )}>

                <Badge.Chain/>

                {/* Account */}
                <div className='flex items-start gap-x-2 mx-auto mt-12 mb-4'>
                    <div className='relative'>
                        <Profile.Avatar avatar={keyring?.avatar} size='lg' className='shadow-lg'/>
                        <Button.Solid className={cn(
                            'absolute -bottom-1.5 -left-1.5',
                            'flex justify-center items-center gap-x-1'
                        )} type='button'>
                            <Icon icon='mingcute:edit-2-line' className='size-4'/>
                        </Button.Solid>
                    </div>
                    <div className='w-full flex flex-col gap-y-2'>
                        <Profile.Name name={keyring?.name} size='lg'/>
                        <Card.Blurry className='w-fit shadow-sm'>
                            <Profile.Address address={keyring?.address} format='chunk4' className='text-xs'/>
                        </Card.Blurry>

                        <div className='flex items-center gap-x-2'>
                            <Button.Solid className={cn(
                                'flex justify-center items-center gap-x-1'
                            )} onClick={handleCopyAddress}>
                                <Icon icon='mingcute:copy-2-line' className='size-4'/>
                                <span>Copy</span>
                            </Button.Solid>
                        </div>
                    </div>
                </div>

                {/* Data Channel */}
                <div className='w-full flex flex-col gap-y-2'>
                    <Label.Section text={'Data Channel'} subtext={'Control the monetization of the Data Channels'}/>
                    {renderAdapters()}
                </div>

                {/* TODO passcode > recovery */}
                <div className='w-full flex flex-col gap-y-2 hidden'>
                    <Label.Section text={'Security & Privacy'} subtext={'Keep your wallet secure'}/>

                    <Button.Solid className={cn(
                        'flex justify-between items-center gap-x-1',
                        'pl-4 py-3'
                    )}>
                        <span>Passcode Setup</span>
                        <Icon icon={'heroicons:chevron-right-solid'}/>
                    </Button.Solid>

                    <Button.Solid className={cn(
                        'flex justify-between items-center gap-x-1',
                        'pl-4 py-3'
                    )}>
                        <span>Reveal Secret Recovery Phrase</span>
                        <Icon icon={'heroicons:chevron-right-solid'}/>
                    </Button.Solid>
                </div>

                <div className='w-full flex flex-col gap-y-4'>
                    <Label.Section text={'Danger Zone'} subtext={'The wallet may not be recoverable'}/>
                    <Button.Solid
                        className={cn(
                            'w-fit',
                            'flex justify-center items-center gap-x-1',
                            'text-rose-600',
                            'mx-auto'
                        )}
                        onClick={handleSignOut}
                    >
                        <Icon icon='mingcute:delete-2-line' className='size-4'/>
                        <span>Sign Out</span>
                    </Button.Solid>
                </div>
            </div>
        </Page.AnimatedTab>
    )
}
