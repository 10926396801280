import { Adapter } from '../adapter.types'


export const AdapterNRC: Adapter = {
    id: 'nrc',
    icon: 'https://play-lh.googleusercontent.com/SPY3nBA8Mfe26jojaOFmqKYMOYKr5_u0GFq1sRKOmbsEboWmw4gJdLyf1ihQyfhLXLA=s64-rw',
    name: 'Nike Run Club',
    cta: 'Collect Run',
    scheme: {
        android: 'com.nike.plusgps',
        googlePlay: 'https://play.google.com/store/apps/details?id=com.nike.plusgps',
        // apple: '', // TODO
        appStore: 'https://apps.apple.com/app/nike-run-club-running-coach/id387771637'
    },
    tokenizer: {
        version: 'community',
        rewards: [{ key: 'collected', unit: 'Run', value: '30', repeat: true }],
        path: {
            profile: 'nrc-user',
            consent: 'nrc-consent',
            data: 'nrc-data'
        },
        fallback: {
            display: { color: '#1e1e24', stroke: '#ffffff', icon: 'mingcute:foot-fill' }
        }
    }
}
