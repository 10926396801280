import getUserLocale from 'get-user-locale'
import moment from 'moment/moment'

import { bridgeCall, BridgeSig } from '../../hooks/use-bridge'
import { DeviceMetadata, PermissionsKey, PermissionsStatus } from '../types/device.types'
import { isWebEnv } from './helper.env'


export const getDeviceMetadata = async (): Promise<DeviceMetadata> => {
    if (isWebEnv) {
        return {
            locale: getUserLocale(),
            tz: moment().utcOffset()
        }
    }
    return await bridgeCall(BridgeSig.metadata)
}

export const permissionOf = async (key: PermissionsKey): Promise<PermissionsStatus> => {
    if (isWebEnv) return PermissionsStatus.permanentlyDenied
    const status = await bridgeCall(BridgeSig.permissionStatus, { key })
    return status ?? PermissionsStatus.permanentlyDenied
}

export const requestPermissionOf = async (key: PermissionsKey): Promise<PermissionsStatus> => {
    if (isWebEnv) return PermissionsStatus.permanentlyDenied
    const status = await bridgeCall(BridgeSig.permissionRequest, { key })
    return status ?? PermissionsStatus.permanentlyDenied
}

export const openAppSettings = async (key: PermissionsKey): Promise<boolean> => {
    if (isWebEnv) return true
    return await bridgeCall(BridgeSig.permissionSettings, { key })
}

export const getFcmToken = async (): Promise<string> => {
    if (isWebEnv) return ''
    return await bridgeCall(BridgeSig.permissionFcmToken) ?? ''
}

export const getAttToken = async (): Promise<string> => {
    if (isWebEnv) return ''
    return await bridgeCall(BridgeSig.permissionAttToken) ?? ''
}
