import { atom, useSetAtom } from 'jotai'
import { useCallback } from 'react'

import { PermissionsKey, PermissionsStatus } from '../libs/types/device.types'


export interface PermissionsSheetProps {
    key: PermissionsKey
    icon?: string
    text?: string
    subtext?: string
    confirm?: string
    onConfirm?: (key: PermissionsKey, status: PermissionsStatus) => PermissionsStatus
}

export const permissionsSheetPropsAtom = atom<PermissionsSheetProps | null>()

export const useSheetPermissions = () => {
    const setPermissionsSheetProps = useSetAtom(permissionsSheetPropsAtom)

    const templatedPropsOf = useCallback((key: PermissionsKey, others: Omit<PermissionsSheetProps, 'key'> = {}) => {
        const props: PermissionsSheetProps = { ...others, key }
        switch (key) {
            case PermissionsKey.notification:
                props.icon = 'mingcute:notification-newdot-fill'
                props.text = 'Enable notifications'
                props.subtext = 'Turn on notifications so you won’t miss any new challenges!'
                props.confirm = 'Turn on notification'
                break
            case PermissionsKey.camera:
                props.icon = 'mingcute:camera-2-ai-fill'
                props.text = 'Enable the camera feature'
                props.subtext = 'Use the camera feature to join in on a variety of challenges!'
                props.confirm = 'Continue'
                break
            case PermissionsKey.photos:
                props.icon = 'mingcute:photo-album-fill'
                props.text = 'Allow access to your photo album'
                props.subtext = 'Browse your album to find photos eligible for data points.'
                props.confirm = 'Continue'
                break
            default:
                break
        }
        return props
    }, [])

    const showPermissionsSheet = useCallback((props: PermissionsSheetProps) => {
        setPermissionsSheetProps(props)
    }, [setPermissionsSheetProps])

    const showPermissionsSheetAsync = useCallback((props: PermissionsSheetProps): Promise<PermissionsStatus> => {
        return new Promise((resolve) => {
            setPermissionsSheetProps({
                ...props,
                onConfirm: (key, status) => {
                    let result = status
                    try {
                        if (props.onConfirm) {
                            result = props.onConfirm(key, status)
                        }
                        return result
                    } catch (e) {
                        return PermissionsStatus.denied
                    } finally {
                        resolve(result)
                    }
                }
            })
        })
    }, [setPermissionsSheetProps])

    const showPermissionsSheetOf = useCallback((key: PermissionsKey, others: Omit<PermissionsSheetProps, 'key'> = {}) => {
        showPermissionsSheet(templatedPropsOf(key, others))
    }, [showPermissionsSheet, templatedPropsOf])

    const showPermissionsSheetAsyncOf = useCallback((key: PermissionsKey, others: Omit<PermissionsSheetProps, 'key'> = {}): Promise<PermissionsStatus> => {
        return showPermissionsSheetAsync(templatedPropsOf(key, others))
    }, [showPermissionsSheetAsync, templatedPropsOf])

    const hidePermissionsSheet = useCallback(() => {
        setPermissionsSheetProps(null)
    }, [setPermissionsSheetProps])

    return {
        showPermissionsSheet,
        showPermissionsSheetOf,
        showPermissionsSheetAsync,
        showPermissionsSheetAsyncOf,
        hidePermissionsSheet
    }
}
