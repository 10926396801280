import React from 'react'

import { cn } from '../../libs/utils'
import { PageAnimated, PageAnimatedProps } from './page-animated'


const MotionVariants = {
    initial: (forward: boolean) => ({ opacity: 0, y: forward ? '30%' : '-30%' }),
    animate: { opacity: 1, y: '0%' },
    exit: (forward: boolean) => ({ opacity: 0, y: forward ? '-30%' : '30%' })
}

export interface PageAnimatedOnboardProps extends PageAnimatedProps {
    forward?: any
}

export const PageAnimatedOnboarding = ({
    forward = true,
    children,
    ...props
}: PageAnimatedOnboardProps) => {
    return (
        <PageAnimated
            className={cn(
                'flex flex-col justify-center items-center',
                'p-4',
                props.className
            )}
            key={props.key}
            variants={MotionVariants}
            custom={forward}
            initial='initial'
            animate='animate'
            exit='exit'
        >
            {children}
        </PageAnimated>
    )
}
