import { Icon } from '@iconify/react'
import { useIsPresent } from 'framer-motion'
import { head } from 'lodash'
import React, { useCallback, useEffect, useRef, useState } from 'react'
import { object, string, ValidationError } from 'yup'

import Button from '../../components/button'
import { PageAnimatedOnboarding } from '../../components/page/page-animated-onboarding'
import Profile from '../../components/profile'
import { useSheetPasscode } from '../../hooks/use-sheet-passcode'
import { cn } from '../../libs/utils'


const Schema = object().shape({
    name: string().min(3, 'Too short!').max(16, 'Too long!').required()
})

interface PageCreateProps {
    avatar: string
    onShuffle: () => void
    onNext: (name: string, passcode: string) => void
}

export const PageCreate = ({
    avatar,
    onShuffle,
    onNext
}: PageCreateProps) => {
    const inputRef = useRef<HTMLInputElement | null>(null)
    const isPresent = useIsPresent()

    const [inputs, setInputs] = useState({ name: '' })
    const [errors, setErrors] = useState({ name: undefined })

    const { showPasscodeSheet } = useSheetPasscode()

    useEffect(() => {
        if (isPresent && inputRef.current) {
            setTimeout(() => {
                inputRef.current!.focus()
            }, 600)
        }
    }, [isPresent])

    const handleChange = useCallback((e: React.FormEvent<HTMLInputElement>) => {
        const key = e.currentTarget.name
        const value = e.currentTarget.value

        setInputs((prev) => {
            return { ...prev, [key]: value }
        })
    }, [])

    const handleConfirm = useCallback(async () => {
        const values = await Schema.validate({
            name: inputs.name.trim()
        }).catch((e: ValidationError) => {
            setErrors((prev) => {
                return { ...prev, [e.path?.toString() ?? '']: head(e.errors) }
            })
        })

        if (!values) return
        setErrors({ name: undefined })

        showPasscodeSheet({
            mode: 'create',
            text: 'Enter your wallet password',
            onConfirm: async (passcode: string) => {
                onNext(values.name, passcode)
                return [true]
            }
        })
    }, [inputs, onNext, showPasscodeSheet])

    return (
        <PageAnimatedOnboarding key={'PageProfile'}>
            <div className={cn(
                'absolute top-[15%]',
                'w-full',
                'flex flex-col justify-center items-start',
                'px-4'
            )}>
                <div className='text-4xl font-semibold text-slate-900/15'>
                    Wallet Profile
                </div>
                <div className='text-xl font-normal text-slate-900/60'>
                    Choose an avatar for your wallet
                </div>
            </div>

            <div className={cn(
                'flex items-center gap-x-2'
            )}>
                <div className='relative'>
                    <Profile.Avatar avatar={avatar} size='lg' className='shadow-lg'/>
                    <Button.Solid className={cn(
                        'absolute -bottom-1.5 -left-1.5',
                        'flex justify-center items-center gap-x-1'
                    )} type='button' onClick={onShuffle}>
                        <Icon icon='mingcute:refresh-3-line' className='size-4'/>
                    </Button.Solid>
                </div>
                <div className='flex flex-col'>
                    <div className={cn(
                        'rounded-2xl',
                        'px-3 pb-1.5 pt-2.5',
                        'bg-white',
                        'ring-1 ring-inset ring-transparent focus-within:ring-2 focus-within:ring-cyan-500',
                        'mt-7'
                    )}>
                        <label htmlFor='name' className='block text-sm font-medium text-slate-600'>
                            username
                        </label>
                        <input
                            name='name'
                            type='text'
                            placeholder='...'
                            className={cn(
                                'block',
                                'w-full',
                                'border-0 p-0',
                                'text-gray-900',
                                'placeholder:text-gray-400',
                                'focus:ring-0 focus:outline-0',
                                'text-xl'
                            )}
                            style={{ outline: 'none' }}
                            maxLength={16}
                            ref={inputRef}
                            value={inputs.name}
                            onChange={handleChange}
                        />
                    </div>
                    <div className='w-full text-sm text-slate-900 h-6 mt-1 ml-2.5'>
                        {errors.name && <div>{errors.name}</div>}
                    </div>
                </div>
            </div>

            <div className={cn(
                'max-w-screen-sm mx-auto',
                'fixed bottom-0 left-0 right-0 p-4',
                'bg-gradient-to-t from-white to-transparent'
            )}>
                <Button.Solid className={cn(
                    'w-full flex justify-center items-center',
                    'bg-cyan-500 text-white font-semibold'
                )} onClick={handleConfirm}>
                    <span className='text-lg'>Continue</span>
                </Button.Solid>
            </div>
        </PageAnimatedOnboarding>
    )
}
