import { Icon } from '@iconify/react'
import { isEmpty } from 'lodash'
import moment from 'moment/moment'
import { nanoid } from 'nanoid'
import React, { useCallback, useEffect, useMemo, useState } from 'react'
import Countdown from 'react-countdown'
import { useInView } from 'react-intersection-observer'
import { useNavigate, useSearchParams } from 'react-router-dom'

import AssetAnimation from '../../assets/animation'
import AssetPlaceholder from '../../assets/placeholder'
import Button from '../../components/button'
import Card from '../../components/card'
import Label from '../../components/label'
import Page from '../../components/page'
import Profile from '../../components/profile'
import Sheet from '../../components/sheet'
import { ImageUploaderPayload } from '../../components/sheet/sheet-image-uploader'
import Spinner from '../../components/spinner'
import { useAccount } from '../../hooks/use-account'
import { useActiveAdapter, useAdapterDataQuery } from '../../hooks/use-adapter'
import { usePopupLoader, usePopupPoints } from '../../hooks/use-popup'
import { useSheetConsent } from '../../hooks/use-sheet-consent'
import { useToasts } from '../../hooks/use-toasts'
import { callableOf } from '../../libs/firebase'
import logger from '../../libs/helper/helper.logger'
import { cn, extractMetadataFromBase64 } from '../../libs/utils'
import Zing from '../../libs/zing'
import { AdapterDataPlaceholder } from '../adapter-data-placeholder'
import { NrcDataCell } from './nrc.data-cell'
import { NrcData, NrcDataCall } from './nrc.types'


export const NrcPageCollect = () => {
    const navigate = useNavigate()
    const [searchParams] = useSearchParams()

    const { adapterId, profile, consent } = useActiveAdapter()
    const { uid } = useAccount()

    const [openUploader, setOpenUploader] = useState(false)
    const [showLoader, closeLoader] = usePopupLoader()
    const [showPoints] = usePopupPoints()
    const { showAlarm } = useToasts()

    const [submitting, setSubmitting] = useState(false)
    const { showConsentSheetAsync } = useSheetConsent()

    const query = useAdapterDataQuery<NrcData>(adapterId)
    const [inViewRef, inView] = useInView()

    const availableAt = useMemo(() => {
        return moment.unix(profile?.availableAt ?? 0)
    }, [profile?.availableAt])

    const handleUpload = useCallback(async (payload: ImageUploaderPayload) => {
        if (availableAt.isAfter(moment())) return
        showLoader()

        try {
            const id = nanoid()
            const { ext } = extractMetadataFromBase64(payload.imageData)
            const path = `${uid}/nrc/run_${id}.${ext}`
            const displayUrl = await Zing.storage.uploadDataUrl(path, payload.imageData)
            if (isEmpty(displayUrl)) {
                closeLoader()
                showAlarm({
                    icon: 'fluent-emoji:face-screaming-in-fear',
                    text: 'Something went wrong',
                    subtext: 'Please try again in a moment.'
                })
                return
            }

            const call = callableOf<NrcDataCall, NrcData>('nrc-data')
            const result = await call({
                id, displayUrl, tz: moment().utcOffset()
            })

            if (!result.data) {
                closeLoader()
                showAlarm({
                    icon: 'fluent-emoji:face-screaming-in-fear',
                    text: 'Something went wrong',
                    subtext: 'Please try again in a moment.'
                })
                return
            }

            await query.invalidate()
            showPoints(result.data)
        } catch (e) {
            console.error('handleUpload', e)
            showAlarm({
                icon: 'fluent-emoji:face-screaming-in-fear',
                text: 'Something went wrong',
                subtext: 'Please try again in a moment.'
            })
        }
    }, [availableAt, closeLoader, query, showAlarm, showLoader, showPoints, uid])

    const handleCollect = useCallback(async () => {
        let consented = consent?.status === true
        if (!consented) {
            consented = await showConsentSheetAsync({ adapterId })
        }

        if (!consented || submitting) return
        setSubmitting(true)

        try {
            setOpenUploader(true)
        } catch (e) {
            logger.error('handleCollect', e)
        } finally {
            setSubmitting(false)
        }
    }, [adapterId, consent?.status, showConsentSheetAsync, submitting])

    useEffect(() => {
        if (inView) {
            query.next()
        }
    }, [inView, openUploader, query])

    useEffect(() => {
        if (!isEmpty(searchParams.get('restore'))) {
            setOpenUploader(true)
        }
    }, [searchParams])

    return (
        <React.Fragment>
            <Page.AnimatedNested key={'ig.page-collect'}>
                <div className={cn(
                    'w-full',
                    'flex flex-col justify-start items-start gap-y-4'
                )}>
                    <Profile.Adapter adapterId={adapterId}/>

                    {/* TODO gamification for NRC */}
                    <div className='w-full flex flex-col gap-y-2 hidden'>
                        <Label.Section text={'Analyzing'} size='2xl' className='ml-2'/>
                        <Card.Blurry className={cn(
                            'text-sm'
                        )}>
                            <div className={cn(
                                'w-full',
                                'flex flex-col justify-center items-center gap-y-2',
                                'text-center'
                            )}>
                                <img src={AssetAnimation.Robot} className='size-12' alt='AI Robot'/>
                                <p className='text-sm'>
                                    Let our AI dive into your latest posts <br/>
                                    to uncover your interests!
                                </p>

                                <Button.Solid className={cn(
                                    'w-full flex justify-center items-center',
                                    'bg-cyan-500 text-white font-semibold'
                                )} onClick={() => navigate('play')}>
                                    <span>Start Analyzing</span>
                                </Button.Solid>
                            </div>
                        </Card.Blurry>
                    </div>

                    <div className='w-full flex flex-col gap-y-2'>
                        <Label.Section text={'Data Collection'} size='2xl' className='ml-2'/>
                        {
                            !query.hasData && (
                                <AdapterDataPlaceholder
                                    text={'Collect Your Run'}
                                    subtext={'Upload a screenshot of your activity\nfrom Nike Run Club'}
                                />
                            )
                        }
                        {
                            query.data?.pages.map((group, i) => (
                                <React.Fragment key={i}>
                                    {
                                        group.items.map((item) => {
                                            return (
                                                <NrcDataCell key={item.id} data={item}/>
                                            )
                                        })
                                    }
                                </React.Fragment>
                            ))
                        }
                    </div>
                    <div ref={inViewRef as any}/>
                </div>
            </Page.AnimatedNested>

            <div className={cn(
                'fixed bottom-0 left-0 right-0 p-4',
                'bg-gradient-to-t from-white to-transparent',
                'flex justify-center items-center'
            )}>
                <Button.Solid
                    className={cn(
                        'relative size-16',
                        'flex justify-center items-center',
                        'bg-cyan-500 text-white font-semibold',
                        'rounded-full',
                        'shadow-xl shadow-black/15'
                    )}
                    onClick={handleCollect}
                    disabled={availableAt.isAfter(moment())}
                >
                    {
                        availableAt.isAfter(moment())
                        ? (
                            <div className='flex flex-col justify-center items-center'>
                                <Icon icon={'mingcute:check-fill'} className='size-6'/>
                                <Countdown date={availableAt.valueOf()} daysInHours={true} className='text-xs'/>
                            </div>
                        )
                        : (
                            <React.Fragment>
                                {
                                    submitting
                                    ? <Spinner.Blocks className='size-6 text-white'/>
                                    : <Icon icon={'mingcute:add-fill'} className='size-7'/>
                                }
                            </React.Fragment>
                        )
                    }
                </Button.Solid>
            </div>

            <Sheet.ImageUploader
                open={openUploader}
                setOpen={setOpenUploader}
                placeholder={AssetPlaceholder.Nrc}
                title={'Upload Screenshot'}
                onUpload={handleUpload}
                tag={adapterId}
            />
        </React.Fragment>
    )
}
