import { ZeroAddress } from 'ethers'

import { IToken } from '../types/blockchain.types'


export const TokenMap: { [key: string]: IToken } = {
    ethereum: {
        id: 'ethereum',
        name: 'Ethereum',
        symbol: 'ETH',
        image: 'https://assets.coingecko.com/coins/images/279/standard/ethereum.png',
        decimals: 18,
        address: {
            11155111: ZeroAddress,
            534352: ZeroAddress
        }
    },
    scroll: {
        id: 'scroll',
        name: 'Scroll',
        symbol: 'SCR',
        image: 'https://assets.coingecko.com/coins/images/50571/standard/scroll.jpg',
        decimals: 18,
        address: {
            534352: '0xd29687c813D741E2F938F4aC377128810E217b1b' // scroll
        }
    },
    bitcoin: {
        id: 'bitcoin',
        name: 'Bitcoin',
        symbol: 'BTC',
        image: 'https://assets.coingecko.com/coins/images/1/standard/bitcoin.png',
        decimals: 8,
        address: {
            11155111: '0x66194F6C999b28965E0303a84cb8b797273B6b8b', // sepolia
            534352: '0x3C1BCa5a656e69edCD0D4E36BEbb3FcDAcA60Cf1' // scroll
        }
    },
    tether: {
        id: 'tether',
        name: 'Tether',
        symbol: 'USDT',
        image: 'https://coin-images.coingecko.com/coins/images/325/standard/Tether.png',
        decimals: 6,
        address: {
            11155111: '0x419Fe9f14Ff3aA22e46ff1d03a73EdF3b70A62ED', // sepolia
            534352: '0xf55BEC9cafDbE8730f096Aa55dad6D22d44099Df' // scroll
        }
    }
}

export const TokenWatchlist: { [key: number]: IToken[] } = {
    534352: [
        TokenMap.ethereum,
        TokenMap.scroll,
        TokenMap.bitcoin
    ],
    11155111: [
        TokenMap.ethereum,
        TokenMap.tether,
        TokenMap.bitcoin
    ]
}

export default TokenMap
