import { Icon } from '@iconify/react'
import React, { HTMLAttributes } from 'react'

import { useActiveChain } from '../../hooks/use-chain'
import { cn } from '../../libs/utils'


export interface BadgeChainProps extends HTMLAttributes<HTMLDivElement> {

}

export const BadgeChain = ({
    ...props
}: BadgeChainProps) => {
    const { activeChain } = useActiveChain()

    return (
        <div className={cn(
            'w-fit',
            'inline-flex items-center gap-x-1',
            'rounded-full',
            'px-2 py-1',
            'text-sm font-medium',
            'bg-white/80 backdrop-blur-lg',
            'ring-1 ring-inset ring-slate-200',
            'cursor-pointer',
            props.className
        )} onClick={props.onClick}>
            <img src={activeChain.image} className={cn(
                'size-6',
                'bg-white',
                'rounded-full overflow-hidden'
            )} alt=''/>

            <span className='text-sm text-slate-900 font-medium'>
                {activeChain.name}
                {activeChain.testnet === true && ' Testnet'}
            </span>
            <Icon icon={'heroicons:chevron-down'} className='text-slate-900'/>
        </div>
    )
}
