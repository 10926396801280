import WCLogo from './wallet-connect.jpg'
import ZingLogo from './zing.jpg'
import ZingTextLogo from './zing-text.svg'


const AssetLogo = {
    Zing: ZingLogo as string,
    ZingText: ZingTextLogo as string,
    WalletConnect: WCLogo as string
}

export default AssetLogo
