import { isEmpty } from 'lodash'
import React, { createContext, useCallback, useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import secureLocalStorage from 'react-secure-storage'

import { useSheetPasscode } from '../../hooks/use-sheet-passcode'
import { splitSecretToShares } from '../../libs/helper/helper.crypto'
import { isNativeEnv } from '../../libs/helper/helper.env'
import { clearCachedKeyring, clearKeyringShare1, getCachedKeyring, setCachedKeyring, setKeyringShare1, tryRecoverKeyringShares, validateKeyring } from '../../libs/helper/helper.keyring'
import { Keyring } from '../../libs/types/account.types'
import Zing from '../../libs/zing'
import ViewPending from '../../views/view-pending'


export enum KeyringState {
    Failed = -1,
    Undetermined = 0,
    KeyringRequired = 1,
    KeyringLoaded = 2,
}

export interface KeyringContextProps {
    state: KeyringState
    keyring: Keyring | null
    initialize: () => Promise<KeyringState>
    signOut: () => Promise<void>
}

export const Context = createContext<KeyringContextProps>({
    state: KeyringState.Undetermined,
    keyring: null,
    initialize(): Promise<KeyringState> {
        return Promise.resolve(KeyringState.Undetermined)
    },
    signOut(): Promise<void> {
        return Promise.resolve(undefined)
    }
})

const KeyringProvider: React.FC<{ children: React.ReactNode }> = ({ children }) => {
    const navigate = useNavigate()
    const [attached, setAttached] = useState(false)
    const [state, setState] = useState<KeyringState>(KeyringState.Undetermined)
    const [keyring, setKeyring] = useState<Keyring | null>(null)
    
    const { showPasscodeSheetAsync } = useSheetPasscode()
    
    const initialize = useCallback(async () => {
        await Zing.auth.stateReady()
        const user = Zing.auth.currentUser()
        setAttached(true)
        
        if (!user) {
            setKeyring(null)
            setState(KeyringState.KeyringRequired)
            return KeyringState.KeyringRequired
        }
        
        /// Recover with cached keyring
        const cached = await getCachedKeyring()
        let keyring = await validateKeyring(cached)
        if (keyring !== null) {
            setKeyring(keyring)
            setState(KeyringState.KeyringLoaded)
            return KeyringState.KeyringLoaded
        }
        
        /// Check if keyring shares exist
        const shares = await Zing.database.getKeyringShares(user.uid)
        if (!shares.s2 || isEmpty(shares.s2)) {
            setKeyring(null)
            setState(KeyringState.KeyringRequired)
            
            navigate('/keyring')
            return KeyringState.KeyringRequired
        }
        
        /// Recover with shares: s2(social) + s1(device)
        keyring = await tryRecoverKeyringShares(shares)
        if (keyring !== null) {
            setKeyring(keyring)
            setState(KeyringState.KeyringLoaded)
            
            const [s1] = await splitSecretToShares(keyring.s0)
            await Promise.all([setCachedKeyring(keyring), setKeyringShare1(s1)])
            return KeyringState.KeyringLoaded
        }
        
        /// Recover with shares: s2 + s3
        const [result, recovered] = await showPasscodeSheetAsync({
            text: 'Wallet Recovery',
            subtext: 'Enter your wallet password',
            dismissible: true,
            onConfirm: async (passcode: string) => {
                let recovered = await tryRecoverKeyringShares(shares, passcode)
                return [recovered !== null, recovered]
            }
        })
        
        keyring = recovered
        if (result && keyring !== null) {
            setKeyring(keyring)
            setState(KeyringState.KeyringLoaded)
            
            const [s1] = await splitSecretToShares(keyring.s0)
            await Promise.all([setCachedKeyring(keyring), setKeyringShare1(s1)])
            return KeyringState.KeyringLoaded
        }
        
        setKeyring(null)
        setState(KeyringState.Failed)
        return KeyringState.Failed
    }, [navigate, showPasscodeSheetAsync])
    
    const signOut = useCallback(async () => {
        setKeyring(null)
        setState(KeyringState.KeyringRequired)
        
        localStorage.clear()
        secureLocalStorage.clear()
        await Promise.allSettled([
            Zing.auth.signOut(),
            clearCachedKeyring(),
            clearKeyringShare1()
        ])
    }, [])
    
    useEffect(() => {
        if (isNativeEnv) {
            document.addEventListener('flutterInAppWebViewPlatformReady', initialize)
        } else {
            initialize()
        }
        
        return () => {
            document.removeEventListener('flutterInAppWebViewPlatformReady', initialize)
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])
    
    if (!attached) {
        return <ViewPending className='bg-cyan-500'/>
    }
    
    return (
        <Context.Provider value={{
            state,
            keyring,
            initialize,
            signOut
        }}>
            {children}
        </Context.Provider>
    )
}

export default KeyringProvider
