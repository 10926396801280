import BigNumber from 'bignumber.js'
import { formatUnits } from 'ethers'
import type { BigNumberish, Numeric } from 'ethers/src.ts/utils'


export const Zero = BigNumber(0)

export const Unit = BigNumber(1e18)

export const toDisplayUnit = (value: BigNumberish | BigNumber, unit?: string | Numeric) => {
    return formatUnits(value.toString(), unit)
}

export const toUnit = (value: BigNumberish | BigNumber, decimals: number = 18): BigNumber => {
    return BigNumber(value.toString()).div(BigNumber(10).pow(decimals))
}

export const toDisplayBalance = (value: BigNumberish | BigNumber): string => {
    const str = value.toString()
    if (str.indexOf('.') < 0) return `${str}.00`

    const [decimal, floating] = str.split('.', 2)
    return floating.length > 4 ? `${decimal}.${floating.slice(0, 4)}` : `${decimal}.${floating}`
}
