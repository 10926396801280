import React, { HTMLAttributes } from 'react'

import { cn } from '../../libs/utils'


export interface LogoTextProps extends HTMLAttributes<HTMLSpanElement> {

}

export const LogoText = ({
    ...props
}: LogoTextProps) => {

    return (
        <span className={cn(
            'inline-flex items-center',
            'font-zing',
            'text-3xl',
            'text-cyan-500',
            'select-none',
            props.className
        )} aria-hidden='true'>ZING</span>
    )
}
