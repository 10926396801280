import { Icon } from '@iconify/react'
import { head, last } from 'lodash'
import moment from 'moment/moment'
import { nanoid } from 'nanoid'
import React, { useCallback, useMemo } from 'react'
import Countdown from 'react-countdown'

import Badge from '../../components/badge'
import Button from '../../components/button'
import Card from '../../components/card'
import Page from '../../components/page'
import Profile from '../../components/profile'
import { useActiveAdapter } from '../../hooks/use-adapter'
import { usePopupLoader, usePopupPoints } from '../../hooks/use-popup'
import { useToasts } from '../../hooks/use-toasts'
import { callableOf } from '../../libs/firebase'
import { cn } from '../../libs/utils'
import { ZgData, ZgDataCall } from './zg.types'


export const ZgPageCollect = () => {
    const { adapterId, adapter, profile } = useActiveAdapter()

    const [showLoader, closeLoader] = usePopupLoader()
    const [showPoints] = usePopupPoints()
    const { showAlarm } = useToasts()

    const slots = useMemo(() => {
        const reward = head(adapter?.tokenizer.rewards)
        if (!reward) return []

        return [
            { boost: undefined, points: reward.value },
            { boost: undefined, points: reward.value },
            { boost: 'X2', points: '20' },
            { boost: undefined, points: reward.value },
            { boost: 'X3', points: '30' }
        ]
    }, [adapter])

    const renderSlots = useCallback(() => {
        let history = profile?.checkin ?? []
        const lastCheckin = moment.unix(last(history) ?? 0)
        if (history.length === 5 && lastCheckin.isBefore(moment(), 'day')) {
            history = []
        }

        return (
            <div className={cn(
                'w-full',
                'grid grid-cols-3 gap-2'
            )}>
                {
                    slots.map((slot, idx) => {
                        const checked = idx < history.length
                        return (
                            <Card.Blurry key={idx} className={cn(
                                'relative',
                                'aspect-square',
                                'flex flex-col justify-center items-center',
                                'overflow-visible',
                                'text-sm'
                            )}>
                                <span className='text-sm font-medium'>Day {idx + 1}</span>
                                <Button.Point value={slot.points} className='shadow-none'/>

                                {checked && (
                                    <div className={cn(
                                        'absolute inset-0 rounded-2xl',
                                        'flex flex-col justify-center items-center',
                                        'bg-cyan-500'
                                    )}>
                                        <Icon icon='mingcute:check-circle-line' className='size-12 text-white'/>
                                    </div>
                                )}

                                {slot.boost && (
                                    <div className={cn(
                                        'absolute -top-2 -right-3.5',
                                        'rotate-[30deg]'
                                    )}>
                                        <Badge.Text text={slot.boost} className={cn(
                                            'text-base font-bold',
                                            'px-4',
                                            'bg-amber-500 ring-amber-200 text-white'
                                        )}/>
                                    </div>
                                )}
                            </Card.Blurry>
                        )
                    })
                }
            </div>
        )
    }, [profile, slots])

    const availableAt = useMemo(() => {
        return moment.unix(profile?.availableAt ?? 0)
    }, [profile])

    const handleTask = useCallback(async () => {
        if (availableAt.isAfter(moment())) return
        showLoader()

        try {
            const id = nanoid()
            const call = callableOf<ZgDataCall, ZgData>('zg-checkin')
            const result = await call({
                id, tz: moment().utcOffset()
            })

            if (!result.data) {
                closeLoader()
                showAlarm({
                    icon: 'fluent-emoji:face-screaming-in-fear',
                    text: 'Something went wrong',
                    subtext: 'Please try again in a moment.'
                })
                return
            }

            showPoints(result.data)
        } catch (e) {
            console.error('handleTask', e)
            showAlarm({
                icon: 'fluent-emoji:face-screaming-in-fear',
                text: 'Something went wrong',
                subtext: 'Please try again in a moment.'
            })
        }
    }, [availableAt, closeLoader, showAlarm, showLoader, showPoints])

    return (
        <React.Fragment>
            <Page.AnimatedNested key={'ig.page-collect'}>
                <div className={cn(
                    'w-full',
                    'flex flex-col justify-start items-start gap-y-4'
                )}>
                    <Profile.Adapter adapterId={adapterId}/>

                    <div className='w-full flex flex-col gap-y-2'>
                        {renderSlots()}
                    </div>
                </div>
            </Page.AnimatedNested>

            <div className={cn(
                'max-w-screen-sm mx-auto',
                'fixed bottom-0 left-0 right-0 p-4',
                'bg-gradient-to-t from-white to-transparent'
            )}>
                <Button.Solid
                    className={cn(
                        'relative',
                        'container h-12',
                        'flex justify-center items-center',
                        'bg-cyan-500 text-white font-semibold'
                    )}
                    onClick={handleTask}
                >
                    {
                        availableAt.isAfter(moment())
                        ? <Countdown date={availableAt.valueOf()} daysInHours={true}/>
                        : <span>{'Check In'}</span>
                    }
                </Button.Solid>
            </div>
        </React.Fragment>
    )
}
