import { SpinnerBlocks } from './spinner-blocks'
import { SpinnerCircle } from './spinner-circle'
import { SpinnerDot3 } from './spinner-dot3'


const Spinner = {
    Circle: SpinnerCircle,
    Blocks: SpinnerBlocks,
    Dot3: SpinnerDot3
}

export default Spinner
