export const Avatars = {
    icon: [
        'fluent-emoji:grinning-face',
        'fluent-emoji:grinning-face-with-big-eyes',
        'fluent-emoji:grinning-face-with-smiling-eyes',
        'fluent-emoji:beaming-face-with-smiling-eyes',
        'fluent-emoji:grinning-squinting-face',
        'fluent-emoji:rolling-on-the-floor-laughing',
        'fluent-emoji:face-with-tears-of-joy',
        'fluent-emoji:slightly-smiling-face',
        'fluent-emoji:upside-down-face',
        'fluent-emoji:winking-face',
        'fluent-emoji:smiling-face-with-smiling-eyes',
        'fluent-emoji:smiling-face-with-halo',
        'fluent-emoji:smiling-face-with-hearts',
        'fluent-emoji:smiling-face-with-heart-eyes',
        'fluent-emoji:star-struck',
        'fluent-emoji:face-blowing-a-kiss',
        'fluent-emoji:kissing-face',
        'fluent-emoji:smiling-face',
        'fluent-emoji:kissing-face-with-closed-eyes',
        'fluent-emoji:kissing-face-with-smiling-eyes',
        'fluent-emoji:face-savoring-food',
        'fluent-emoji:face-with-tongue',
        'fluent-emoji:winking-face-with-tongue',
        'fluent-emoji:zany-face',
        'fluent-emoji:squinting-face-with-tongue',
        'fluent-emoji:money-mouth-face',
        'fluent-emoji:hugging-face',
        'fluent-emoji:face-with-hand-over-mouth',
        'fluent-emoji:face-with-open-eyes-and-hand-over-mouth',
        'fluent-emoji:face-with-peeking-eye',
        'fluent-emoji:shushing-face',
        'fluent-emoji:thinking-face',
        'fluent-emoji:saluting-face',
        'fluent-emoji:zipper-mouth-face',
        'fluent-emoji:face-with-raised-eyebrow',
        'fluent-emoji:neutral-face',
        'fluent-emoji:expressionless-face',
        'fluent-emoji:face-without-mouth',
        'fluent-emoji:dotted-line-face',
        'fluent-emoji:face-in-clouds',
        'fluent-emoji:smirking-face',
        'fluent-emoji:unamused-face',
        'fluent-emoji:face-with-rolling-eyes',
        'fluent-emoji:grimacing-face',
        'fluent-emoji:face-exhaling',
        'fluent-emoji:lying-face',
        'fluent-emoji:relieved-face',
        'fluent-emoji:pensive-face',
        'fluent-emoji:cowboy-hat-face',
        'fluent-emoji:partying-face',
        'fluent-emoji:disguised-face',
        'fluent-emoji:smiling-face-with-sunglasses',
        'fluent-emoji:nerd-face',
        'fluent-emoji:face-with-monocle',
        'fluent-emoji:face-with-open-mouth',
        'fluent-emoji:hushed-face',
        'fluent-emoji:astonished-face',
        'fluent-emoji:face-screaming-in-fear',
        'fluent-emoji:confounded-face',
        'fluent-emoji:persevering-face',
        'fluent-emoji:face-with-steam-from-nose',
        'fluent-emoji:smiling-face-with-horns',
        'fluent-emoji:angry-face-with-horns',
        'fluent-emoji:skull',
        'fluent-emoji:skull-and-crossbones',
        'fluent-emoji:pile-of-poo',
        'fluent-emoji:clown-face',
        'fluent-emoji:ghost',
        'fluent-emoji:alien',
        'fluent-emoji:alien-monster',
        'fluent-emoji:robot',
        'fluent-emoji:grinning-cat',
        'fluent-emoji:grinning-cat-with-smiling-eyes',
        'fluent-emoji:cat-with-tears-of-joy',
        'fluent-emoji:smiling-cat-with-heart-eyes',
        'fluent-emoji:cat-with-wry-smile',
        'fluent-emoji:kissing-cat',
        'fluent-emoji:weary-cat',
        'fluent-emoji:crying-cat',
        'fluent-emoji:pouting-cat',

        // 'fluent-emoji:see-no-evil-monkey',
        // 'fluent-emoji:hear-no-evil-monkey',
        // 'fluent-emoji:speak-no-evil-monkey',
        // 'fluent-emoji:monkey-face',
        // 'fluent-emoji:monkey',
        // 'fluent-emoji:gorilla',
        // 'fluent-emoji:orangutan',
        // 'fluent-emoji:dog-face',
        // 'fluent-emoji:dog',
        // 'fluent-emoji:guide-dog',
        // 'fluent-emoji:service-dog',
        // 'fluent-emoji:poodle',
        // 'fluent-emoji:wolf',
        // 'fluent-emoji:fox',
        // 'fluent-emoji:raccoon',
        // 'fluent-emoji:cat-face',
        // 'fluent-emoji:cat',
        // 'fluent-emoji:black-cat',
        // 'fluent-emoji:lion',
        // 'fluent-emoji:tiger-face',
        // 'fluent-emoji:tiger',
        // 'fluent-emoji:leopard',
        // 'fluent-emoji:horse-face',
        // 'fluent-emoji:moose',
        // 'fluent-emoji:donkey',
        // 'fluent-emoji:horse',
        // 'fluent-emoji:unicorn',
        // 'fluent-emoji:zebra',
        // 'fluent-emoji:deer',
        // 'fluent-emoji:bison',
        // 'fluent-emoji:cow-face',
        // 'fluent-emoji:ox',
        // 'fluent-emoji:water-buffalo',
        // 'fluent-emoji:cow',
        // 'fluent-emoji:pig-face',
        // 'fluent-emoji:pig',
        // 'fluent-emoji:boar',
        // 'fluent-emoji:pig-nose',
        // 'fluent-emoji:ram',
        // 'fluent-emoji:ewe',
        // 'fluent-emoji:goat',
        // 'fluent-emoji:camel',
        // 'fluent-emoji:two-hump-camel',
        // 'fluent-emoji:llama',
        // 'fluent-emoji:giraffe',
        // 'fluent-emoji:elephant',
        // 'fluent-emoji:mammoth',
        // 'fluent-emoji:rhinoceros',
        // 'fluent-emoji:hippopotamus',
        // 'fluent-emoji:mouse-face',
        // 'fluent-emoji:mouse',
        // 'fluent-emoji:rat',
        // 'fluent-emoji:hamster',
        // 'fluent-emoji:rabbit-face',
        // 'fluent-emoji:rabbit',
        // 'fluent-emoji:chipmunk',
        // 'fluent-emoji:beaver',
        // 'fluent-emoji:hedgehog',
        // 'fluent-emoji:bat',
        // 'fluent-emoji:bear',
        // 'fluent-emoji:polar-bear',
        // 'fluent-emoji:koala',
        // 'fluent-emoji:panda',
        // 'fluent-emoji:sloth',
        // 'fluent-emoji:otter',
        // 'fluent-emoji:skunk',
        // 'fluent-emoji:kangaroo',
        // 'fluent-emoji:badger',
        // 'fluent-emoji:turkey',
        // 'fluent-emoji:chicken',
        // 'fluent-emoji:rooster',
        // 'fluent-emoji:hatching-chick',
        // 'fluent-emoji:baby-chick',
        // 'fluent-emoji:front-facing-baby-chick',
        // 'fluent-emoji:bird',
        // 'fluent-emoji:penguin',
        // 'fluent-emoji:dove',
        // 'fluent-emoji:eagle',
        // 'fluent-emoji:duck',
        // 'fluent-emoji:swan',
        // 'fluent-emoji:owl',
        // 'fluent-emoji:dodo',
        // 'fluent-emoji:feather',
        // 'fluent-emoji:flamingo',
        // 'fluent-emoji:peacock',
        // 'fluent-emoji:parrot',
        // 'fluent-emoji:wing',
        // 'fluent-emoji:black-bird',
        // 'fluent-emoji:goose',
        // 'fluent-emoji:phoenix-bird',
        // 'fluent-emoji:frog',
        // 'fluent-emoji:dragon-face',
        // 'fluent-emoji:dragon',
        // 'fluent-emoji:sauropod',
        // 'fluent-emoji:t-rex',
        // 'fluent-emoji:spouting-whale',
        // 'fluent-emoji:whale',
        // 'fluent-emoji:dolphin',
        // 'fluent-emoji:seal',
        // 'fluent-emoji:fish',
        // 'fluent-emoji:tropical-fish',
        // 'fluent-emoji:blowfish',
        // 'fluent-emoji:shark',
        // 'fluent-emoji:octopus',
        // 'fluent-emoji:spiral-shell',
        // 'fluent-emoji:honeybee',

        // flower
        // 'fluent-emoji:bouquet',
        // 'fluent-emoji:cherry-blossom',
        // 'fluent-emoji:white-flower',
        // 'fluent-emoji:lotus',
        // 'fluent-emoji:rosette',
        // 'fluent-emoji:rose',
        // 'fluent-emoji:wilted-flower',
        // 'fluent-emoji:hibiscus',
        // 'fluent-emoji:sunflower',
        // 'fluent-emoji:blossom',
        // 'fluent-emoji:tulip',
        // 'fluent-emoji:hyacinth',
        // 'fluent-emoji:seedling',
        // 'fluent-emoji:potted-plant',
        // 'fluent-emoji:evergreen-tree',
        // 'fluent-emoji:deciduous-tree',
        // 'fluent-emoji:palm-tree',
        // 'fluent-emoji:cactus',
        // 'fluent-emoji:sheaf-of-rice',
        // 'fluent-emoji:herb',
        // 'fluent-emoji:shamrock',
        // 'fluent-emoji:four-leaf-clover',
        // 'fluent-emoji:maple-leaf',
        // 'fluent-emoji:fallen-leaf',
        // 'fluent-emoji:leaf-fluttering-in-wind',
        // 'fluent-emoji:empty-nest',
        // 'fluent-emoji:nest-with-eggs',
        // 'fluent-emoji:mushroom',
        // 'fluent-emoji:crab',
        // 'fluent-emoji:new-moon',
        // 'fluent-emoji:waxing-crescent-moon',
        // 'fluent-emoji:first-quarter-moon',
        // 'fluent-emoji:waxing-gibbous-moon',
        // 'fluent-emoji:full-moon',
        // 'fluent-emoji:waning-gibbous-moon',
        // 'fluent-emoji:last-quarter-moon',
        // 'fluent-emoji:waning-crescent-moon',
        // 'fluent-emoji:crescent-moon',
        // 'fluent-emoji:new-moon-face',
        // 'fluent-emoji:first-quarter-moon-face',
        // 'fluent-emoji:last-quarter-moon-face',
        // 'fluent-emoji:sun',
        // 'fluent-emoji:full-moon-face',
        // 'fluent-emoji:sun-with-face',
        // 'fluent-emoji:ringed-planet',
        // 'fluent-emoji:star',
        // 'fluent-emoji:glowing-star',
        // 'fluent-emoji:comet',
        // 'fluent-emoji:fire',
        // 'fluent-emoji:droplet',
        // 'fluent-emoji:water-wave',
        // 'fluent-emoji:grapes',
        // 'fluent-emoji:melon',
        // 'fluent-emoji:watermelon',
        // 'fluent-emoji:tangerine',
        // 'fluent-emoji:lemon',
        // 'fluent-emoji:lime',
        // 'fluent-emoji:banana',
        // 'fluent-emoji:pineapple',
        // 'fluent-emoji:mango',
        // 'fluent-emoji:red-apple',
        // 'fluent-emoji:green-apple',
        // 'fluent-emoji:pear',
        // 'fluent-emoji:peach',
        // 'fluent-emoji:cherries',
        // 'fluent-emoji:strawberry',
        // 'fluent-emoji:blueberries',
        // 'fluent-emoji:kiwi-fruit',
        // 'fluent-emoji:tomato',
        // 'fluent-emoji:olive',
        // 'fluent-emoji:coconut',
        // 'fluent-emoji:avocado',
        // 'fluent-emoji:eggplant',
        // 'fluent-emoji:potato',
        // 'fluent-emoji:carrot',
        // 'fluent-emoji:ear-of-corn',
        // 'fluent-emoji:hot-pepper',
        // 'fluent-emoji:bell-pepper',
        // 'fluent-emoji:cucumber',
        // 'fluent-emoji:leafy-green',
        // 'fluent-emoji:broccoli',
        // 'fluent-emoji:garlic',
        // 'fluent-emoji:onion',
        // 'fluent-emoji:peanuts',
        // 'fluent-emoji:beans',
        // 'fluent-emoji:chestnut',
        // 'fluent-emoji:ginger',
        // 'fluent-emoji:pea-pod',
        // 'fluent-emoji:brown-mushroom',
        // 'fluent-emoji:bread',
        // 'fluent-emoji:croissant',
        // 'fluent-emoji:baguette-bread',
        // 'fluent-emoji:flatbread',
        // 'fluent-emoji:pretzel',
        // 'fluent-emoji:bagel',
        // 'fluent-emoji:pancakes',
        // 'fluent-emoji:waffle',
        // 'fluent-emoji:cheese-wedge',
        // 'fluent-emoji:meat-on-bone',
        // 'fluent-emoji:poultry-leg',
        // 'fluent-emoji:cut-of-meat',
        // 'fluent-emoji:bacon',
        // 'fluent-emoji:hamburger',
        // 'fluent-emoji:french-fries',
        // 'fluent-emoji:pizza',
        // 'fluent-emoji:hot-dog',
        // 'fluent-emoji:sandwich',
        // 'fluent-emoji:taco',
        // 'fluent-emoji:burrito',
        // 'fluent-emoji:tamale',
        // 'fluent-emoji:stuffed-flatbread',
        // 'fluent-emoji:falafel',
        // 'fluent-emoji:egg',
        // 'fluent-emoji:cooking',
        // 'fluent-emoji:shallow-pan-of-food',
        // 'fluent-emoji:pot-of-food',
        // 'fluent-emoji:fondue',
        // 'fluent-emoji:bowl-with-spoon',
        // 'fluent-emoji:green-salad',
        // 'fluent-emoji:popcorn',
        // 'fluent-emoji:butter',
        // 'fluent-emoji:salt',
        // 'fluent-emoji:canned-food',
        // 'fluent-emoji:bento-box',
        // 'fluent-emoji:rice-cracker',
        // 'fluent-emoji:rice-ball',
        // 'fluent-emoji:cooked-rice',
        // 'fluent-emoji:curry-rice',
        // 'fluent-emoji:steaming-bowl',
        // 'fluent-emoji:spaghetti',
        // 'fluent-emoji:roasted-sweet-potato',
        // 'fluent-emoji:oden',
        // 'fluent-emoji:sushi',
        // 'fluent-emoji:fried-shrimp',
        // 'fluent-emoji:fish-cake-with-swirl',
        // 'fluent-emoji:moon-cake',
        // 'fluent-emoji:dango',
        // 'fluent-emoji:dumpling',
        // 'fluent-emoji:fortune-cookie',
        // 'fluent-emoji:takeout-box',
        // 'fluent-emoji:soft-ice-cream',
        // 'fluent-emoji:shaved-ice',
        // 'fluent-emoji:ice-cream',
        // 'fluent-emoji:doughnut',
        // 'fluent-emoji:cookie',
        // 'fluent-emoji:birthday-cake',
        // 'fluent-emoji:shortcake',
        // 'fluent-emoji:cupcake',
        // 'fluent-emoji:pie',
        // 'fluent-emoji:chocolate-bar',
        // 'fluent-emoji:candy',
        // 'fluent-emoji:lollipop',
        // 'fluent-emoji:custard',
        // 'fluent-emoji:honey-pot',
        // 'fluent-emoji:baby-bottle',
        // 'fluent-emoji:glass-of-milk',
        // 'fluent-emoji:hot-beverage',
        // 'fluent-emoji:teapot',
        // 'fluent-emoji:teacup-without-handle',
        // 'fluent-emoji:sake',
        // 'fluent-emoji:bottle-with-popping-cork',
        // 'fluent-emoji:wine-glass',
        // 'fluent-emoji:cocktail-glass',
        // 'fluent-emoji:tropical-drink',
        // 'fluent-emoji:beer-mug',
        // 'fluent-emoji:clinking-beer-mugs',
        // 'fluent-emoji:clinking-glasses',
        // 'fluent-emoji:tumbler-glass',
        // 'fluent-emoji:pouring-liquid',
        // 'fluent-emoji:cup-with-straw',
        // 'fluent-emoji:bubble-tea',
        // 'fluent-emoji:beverage-box',
        // 'fluent-emoji:mate',
        // 'fluent-emoji:ice',
        // 'fluent-emoji:chopsticks',
        // 'fluent-emoji:fork-and-knife-with-plate',
        // 'fluent-emoji:fork-and-knife',
        // 'fluent-emoji:spoon',
        // 'fluent-emoji:kitchen-knife',
        // 'fluent-emoji:jar',
        // 'fluent-emoji:amphora',
        'fluent-emoji:jack-o-lantern'
    ],
    colors: [
        '#cbd5e1', // 'bg-slate-300',
        '#d4d4d8', // 'bg-zinc-300',
        '#fca5a5', // 'bg-red-300',
        '#fcd34d', // 'bg-amber-300',
        '#bef264', // 'bg-lime-300',
        '#5eead4', // 'bg-teal-300',
        '#67e8f9', // 'bg-cyan-300',
        '#a5b4fc', // 'bg-indigo-300',
        '#d8b4fe', // 'bg-purple-300',
        '#f9a8d4'  // 'bg-pink-300',
    ]
}
