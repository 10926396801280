import { useMemo } from 'react'


export const useUserAgent = () => {
    const userAgent = navigator.userAgent.toLowerCase()

    const isApple = useMemo(() => userAgent.includes('com.openrhapsody.a2zing/ios'), [userAgent])
    const isAndroid = useMemo(() => userAgent.includes('com.openrhapsody.a2zing/android'), [userAgent])
    const isEmbedded = useMemo(() => userAgent.includes('com.openrhapsody.a2zing'), [userAgent])

    return {
        userAgent,
        isApple,
        isAndroid,
        isEmbedded
    }
}
