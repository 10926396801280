import { DialogPanel } from '@headlessui/react'
import React from 'react'

import { cn } from '../../../libs/utils'
import Logo from '../../logo'


export const DialogPanelLoader = () => {
    return (
        <DialogPanel
            transition
            className={cn(
                'relative',
                'flex flex-col items-center',
                'bg-white',
                'px-8 pt-8 pb-6',
                'rounded-2xl overflow-hidden',
                'shadow-2xl',
                'transform',
                'transition-all',
                'data-[closed]:translate-y-4',
                'data-[closed]:opacity-0',
                'data-[enter]:duration-300',
                'data-[enter]:ease-out',
                'data-[leave]:duration-200',
                'data-[leave]:ease-in'
            )}
        >
            <div className={cn(
                'w-full',
                'flex flex-col justify-center items-center gap-y-2',
                'text-center'
            )}>
                <Logo.Text className='text-cyan-500 animate-tada'/>
                <p className='text-sm text-slate-600 whitespace-pre-wrap'>
                    {'Please wait a moment'}
                </p>
            </div>
        </DialogPanel>
    )
}
