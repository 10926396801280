import { AdapterStatus, AdapterTask } from '../adapter.types'


export enum AdapterIgState {
    Undetermined,
    Connecting,
    Connected,
    Collect
}

export interface IgProfile {
    username: string
    displayUrl?: string
    biography?: string
    followersCount?: number
    followsCount?: number
    postsCount?: number
    collected?: number
    updatedAt?: number
}

export interface IgPost {
    id: string
    type: string
    shortCode: string
    caption: string
    hashtags: string[]
    mentions: any[]
    url: string
    commentsCount: number
    dimensionsHeight: number
    dimensionsWidth: number
    images: string[]
    displays?: string[]
    displayUrl: string
    displayUrls?: string[]
    alt: string
    likesCount: number
    timestamp: string
    childPosts: IgPost[]
    locationName?: string
    ownerUsername: string
    ownerId: string
}

export interface IgTask extends AdapterTask {
    id: string
    uid: string
    status: AdapterStatus
    timestamp: number

    /** params **/
    username: string
    posts?: string[]
}

export interface IgPlay extends AdapterTask {
    id: string
    uid: string
    status: AdapterStatus
    timestamp: number

    /** params **/
    username: string
    displayUrl?: string
    posts?: string[]
    age?: string
    city?: string
    display?: string
    gender?: string
    relationship?: string
    summary?: string
}

export interface IgPlayCall {
    id: string
    username: string
    locale: string
}
