import { WalletAccount } from './wallet-account'
import { WalletBalance } from './wallet-balance'


const Wallet = {
    Account: WalletAccount,
    Balance: WalletBalance
}

export default Wallet
