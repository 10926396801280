import { useContext, useMemo } from 'react'

import { Context } from '../contexts/keyring'


export const useKeyring = () => {
    const {
        state,
        keyring,
        initialize,
        signOut
    } = useContext(Context)

    const uid = useMemo(() => keyring?.uid, [keyring?.uid])

    return {
        uid,
        state,
        keyring,
        initialize,
        signOut
    }
}
