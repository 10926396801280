import { useCallback, useEffect, useState } from 'react'

import { getAttToken, getFcmToken, openAppSettings, permissionOf, requestPermissionOf } from '../libs/helper/helper.device'
import { PermissionsKey, PermissionsStatus } from '../libs/types/device.types'
import { useWindowVisibility } from './use-window-visibility'


export const useDevicePermissions = () => {
    const visibility = useWindowVisibility()

    const [fcmToken, setFcmToken] = useState('')
    const [attToken, setAttToken] = useState('')

    const queueTokenIfNeeded = useCallback(async (key: PermissionsKey) => {
        switch (key) {
            case PermissionsKey.notification:
                setFcmToken(await getFcmToken())
                break
            case PermissionsKey.att:
                setAttToken(await getAttToken())
                break
            default:
                break
        }
    }, [])

    const requestPermission = useCallback(async (key: PermissionsKey): Promise<PermissionsStatus> => {
        let status = await permissionOf(key)
        switch (status) {
            case PermissionsStatus.permanentlyDenied:
                await openAppSettings(key)
                break
            case PermissionsStatus.granted:
                queueTokenIfNeeded(key)
                break
            default: {
                status = await requestPermissionOf(key)
                if (status === PermissionsStatus.granted) {
                    queueTokenIfNeeded(key)
                }
                break
            }
        }
        return status
    }, [queueTokenIfNeeded])

    const checkPermission = useCallback(async (key: PermissionsKey): Promise<PermissionsStatus> => {
        const status = await permissionOf(key)
        if (status === PermissionsStatus.granted) {
            queueTokenIfNeeded(key)
        }
        return status
    }, [queueTokenIfNeeded])

    useEffect(() => {
        if (!visibility) return

        checkPermission(PermissionsKey.notification)
        checkPermission(PermissionsKey.att)
    }, [checkPermission, visibility])

    return {
        fcmToken,
        attToken,
        checkPermission,
        requestPermission
    }
}
