import { PageAnimated } from './page-animated'
import { PageAnimatedNested } from './page-animated-nested'
import { PageAnimatedOnboarding } from './page-animated-onboarding'
import { PageAnimatedTab } from './page-animated-tab'
import { PageNested } from './page-nested'


const Page = {
    Animated: PageAnimated,
    AnimatedTab: PageAnimatedTab,
    AnimatedOnboarding: PageAnimatedOnboarding,
    AnimatedNested: PageAnimatedNested,
    Nested: PageNested
}

export default Page
