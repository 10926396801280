import { motion, stagger, useAnimate } from 'framer-motion'
import React, { useCallback, useEffect, useState } from 'react'

import { PageAnimatedOnboarding } from '../../components/page/page-animated-onboarding'
import Profile from '../../components/profile'
import Spinner from '../../components/spinner'
import Wallet from '../../components/wallet'
import { Keyring } from '../../libs/types/account.types'
import { cn, splitAddress } from '../../libs/utils'


interface PageReviewProps {
    keyring: Keyring | null
    authenticated: boolean
    onNext: () => void
}

export const PageReview = ({
    keyring,
    authenticated,
    onNext
}: PageReviewProps) => {
    const [scope, animate] = useAnimate()

    const [name, setName] = useState('')
    const [avatar, setAvatar] = useState('')
    const [completed, setCompleted] = useState(false)

    const start = useCallback(() => {
        setTimeout(() => setName(keyring?.name ?? ''), 1000)
        setTimeout(() => setAvatar(keyring?.avatar ?? ''), 1500)
        setTimeout(() => {
            if (scope.current !== null) {
                animate(
                    'span',
                    { opacity: 1, filter: 'blur(0px)' },
                    { duration: 0.5, delay: stagger(0.4) }
                ).then(() => {
                    setCompleted(true)
                })
            }
        }, 2500)
    }, [animate, keyring?.avatar, keyring?.name, scope])

    const renderAddress = useCallback(() => {
        const chunks = splitAddress(keyring?.address)
        chunks.splice(5, 0, ' ')
        chunks.splice(0, 0, '0x')

        return (
            <motion.div ref={scope} className={cn(
                'grid grid-cols-6',
                'font-mono',
                'text-xs',
                'text-slate-900',
                'font-medium',
                'tracking-tight',
                'whitespace-break-spaces',
                'place-items-end'
            )}>
                {
                    chunks.map((word, idx) => {
                        return (
                            <motion.span key={word + idx}
                                         className={cn(
                                             'opacity-0',
                                             'whitespace-pre-wrap'
                                         )}
                                         style={{ filter: 'blur(10px)' }}
                            >
                                {word}{' '}
                            </motion.span>
                        )
                    })
                }
            </motion.div>
        )
    }, [keyring?.address, scope])

    useEffect(() => {
        start()
    }, [start])

    return (
        <PageAnimatedOnboarding key={'PageWallet'}>
            <div className={cn(
                'absolute top-[15%]',
                'w-full',
                'flex flex-col justify-center items-start',
                'px-4'
            )}>
                <div className='text-4xl font-semibold text-slate-900/15'>
                    Wallet Address
                </div>
                <div className='text-xl font-normal text-slate-900/60'>
                    We’re setting up your wallet address
                </div>
            </div>

            <motion.div layout>
                {(authenticated && completed) ? (
                    <Wallet.Account className='shadow-xl' onClick={onNext}/>
                ) : (
                     <div className={cn(
                         'w-fit',
                         'flex justify-center items-center',
                         'gap-x-2 p-1.5',
                         'rounded-full',
                         'bg-white',
                         'ring-1 ring-black/5',
                         'shadow-lg'
                     )}>
                         <Profile.Avatar avatar={avatar} size='md'/>
                         <div className='relative flex flex-col items-start'>
                             <Profile.Name name={name} className='text-base'/>
                             {renderAddress()}
                         </div>
                         <Spinner.Circle className='size-6 mr-1'/>
                     </div>
                 )}
            </motion.div>
        </PageAnimatedOnboarding>
    )
}
