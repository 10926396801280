import { Icon } from '@iconify/react'
import React, { ButtonHTMLAttributes } from 'react'

import { cn } from '../../libs/utils'


export interface ButtonFloatingProps extends ButtonHTMLAttributes<HTMLButtonElement> {
}

export const ButtonFloating = ({
    ...props
}: ButtonFloatingProps) => {

    return (
        <button onClick={props.onClick} className={cn(
            'p-4',
            'flex justify-center items-center',
            'bg-cyan-500 text-white font-semibold',
            'rounded-full',
            'shadow-xl shadow-black/15',
            props.disabled ? 'bg-opacity-45' : '',
            props.className
        )} type={props.type} disabled={props.disabled}>
            {props.children}
            <Icon icon={'mingcute:add-fill'} className='size-7'/>
        </button>
    )
}
