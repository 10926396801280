import { Icon } from '@iconify/react'
import { HTMLAttributes } from 'react'

import { cn } from '../../libs/utils'


export interface BadgeFloatProps extends HTMLAttributes<HTMLDivElement> {
    icon?: string
    text?: string
}

export const BadgeFloat = ({
    icon = 'fluent-emoji:wrapped-gift',
    text,
    ...props
}: BadgeFloatProps) => {

    return (
        <div className={cn(
            'absolute',
            'px-4 py-2.5',
            'flex flex-col justify-center items-center gap-y-0.5',
            'rounded-2xl overflow-hidden',
            'bg-white/60 backdrop-blur-xl',
            'shadow-xl',
            'z-20',
            props.className
        )}>
            {icon && (
                <Icon icon={icon} className={cn(
                    'size-12'
                )} />
            )}
            <span className='text-xs font-semibold'>View Results</span>
        </div>
    )
}
