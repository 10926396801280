export interface DeviceMetadata {
    locale: string
    tz: number
}

export enum PermissionsKey {
    photos = 'photos',
    camera = 'camera',
    notification = 'notification',
    att = 'att'
}

export enum PermissionsStatus {
    denied = 'denied',
    granted = 'granted',
    restricted = 'restricted',
    limited = 'limited',
    permanentlyDenied = 'permanentlyDenied',
    provisional = 'provisional'
}
