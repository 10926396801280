import { useContext, useMemo } from 'react'

import { Context as AccountContext } from '../contexts/account'


export const useAccount = () => {
    const {
        account
    } = useContext(AccountContext)

    const uid = useMemo(() => account?.uid, [account?.uid])
    const points = useMemo(() => account?.points ?? 0, [account?.points])

    return {
        uid,
        account,
        points
    }
}
