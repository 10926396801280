import { atom, useSetAtom } from 'jotai/index'
import { useCallback } from 'react'


export interface ConsentSheetProps {
    adapterId?: string
    onConfirm?: (consented: boolean) => void
}

export const consentSheetPropsAtom = atom<ConsentSheetProps | null>()

export const useSheetConsent = () => {
    const setConsentSheetProps = useSetAtom(consentSheetPropsAtom)

    const showConsentSheet = useCallback((props: ConsentSheetProps) => {
        setConsentSheetProps(props)
    }, [setConsentSheetProps])

    const showConsentSheetAsync = useCallback((props: ConsentSheetProps): Promise<boolean> => {
        return new Promise((resolve) => {
            setConsentSheetProps({
                ...props,
                onConfirm: (consented) => {
                    try {
                        if (props.onConfirm) {
                            props.onConfirm(consented)
                        }
                        return consented
                    } catch (e) {
                        return false
                    } finally {
                        resolve(consented)
                    }
                }
            })
        })
    }, [setConsentSheetProps])

    const hideConsentSheet = useCallback(() => {
        setConsentSheetProps(null)
    }, [setConsentSheetProps])

    return {
        showConsentSheet,
        showConsentSheetAsync,
        hideConsentSheet
    }

}
