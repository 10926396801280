import { Icon } from '@iconify/react'
import { cva, VariantProps } from 'class-variance-authority'
import React, { HTMLAttributes, useMemo } from 'react'

import { cn, typeofColor, typeofIcon } from '../../libs/utils'


const Styles = {
    it: 'inline-block overflow-hidden rounded-full shrink-0 bg-white/20',
    size: {
        sm: {
            width: 'w-10',
            height: 'h-10',
            padding: 'p-1'
        },
        md: {
            width: 'w-12',
            height: 'h-12',
            padding: 'p-1.5'
        },
        lg: {
            width: 'w-20',
            height: 'h-20',
            padding: 'p-2'
        },
        xl: {
            width: 'w-24',
            height: 'h-24',
            padding: 'p-2.5'
        }
    }
}

// noinspection TypeScriptValidateTypes
const Variants = cva(Styles.it, {
    variants: {
        size: {
            sm: Object.values(Styles.size.sm),
            md: Object.values(Styles.size.md),
            lg: Object.values(Styles.size.lg),
            xl: Object.values(Styles.size.xl)
        }
    },
    defaultVariants: {
        size: 'sm'
    }
})

export interface ProfileAvatarProps extends HTMLAttributes<HTMLDivElement>, VariantProps<typeof Variants> {
    avatar?: string
}

export const ProfileAvatar = ({
    avatar,
    size,
    ...props
}: ProfileAvatarProps) => {
    const [src, color] = useMemo(() => {
        return avatar?.toString()?.split('/', 2) ?? []
    }, [avatar])

    const useColor = useMemo(() => {
        return typeofColor(color)
    }, [color])

    const contentView = useMemo(() => {
        if (typeofIcon(src)) return <Icon icon={src as string} className='w-full h-full'/>
        return <img src={src} alt={''} className='w-full h-full'/>
    }, [src])

    if (!src) {
        return (
            <div className={cn(
                Variants({ size }),
                'bg-slate-200',
                'animate-pulse',
                props.className
            )}/>
        )
    }

    return (
        <div className={cn(
            Variants({ size }),
            color,
            props.className
        )} style={{ backgroundColor: useColor ? color : '' }} onClick={props.onClick}>
            {contentView}
        </div>
    )
}
