const isProduction = process.env.REACT_APP_ENV !== 'development'

const Constants = {
    production: isProduction,
    keyring: 'keyring',
    keyringShare0: 'keyring#share0',
    keyringShare1: 'keyring#share1',
    createWalletToastId: 'toast#create-wallet'
}

export default Constants
