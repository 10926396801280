import React, { HTMLAttributes } from 'react'

import { cn } from '../../libs/utils'
import { ContainerBase } from '../container/container-base'


export interface CardBlurryProps extends HTMLAttributes<HTMLDivElement> {
}

export const CardBlurry = ({
    ...props
}: CardBlurryProps) => {

    return (
        <ContainerBase className={cn(
            'flex flex-col items-start shrink-0 gap-y-2',
            'bg-white/80 backdrop-blur-lg',
            'ring-1 ring-black/5',
            'rounded-2xl overflow-hidden',
            'shadow-lg',
            'p-4',
            props.className
        )} onClick={props.onClick}>
            {props.children}
        </ContainerBase>
    )
}
