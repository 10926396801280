import { BadgeChain } from './badge-chain'
import { BadgeCompact, BadgeCompactDataTag, BadgeCompactTokenizerVersion } from './badge-compact'
import { BadgeFloat } from './badge-float'
import { BadgeIcon, BadgeIconDataTag, BadgeIconWallet } from './badge-icon'
import { BadgeText } from './badge-text'


const Badge = {
    Text: BadgeText,
    Icon: BadgeIcon,
    Chain: BadgeChain,
    IconDataTag: BadgeIconDataTag,
    IconWallet: BadgeIconWallet,
    Compact: BadgeCompact,
    CompactDataTag: BadgeCompactDataTag,
    CompactTokenizerVersion: BadgeCompactTokenizerVersion,
    Float: BadgeFloat
}

export default Badge
