import { BackgroundBlob } from './background-blob'
import { BackgroundWavy } from './background-wavy'


const Background = {
    Wavy: BackgroundWavy,
    Blob: BackgroundBlob
}

export default Background
