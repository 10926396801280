import { CardAdapter } from './card-adapter'
import { CardApp } from './card-app'
import { CardBlurry } from './card-blurry'
import { CardToken } from './card-token'


const Card = {
    Blurry: CardBlurry,
    Adapter: CardAdapter,
    Token: CardToken,
    App: CardApp
}

export default Card
