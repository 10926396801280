import React, { HTMLAttributes, useMemo } from 'react'

import { useTokenPrice } from '../../hooks/use-token'
import { toDisplayBalance, toUnit, Zero } from '../../libs/helper/helper.number'
import { IToken } from '../../libs/types/blockchain.types'
import { cn } from '../../libs/utils'
import { ContainerBase } from '../container/container-base'


export interface CardCoinProps extends HTMLAttributes<HTMLDivElement> {
    token: IToken
}

export const CardToken = ({
    token,
    ...props
}: CardCoinProps) => {
    const priceRaw = useTokenPrice(token)

    const [price, balance, usd] = useMemo(() => {
        const price = priceRaw.toFixed(2)
        const balance = toUnit(token.balance ?? Zero, token.decimals)
        const usd = balance.times(price)

        return [price, toDisplayBalance(balance), usd.toFixed(2)]
    }, [priceRaw, token.balance, token.decimals])

    return (
        <ContainerBase className={cn(
            'flex items-center shrink-0 gap-x-2',
            'bg-white/80 backdrop-blur-lg',
            'ring-1 ring-black/5',
            'rounded-2xl overflow-hidden',
            'shadow-lg',
            'p-4',
            props.className
        )} onClick={props.onClick}>
            <img src={token.image} className={cn(
                'size-10 p-0.5',
                'bg-white',
                'rounded-full overflow-hidden'
            )} alt=''/>

            <div className='flex flex-col grow'>
                <span className='text-base text-slate-900 font-medium'>{token.name}</span>
                <span className='text-sm text-slate-900/80'>${price}</span>
            </div>
            <div className='flex flex-col items-end shrink-0'>
                <span className='text-base text-slate-900 font-medium'>{balance} {token.symbol.toUpperCase()}</span>
                <span className='text-sm text-slate-900/80'>${usd} USD</span>
            </div>
        </ContainerBase>
    )
}
