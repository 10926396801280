import { motion } from 'framer-motion'
import React from 'react'

import Button from '../../components/button'
import Card from '../../components/card'
import Page from '../../components/page'
import { useActiveAdapter } from '../../hooks/use-adapter'
import { cn } from '../../libs/utils'
import { AdapterStepperProps } from '../../views/tokenizer/view-tokenizer-steps'
import { AdapterIgState } from './ig.types'


export const IgPageConnected = ({ goto, forward }: AdapterStepperProps) => {
    const { adapter } = useActiveAdapter()

    return (
        <Page.AnimatedNested key={'InstagramPageConnected'}>
            <div className={cn(
                'absolute top-[15%]',
                'w-full',
                'flex flex-col justify-center items-start',
                'px-4'
            )}>
                <div className='text-4xl font-semibold text-slate-900/15'>
                    Congratulations!
                </div>
                <div className='text-xl font-normal text-slate-900/60'>
                    {'You\'ve connected your Instagram.'}
                </div>
            </div>

            <motion.div className={cn(
                'relative',
                'w-full my-auto',
                'flex flex-col justify-center items-center'
            )}
                        initial={{ opacity: 0, y: '30%' }}
                        animate={{ opacity: 1, y: 0 }}
                        transition={{ delay: 1 }}
            >
                {adapter && (
                    <Card.Adapter
                        adapter={adapter}
                        action={
                            <Button.Point
                                value={adapter.tokenizer.rewards[0].value}
                                unit={adapter.tokenizer.rewards[0].unit}
                            />
                        }
                        onClick={() => goto?.call(this, AdapterIgState.Collect)}
                    />
                )}
            </motion.div>
        </Page.AnimatedNested>
    )
}
