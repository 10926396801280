import { useQuery } from '@tanstack/react-query'
import axios, { AxiosRequestConfig } from 'axios'
import BigNumber from 'bignumber.js'
import { useCallback, useContext, useEffect, useMemo, useState } from 'react'

import { Context } from '../contexts/chain'
import { TokenWatchlist } from '../libs/blockchain/tokens'
import logger from '../libs/helper/helper.logger'
import { getBalances } from '../libs/helper/helper.multicall'
import { Zero } from '../libs/helper/helper.number'
import { DataCurrentPrice } from '../libs/types/api.types'
import { IToken } from '../libs/types/blockchain.types'
import { useBlock } from './use-block'


export const useTokenWatchlist = (): IToken[] => {
    const { chain, provider, signer } = useContext(Context)
    const block = useBlock()

    const watchlist = useMemo(() => TokenWatchlist[chain.id], [chain.id])
    const [tokens, setTokens] = useState<IToken[]>(watchlist)

    const syncWatchlist = useCallback(async () => {
        if (!provider) return

        const tokens = await getBalances(provider,
            watchlist,
            chain.id,
            signer?.address
        )

        setTokens(tokens)
    }, [chain.id, provider, signer?.address, watchlist])

    useEffect(() => {
        if (block === 0) return

        syncWatchlist()
    }, [block, provider, syncWatchlist])

    return tokens
}

export const useTokenPrice = (token: IToken): BigNumber => {
    const {
        data,
        isSuccess
    } = useQuery({
        queryKey: ['api/binance', 'price', token.id],
        queryFn: async () => {
            if (token.symbol.toUpperCase() === 'USDT') {
                return { id: token.id, price: 1 }
            }

            try {
                const { data } = await axios.get('https://api.binance.com/api/v3/avgPrice', {
                    params: {
                        symbol: `${token.symbol.toUpperCase()}USDT`
                    }
                } as AxiosRequestConfig)
                return { ...data, id: token.id } as DataCurrentPrice
            } catch (e) {
                logger.error('useTokenPrice', e)
                return { id: token.id, price: 0 }
            }
        },
        staleTime: 600_000,
        refetchInterval: 600_000
    })
    return isSuccess ? BigNumber(data?.price ?? 0) : Zero
}
