import { Adapter } from '../adapter.types'


export const AdapterIg: Adapter = {
    id: 'ig',
    icon: 'https://play-lh.googleusercontent.com/VRMWkE5p3CkWhJs6nv-9ZsLAs1QOg5ob1_3qg-rckwYW7yp1fMrYZqnEFpk0IoVP4LM=s64-rw',
    name: 'Instagram',
    cta: 'Collect Posts',
    scheme: {
        android: 'com.instagram.android',
        googlePlay: '',
        apple: 'instagram://',
        appStore: 'https://apps.apple.com/app/id389801252'
    },
    tokenizer: {
        version: 'community',
        rewards: [{ key: 'collected', unit: 'Post', value: '100', repeat: true }],
        path: {
            profile: 'ig-user',
            consent: 'ig-consent',
            nonce: 'ig-nonce',
            data: 'ig-data',
            task: 'ig-task',
            play: 'ig-play'
        }
    }
}
