import { AnimatePresence, motion } from 'framer-motion'
import { useAtomValue } from 'jotai/index'
import React from 'react'

import { toastAlarmsAtom } from '../../hooks/use-toasts'
import { ToastAlarm } from './toast-alarm'


export const ToastContainer = () => {
    const alarms = useAtomValue(toastAlarmsAtom)

    return (
        <React.Fragment>
            <div aria-live='assertive' className='pointer-events-none fixed inset-0 p-2 z-50'>
                <ul>
                    <div className='flex w-full flex-col items-end space-y-2'>
                        <AnimatePresence initial={false} mode='popLayout'>
                            {alarms.map((toast) => {
                                return (
                                    <motion.li
                                        layout
                                        key={toast.id}
                                        initial={{ opacity: 0, y: 50, scale: 0.3 }}
                                        animate={{ opacity: 1, y: 0, scale: 1 }}
                                        exit={{ opacity: 0, scale: 0.5, transition: { duration: 0.2 } }}
                                    >
                                        <ToastAlarm {...toast}/>
                                    </motion.li>
                                )
                            })}
                        </AnimatePresence>
                    </div>
                </ul>
            </div>
        </React.Fragment>
    )
}
