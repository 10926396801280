import { Icon } from '@iconify/react'
import React, { HTMLAttributes } from 'react'

import { useAccount } from '../../hooks/use-account'
import { cn } from '../../libs/utils'


export interface WalletBalanceProps extends HTMLAttributes<HTMLDivElement> {

}

export const WalletBalance = ({
    ...props
}: WalletBalanceProps) => {
    const { points } = useAccount()

    return (
        <div className={cn(
            'w-fit',
            'flex flex-col justify-center',
            'gap-y-4 p-4',
            props.className
        )} style={props.style}>
            <div className={cn(
                'flex flex-col justify-center items-center',
                'text-slate-900'
            )}>
                <span className={cn(
                    'text-sm',
                    'font-light'
                )}>Data Points</span>

                <div className={cn(
                    'flex items-center'
                )}>
                    <span className={cn(
                        'text-3xl',
                        'text-amber-500',
                        'font-extrabold',
                        'mx-0.5',
                        'select-none'
                    )}>
                        {points}
                    </span>
                    <Icon icon='fluent-emoji:coin' className='size-12'/>
                </div>
            </div>
        </div>
    )
}
