import { useAtom } from 'jotai/index'
import React, { useCallback, useEffect, useState } from 'react'
import { BottomSheet } from 'react-spring-bottom-sheet'

import { useAdapter } from '../../hooks/use-adapter'
import { consentSheetPropsAtom } from '../../hooks/use-sheet-consent'
import logger from '../../libs/helper/helper.logger'
import { cn, snooze } from '../../libs/utils'
import { BadgeCompactDataTag, BadgeCompactTokenizerVersion } from '../badge/badge-compact'
import { ButtonSolid } from '../button/button-solid'
import { CardApp } from '../card/card-app'
import { CardBlurry } from '../card/card-blurry'
import { LabelCardTitle } from '../label/label-card-title'
import { SpinnerDot3 } from '../spinner/spinner-dot3'


export const SheetConsent = () => {
    const [consentProps, setConsentProps] = useAtom(consentSheetPropsAtom)
    const { adapter, updateConsent } = useAdapter(consentProps?.adapterId)

    const [submitting, setSubmitting] = useState(false)

    const handleDismiss = useCallback(() => {
        setConsentProps(null)
    }, [setConsentProps])

    const handleLater = useCallback(async () => {
        consentProps?.onConfirm?.call(this, false)
        handleDismiss()
    }, [consentProps?.onConfirm, handleDismiss])

    const handleConsent = useCallback(async () => {
        if (!consentProps) return
        if (submitting) return
        setSubmitting(true)

        try {
            await updateConsent(true)
            consentProps.onConfirm?.call(this, true)
        } catch (e) {
            logger.error('SheetConsent.handleConsent', e)
        } finally {
            await snooze(600)

            setSubmitting(false)
            handleDismiss()
        }
    }, [consentProps, handleDismiss, submitting, updateConsent])

    const renderDataTypesView = useCallback(() => {
        return adapter?.tokenizer.rewards.map((reward, idx) => {
            return <BadgeCompactDataTag key={idx} text={reward.unit}/>
        })
    }, [adapter?.tokenizer.rewards])

    useEffect(() => {
        if (!adapter) {
            handleDismiss()
        }
    }, [adapter, handleDismiss])

    return (
        <BottomSheet
            open={!!consentProps}
            onDismiss={handleLater}
            defaultSnap={({ snapPoints }) => Math.min(...snapPoints)}
            snapPoints={({ maxHeight }) => [maxHeight * 0.9, maxHeight * 0.8]}
            footer={
                <div className='w-full h-12 flex items-center gap-x-2'>
                    <ButtonSolid className={cn(
                        'h-full',
                        'flex justify-center items-center gap-x-1',
                        'px-4'
                    )} onClick={handleLater}>
                        <span className='text-'>Decline</span>
                    </ButtonSolid>
                    <ButtonSolid className={cn(
                        'container h-full',
                        'flex justify-center items-center gap-x-1',
                        'bg-cyan-500 text-white font-semibold'
                    )} onClick={handleConsent}>
                        {
                            submitting
                            ? <SpinnerDot3 className='size-6 text-white'/>
                            : <span className='text-lg'>Continue</span>
                        }
                    </ButtonSolid>
                </div>
            }
            expandOnContentDrag={true}
        >
            <div className={cn(
                'w-full',
                'grid grid-cols-2 gap-2',
                'p-4'
            )}>
                <span className='w-full text-lg text-center font-medium col-span-2'>
                    Consent for Data Tokenizing
                </span>

                <CardBlurry className='col-span-2'>
                    <LabelCardTitle text={'Data Channel'}/>
                    <CardApp app={adapter} flat={true}/>
                </CardBlurry>

                <CardBlurry className='col-span-1'>
                    <LabelCardTitle text={'Data Tokenizer'}/>
                    <p className='text-sm text-start text-slate-900/60'>
                        {
                            adapter?.tokenizer.version === 'alliance'
                            ? 'Powered by Data Channel'
                            : 'Driven by the Community'
                        }
                    </p>
                    <BadgeCompactTokenizerVersion version={adapter?.tokenizer.version}/>
                </CardBlurry>
                <CardBlurry className='col-span-1'>
                    <LabelCardTitle text={'Data Types'}/>
                    <p className='text-sm text-start text-slate-900/60'>
                        The following data may be tokenized:
                    </p>
                    {renderDataTypesView()}
                </CardBlurry>

                <CardBlurry className='col-span-2 gap-y-4 text-sm grow shadow-none ring-0'>
                    <LabelCardTitle text={'Self-Custody'}/>
                    <span>User data connected through the Data Channel will be recorded on the blockchain as an NFT.</span>
                    <span>Ownership of the recorded data belongs to the NFT holder, and no one can access the data without the user’s permission.</span>
                    <span>Users can control and stop the monetization of their data through the NFT at any time.</span>
                </CardBlurry>
            </div>
        </BottomSheet>
    )
}
