import { HTMLAttributes } from 'react'

import { cn } from '../../libs/utils'


export interface BadgeTextProps extends HTMLAttributes<HTMLSpanElement> {
    text: string
}

export const BadgeText = ({
    text,
    ...props
}: BadgeTextProps) => {
    return (
        <span className={cn(
            'w-fit',
            'inline-flex items-center',
            'rounded-full',
            'px-2.5 py-1',
            'text-sm font-medium',
            'ring-1 ring-inset ring-slate-200',
            props.className
        )}>
            {text}
        </span>
    )
}
