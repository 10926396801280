import { ContainerBase } from './container-base'
import { ContainerScreen } from './container-screen'


const Container = {
    Base: ContainerBase,
    Screen: ContainerScreen
}

export default Container
