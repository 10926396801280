import React, { HTMLAttributes, useMemo } from 'react'

import { cn } from '../../libs/utils'


export interface LabelSectionInlinedProps extends HTMLAttributes<HTMLDivElement> {
    text?: string
    subtext?: string
    size?: 'sm' | 'lg' | '2xl'
}

export const LabelSection = ({
    text,
    subtext,
    size,
    ...props
}: LabelSectionInlinedProps) => {

    const sizes = useMemo(() => {
        switch (size) {
            case 'sm':
                return ['text-sm', 'text-xs']
            case '2xl':
                return ['text-2xl', 'text-base']
            default:
                return ['text-lg', 'text-sm']
        }
    }, [size])

    return (
        <div className={cn(
            'flex flex-col',
            'whitespace-pre-line',
            'text-slate-900',
            props.className
        )}>
            {text && (<span className={`${sizes[0]} font-semibold`}>{text}</span>)}
            {subtext && (<span className={`${sizes[1]} opacity-60`}>{subtext}</span>)}

            {props.children}
        </div>
    )
}
