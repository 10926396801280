import { Icon } from '@iconify/react'
import { AnimatePresence, motion } from 'framer-motion'
import { isEmpty } from 'lodash'
import React, { useCallback } from 'react'
import { useNavigate } from 'react-router-dom'

import { cn } from '../../libs/utils'
import Logo from '../logo'


export interface HeaderPageProps {
    back?: boolean | string
    title?: string
    icon?: string
    logo?: boolean
    to?: string
}

export const HeaderPage = ({
    back = false,
    title = '',
    icon,
    logo,
    to
}: HeaderPageProps) => {
    const navigate = useNavigate()

    const handleBack = useCallback(() => {
        if (to) {
            navigate(to)
        } else {
            navigate(-1)
        }
    }, [navigate, to])

    return (
        <div className={cn(
            'absolute top-0',
            'max-w-screen-sm w-full h-14',
            'bg-white/80 backdrop-blur-md',
            'text-slate-900',
            'z-10'
        )}>
            <nav className={cn(
                'absolute top-0 w-full h-full',
                'flex items-center gap-x-4'
            )}>
                <AnimatePresence>
                    {title && (
                        <motion.div
                            className={cn(
                                'absolute',
                                'top-0 bottom-0',
                                'left-12 right-12',
                                'flex justify-center items-center',
                                'font-medium text-lg text-slate-900',
                                'whitespace-pre-line',
                                'pointer-events-none'
                            )}
                            initial={{ opacity: 0 }}
                            animate={{ opacity: 1 }}
                            transition={{ delay: 0.2 }}
                        >
                            {title}
                        </motion.div>
                    )}
                </AnimatePresence>

                <AnimatePresence>
                    {!!back && (
                        <div className={cn(
                            'h-full aspect-square',
                            'flex items-center',
                            'text-slate-900',
                            'cursor-pointer',
                            'pl-2',
                            back ? 'visible' : 'hidden'
                        )} onClick={handleBack}>
                            <motion.div
                                initial={{ opacity: 0 }}
                                animate={{ opacity: 1 }}
                                transition={{ delay: 0.2 }}
                            >
                                <Icon icon={icon ?? 'mingcute:left-line'} className='size-7'/>
                            </motion.div>

                            {!isEmpty(back) && (
                                <motion.span
                                    initial={{ x: '15%', opacity: 0 }}
                                    animate={{ x: '0%', opacity: 1 }}
                                    transition={{ delay: 0.2 }}
                                    className='font-light'
                                >
                                    {back.toString()}
                                </motion.span>
                            )}
                        </div>
                    )}
                </AnimatePresence>

                <AnimatePresence>
                    {logo && (
                        <motion.div
                            className={cn(
                                'pointer-events-none',
                                'pl-3'
                            )}
                            initial={{ opacity: 0 }}
                            animate={{ opacity: 1 }}
                        >
                            <Logo.Text className='mt-2 text-slate-600/30'/>
                        </motion.div>
                    )}
                </AnimatePresence>

                <div className='grow'/>
            </nav>
        </div>
    )
}
