import { head } from 'lodash'
import React, { useCallback, useEffect } from 'react'

import { Adapters } from '../../adapter/adapter.list'
import { AdapterRewards } from '../../adapter/adapter.types'
import Button from '../../components/button'
import Card from '../../components/card'
import Label from '../../components/label'
import Page from '../../components/page'
import Wallet from '../../components/wallet'
import { KeyringState } from '../../contexts/keyring/keyring-provider'
import { useKeyring } from '../../hooks/use-keyring'
import { useSafeNavigate } from '../../hooks/use-safe-navigate'
import { useToasts } from '../../hooks/use-toasts'
import { cn } from '../../libs/utils'


export const ViewMainPlaying = () => {
    const { state } = useKeyring()
    const { showCreateWalletAlarm } = useToasts()
    const { safeNavigate } = useSafeNavigate()

    useEffect(() => {
        if (state === KeyringState.KeyringRequired) {
            showCreateWalletAlarm()
        }
    }, [showCreateWalletAlarm, state])

    const renderAdapters = useCallback(() => {
        return Adapters.map((adapter, idx) => {
            const reward: AdapterRewards | undefined = head(adapter.tokenizer.rewards)
            return (
                <Card.Adapter
                    key={`${adapter.id}+${idx}`}
                    adapter={adapter}
                    action={reward && (
                        <Button.Point
                            value={reward.value}
                            unit={reward?.repeat ? reward.unit : ''}
                        />
                    )}
                    onClick={() => safeNavigate(`/adapter/${adapter.id}`)}
                />
            )
        })
    }, [safeNavigate])

    return (
        <Page.AnimatedTab>
            <Wallet.Account
                className='sticky top-2 ml-auto mr-2 z-20 shadow-lg'
                onClick={() => safeNavigate('/settings')}
            />

            <Wallet.Balance className='fixed left-1/2 -translate-x-1/2 top-40 -translate-y-1/2'/>

            <div className={cn(
                'w-full grow',
                'flex flex-col items-center',
                'mt-80',
                'z-10'
            )}>
                <div className={cn(
                    'w-full h-full grow',
                    'p-4 pb-6',
                    'bg-white/80 backdrop-blur-lg',
                    'ring-1 ring-black/5',
                    'rounded-t-3xl',
                    'z-10'
                )}>
                    <Label.Section text={'Data Tokenizing'} subtext={'Own Your Data as a Token'}/>
                    <div className='mt-2 flex flex-col gap-2'>
                        {renderAdapters()}
                    </div>
                </div>
            </div>
        </Page.AnimatedTab>
    )
}
