import ViewKeyringCreate from './view-keyring-create'
import ViewKeyringOAuth from './view-keyring-oauth'


const ViewKeyring = {
    Create: ViewKeyringCreate,
    OAuth: ViewKeyringOAuth
}

export default ViewKeyring
