import { Icon } from '@iconify/react'
import { cva, VariantProps } from 'class-variance-authority'
import React, { HTMLAttributes, useMemo } from 'react'

import { cn } from '../../libs/utils'


const Styles = {
    it: 'rounded-full overflow-hidden bg-slate-200'
}

// noinspection TypeScriptValidateTypes
const Variants = cva(Styles.it, {
    variants: {
        size: {
            md: 'size-12',
            lg: 'size-16'
        },
        profile: {
            true: '',
            false: 'rounded-xl shadow-md'
        }
    },
    defaultVariants: {
        size: 'md',
        profile: false
    }
})

export interface CardLayoutAdapterIconProps extends HTMLAttributes<HTMLDivElement>, VariantProps<typeof Variants> {
    display?: string | { color: string, icon: string, stroke: string }
    icon?: string
}

export const LayoutAdapterIcon = ({
    display,
    icon,
    size,
    ...props
}: CardLayoutAdapterIconProps) => {

    const DisplayView = useMemo(() => {
        if (!display) {
            return <img src={icon} alt=''/>
        } else if (typeof display === 'string') {
            return <img src={display} className='w-full h-full' alt=''/>
        } else {
            return (
                <div className={cn(
                    'w-full h-full',
                    'flex justify-center items-center',
                    'select-none'
                )} style={{ backgroundColor: display.color }}>
                    <Icon icon={display.icon} className='size-7' style={{
                        color: display.stroke
                    }}/>
                </div>
            )
        }
    }, [display, icon])

    return (
        <div className='relative w-fit shrink-0'>
            <div className={cn(
                Variants({ size, profile: !!display }),
                props.className
            )}>
                {DisplayView}
            </div>

            {display && (
                <div className={cn(
                    'absolute -bottom-1.5 -right-1.5',
                    'size-6',
                    'rounded-md overflow-hidden',
                    'ring-2 ring-white',
                    'shadow-lg'
                )}>
                    <img src={icon} alt=''/>
                </div>
            )}
        </div>
    )
}
