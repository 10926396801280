import { Icon } from '@iconify/react'
import { motion } from 'framer-motion'
import { isEmpty } from 'lodash'
import moment from 'moment'
import React, { useCallback, useEffect, useMemo, useState } from 'react'
import Countdown from 'react-countdown'
import { useInView } from 'react-intersection-observer'
import { useNavigate } from 'react-router-dom'

import AssetAnimation from '../../assets/animation'
import Button from '../../components/button'
import Card from '../../components/card'
import Label from '../../components/label'
import Page from '../../components/page'
import Profile from '../../components/profile'
import Spinner from '../../components/spinner'
import { useActiveAdapter, useAdapterDataQuery, useAdapterTask } from '../../hooks/use-adapter'
import { useDevicePermissions } from '../../hooks/use-device-permissions'
import { useSheetConsent } from '../../hooks/use-sheet-consent'
import { useSheetPermissions } from '../../hooks/use-sheet-permissions'
import logger from '../../libs/helper/helper.logger'
import { PermissionsKey } from '../../libs/types/device.types'
import { cn, snooze } from '../../libs/utils'
import { AdapterStepperProps } from '../../views/tokenizer/view-tokenizer-steps'
import { AdapterDataPlaceholder } from '../adapter-data-placeholder'
import { IgDataCell } from './ig.data-cell'
import { IgPost } from './ig.types'


export const IgPageCollect = ({ goto, forward }: AdapterStepperProps) => {
    const navigate = useNavigate()

    const { adapterId, profile, consent } = useActiveAdapter()
    const { pending, createTask } = useAdapterTask(adapterId, true)
    const { requestPermission } = useDevicePermissions()
    const { showPermissionsSheetOf } = useSheetPermissions()
    const { showConsentSheetAsync } = useSheetConsent()

    const [submitting, setSubmitting] = useState(false)

    const query = useAdapterDataQuery<IgPost>(adapterId)
    const [inViewRef, inView] = useInView()

    const availableAt = useMemo(() => {
        return moment.unix(profile?.availableAt ?? 0)
    }, [profile])

    const handleCollect = useCallback(async () => {
        let consented = consent?.status === true
        if (!consented) {
            consented = await showConsentSheetAsync({ adapterId })
        }
        await requestPermission(PermissionsKey.att)

        if (!consented || submitting) return
        if (pending || !profile || isEmpty(profile?.username)) return
        if (availableAt.isAfter(moment())) return
        setSubmitting(true)

        try {
            await createTask({ username: profile.username })
        } catch (e) {
            logger.error('ig.page-collect > handleCollect', e)
        } finally {
            setSubmitting(false)

            await snooze(1200)
            showPermissionsSheetOf(PermissionsKey.notification)
        }
    }, [adapterId, availableAt, consent?.status, createTask, pending, profile, requestPermission, showConsentSheetAsync, showPermissionsSheetOf, submitting])

    useEffect(() => {
        if (inView) {
            query.next()
        }
    }, [inView, query])

    return (
        <React.Fragment>
            <Page.AnimatedNested key={'ig.page-collect'}>
                <div className={cn(
                    'w-full',
                    'flex flex-col justify-start items-start gap-y-4'
                )}>
                    <Profile.Adapter adapterId={adapterId}/>

                    <div className='w-full flex flex-col gap-y-2'>
                        <Label.Section text={'Analyzing'} size='2xl' className='ml-2'/>
                        <Card.Blurry className={cn(
                            'text-sm'
                        )}>
                            <div className={cn(
                                'w-full',
                                'flex flex-col justify-center items-center gap-y-2',
                                'text-center'
                            )}>
                                <img src={AssetAnimation.Robot} className='size-12' alt='AI Robot'/>
                                <p className='text-sm'>
                                    Let our AI dive into your latest posts <br/>
                                    to uncover your interests!
                                </p>

                                <Button.Solid className={cn(
                                    'w-full flex justify-center items-center',
                                    'bg-cyan-500 text-white font-semibold'
                                )} onClick={() => navigate('play')}>
                                    <span>Start Analyzing</span>
                                </Button.Solid>
                            </div>
                        </Card.Blurry>
                    </div>

                    <div className='w-full flex flex-col gap-y-2'>
                        <Label.Section text={'Data Collection'} size='2xl' className='ml-2'/>
                        {
                            !query.hasData && (
                                <AdapterDataPlaceholder
                                    text={'Collect Your Data'}
                                    subtext={'Data cannot be retrieved from private accounts'}
                                />
                            )
                        }
                        {
                            query.data?.pages.map((group, i) => (
                                <React.Fragment key={i}>
                                    {
                                        group.items.map((item) => {
                                            return (
                                                <IgDataCell key={item.id} post={item}/>
                                            )
                                        })
                                    }
                                </React.Fragment>
                            ))
                        }
                    </div>
                    <div ref={inViewRef as any}/>
                </div>
            </Page.AnimatedNested>

            <div className={cn(
                'fixed bottom-0 left-0 right-0 p-4',
                'bg-gradient-to-t from-white to-transparent'
            )}>
                <Button.Solid
                    className={cn(
                        'relative',
                        'container h-12',
                        'flex justify-center items-center',
                        'bg-cyan-500 text-white font-semibold'
                    )}
                    onClick={handleCollect}
                    disabled={availableAt.isAfter(moment())}
                >
                    {
                        availableAt.isAfter(moment())
                        ? <Countdown date={availableAt.valueOf()} daysInHours={true}/>
                        : (
                            <React.Fragment>
                                {
                                    <React.Fragment>
                                        <span>{(submitting || pending) ? 'Collecting...' : 'Collect Latest Posts'}</span>
                                        <motion.div className='absolute right-4' layout>
                                            {
                                                (submitting || pending)
                                                ? <Spinner.Blocks className='size-6 text-white'/>
                                                : <Icon icon={'heroicons:chevron-right-solid'} className='size-4 text-white stroke-2 stroke-white'/>
                                            }
                                        </motion.div>
                                    </React.Fragment>
                                }
                            </React.Fragment>
                        )
                    }
                </Button.Solid>
            </div>
        </React.Fragment>
    )
}
