import { AnimatePresence } from 'framer-motion'
import React from 'react'

import { ZgPageCollect } from './zg.page-collect'


export const AdapterZg = () => {
    return (
        <AnimatePresence initial={false}>
            <ZgPageCollect/>
        </AnimatePresence>
    )
}
