import { Icon } from '@iconify/react'
import React, { ButtonHTMLAttributes } from 'react'

import { cn } from '../../libs/utils'
import Logo from '../logo'


export interface ButtonZingProps extends ButtonHTMLAttributes<HTMLButtonElement> {
    text?: string
}

export const ButtonZing = ({
    text,
    ...props
}: ButtonZingProps) => {

    return (
        <button onClick={props.onClick} className={cn(
            'flex items-center',
            'h-9',
            'px-2.5 py-1.5',
            'bg-white',
            'rounded-xl',
            'shadow-md',
            props.className
        )} type={props.type} disabled={props.disabled}>
            {
                text
                ? <span className='text-base text-cyan-500 font-extrabold select-none'>{text}</span>
                : <Logo.Text className='mt-1.5 h-5 text-lg'/>
            }
            <Icon icon={'heroicons:chevron-right-solid'} className='size-4 text-cyan-500 stroke-2 stroke-cyan-500 ml-0.5 -mr-1'/>
        </button>
    )
}
