import { atom, useSetAtom } from 'jotai'
import { useCallback } from 'react'

import { generatePasscodePads } from '../libs/utils'


export type PasscodeSheetMode = 'create' | 'confirm'

export interface PasscodeSheetProps {
    mode: PasscodeSheetMode
    text?: string
    subtext?: string
    numPads?: string[]
    alphaPads?: string[]
    dismissible?: boolean
    onConfirm?: (passcode: string, mode: PasscodeSheetMode) => Promise<[boolean, ...any[]]>
}

export const passcodeSheetPropsAtom = atom<PasscodeSheetProps | null>()

export const useSheetPasscode = () => {
    const setPasscodeSheetProps = useSetAtom(passcodeSheetPropsAtom)

    const showPasscodeSheet = useCallback((props: PasscodeSheetProps) => {
        const { numPads, alphaPads } = generatePasscodePads()
        setPasscodeSheetProps({ ...props, numPads, alphaPads })
    }, [setPasscodeSheetProps])

    const showPasscodeSheetAsync = useCallback((props: Partial<PasscodeSheetProps>): Promise<[boolean, ...any[]]> => {
        return new Promise((resolve) => {
            const { numPads, alphaPads } = generatePasscodePads()
            setPasscodeSheetProps({
                ...props,
                mode: props.mode ?? 'confirm',
                numPads,
                alphaPads,
                onConfirm: async (passcode, mode) => {
                    let results: [boolean, ...any[]] = [true]
                    try {
                        if (props.onConfirm) {
                            results = await props.onConfirm(passcode, mode)
                        }
                        return results
                    } catch (e) {
                        return [false]
                    } finally {
                        resolve(results)
                    }
                }
            })
        })
    }, [setPasscodeSheetProps])

    const hidePasscodeSheet = useCallback(() => setPasscodeSheetProps(null), [setPasscodeSheetProps])

    return {
        showPasscodeSheet,
        showPasscodeSheetAsync,
        hidePasscodeSheet
    }
}
