import React, { HTMLAttributes } from 'react'

import { cn } from '../../libs/utils'


export interface LabelTitleProps extends HTMLAttributes<HTMLDivElement> {
    text?: string
}

export const LabelCardTitle = ({
    text,
    ...props
}: LabelTitleProps) => {
    return (
        <div className={cn(
            'flex flex-col',
            'whitespace-pre-line',
            props.className
        )}>
            {text && (<span className='text-sm font-light text-slate-900'>{text}</span>)}
            {props.children}
        </div>
    )
}
