import { Icon } from '@iconify/react'
import React, { HTMLAttributes } from 'react'

import { cn } from '../../libs/utils'


export interface BadgeCompactProps extends HTMLAttributes<HTMLSpanElement> {
    text: string
    icon?: string
    color?: string
}

export const BadgeCompact = ({
    text,
    icon,
    color,
    ...props
}: BadgeCompactProps) => {
    return (
        <span className={cn(
            'w-fit',
            'inline-flex items-center gap-x-0.5',
            'rounded-full',
            'text-sm font-medium',
            props.className
        )}>
            {icon && <Icon icon={icon} className='size-5 shrink-0'/>}
            {text}
        </span>
    )
}

export const BadgeCompactTokenizerVersion = ({
    version
}: { version?: 'alliance' | 'community' }) => {
    return version === 'alliance'
           ? <BadgeCompact text={'Alliance'} icon={'ph:seal-check-duotone'} className='text-slate-600'/>
           : <BadgeCompact text={'Community'} icon={'ph:users-three-duotone'} className='text-slate-600'/>
}

export const BadgeCompactDataTag = ({ text = '' }: { text?: string }) => <BadgeCompact text={text} icon={'ph:cube-duotone'} className='text-slate-600'/>
