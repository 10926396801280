import { cva, VariantProps } from 'class-variance-authority'
import { isEmpty } from 'lodash'
import { HTMLAttributes } from 'react'

import { cn } from '../../libs/utils'


const Styles = {
    it: 'font-bold text-slate-900',
    size: {
        sm: { text: 'text-md' },
        md: { text: 'text-lg' },
        lg: { text: 'text-xl' },
        xl: { text: 'text-2xl' }
    }
}

// noinspection TypeScriptValidateTypes
const ProfileNameVariants = cva(Styles.it, {
    variants: {
        size: {
            sm: Object.values(Styles.size.sm),
            md: Object.values(Styles.size.md),
            lg: Object.values(Styles.size.lg),
            xl: Object.values(Styles.size.xl)
        }
    },
    defaultVariants: {
        size: 'sm'
    }
})

export interface ProfileNameProps extends HTMLAttributes<HTMLSpanElement>, VariantProps<typeof ProfileNameVariants> {
    name?: string
}

export const ProfileName = ({
    name = '',
    size,
    ...props
}: ProfileNameProps) => {

    if (isEmpty(name)) {
        return (
            <div className={cn(
                ProfileNameVariants({ size }),
                'w-full min-w-24 h-5',
                'rounded-md',
                'text-transparent',
                'whitespace-pre-wrap',
                'animate-pulse bg-slate-200',
                props.className
            )}>
                {' '}
            </div>
        )
    }

    return (
        <span className={cn(
            ProfileNameVariants({ size }),
            props.className
        )}>
            {name}
        </span>
    )
}
