import { Icon } from '@iconify/react'
import React, { useCallback } from 'react'
import { BottomSheet } from 'react-spring-bottom-sheet'

import { useActiveChain } from '../../hooks/use-chain'
import Chains from '../../libs/blockchain/chains'
import { IChain } from '../../libs/types/blockchain.types'
import { cn } from '../../libs/utils'
import Card from '../card'
import Label from '../label'


export interface SheetChainProps {
    open: boolean
    onDismiss: () => void
}

export const SheetChain = ({
    open,
    onDismiss
}: SheetChainProps) => {
    const { activeChain, switchChain } = useActiveChain()

    const mains = Chains.filter(each => each.testnet !== true)
    const tests = Chains.filter(each => each.testnet === true)

    const handleSelectChain = useCallback((chain: IChain) => {
        if (activeChain.id !== chain.id) {
            switchChain(chain)
        }
        onDismiss()
    }, [activeChain.id, onDismiss, switchChain])

    const renderChains = useCallback((chains: IChain[]) => {
        return chains.map(chain => {
            return (
                <Card.Blurry key={chain.id} className={cn(
                    'flex flex-row items-center gap-x-2'
                )} onClick={() => handleSelectChain(chain)}>
                    <img src={chain.image} className={cn(
                        'size-10',
                        'bg-white',
                        'rounded-full overflow-hidden'
                    )} alt=''/>

                    <span className='text-slate-900 font-medium grow'>
                        {chain.name}
                        {chain.testnet === true && ' Testnet'}
                    </span>

                    {
                        activeChain.id === chain.id
                        ? <Icon icon='mingcute:check-line' className='text-slate-900'/>
                        : <Icon icon='heroicons:chevron-right' className='text-slate-900'/>
                    }
                </Card.Blurry>
            )
        })
    }, [activeChain.id, handleSelectChain])

    return (
        <BottomSheet
            open={open}
            onDismiss={onDismiss}
            snapPoints={({ minHeight }) => minHeight}
        >
            <div className={cn(
                'w-full',
                'flex flex-col',
                'p-4 pb-8'
            )}>
                <span className='w-full text-lg text-center font-medium col-span-2'>
                    Select a network
                </span>

                <Label.Section text={'Enabled Networks'} size='sm' className='mt-6 mb-2'/>
                <div className='w-full flex flex-col gap-y-4'>
                    {renderChains(mains)}
                </div>

                <Label.Section text={'Test Networks'} size='sm' className='mt-6 mb-2'/>
                <div className='w-full flex flex-col gap-y-4'>
                    {renderChains(tests)}
                </div>
            </div>
        </BottomSheet>
    )
}
