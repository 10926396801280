import { Icon } from '@iconify/react'
import { useAtom } from 'jotai'
import { isEmpty } from 'lodash'
import React, { useCallback, useEffect, useRef, useState } from 'react'
import { BottomSheet } from 'react-spring-bottom-sheet'

import { passcodeSheetPropsAtom } from '../../hooks/use-sheet-passcode'
import { cn, generatePasscodePads, snooze } from '../../libs/utils'


export const SheetPasscode = () => {
    const containerRef = useRef<HTMLDivElement | null>(null)
    const dotsRef = useRef<HTMLDivElement | null>(null)

    const [passcodeSheetProps, setPasscodeSheetProps] = useAtom(passcodeSheetPropsAtom)
    const [passcode, setPasscode] = useState('')
    const [passcodeNew, setPasscodeNew] = useState('')

    const handleDismiss = useCallback(() => {
        setPasscode('')
        setPasscodeNew('')
        setPasscodeSheetProps(null)
    }, [setPasscodeSheetProps])

    const shakeDots = useCallback(() => {
        dotsRef.current?.classList.add('animate-shake')
        setTimeout(() => {
            dotsRef.current?.classList.remove('animate-shake')
            setPasscode('')
        }, 350)
    }, [])

    const handleConfirm = useCallback(async () => {
        const delay = 160
        if (passcodeSheetProps?.mode === 'create') {
            if (isEmpty(passcodeNew)) {
                await snooze(delay)
                setPasscodeNew(passcode)
                setPasscode('')

                const { numPads, alphaPads } = generatePasscodePads()
                passcodeSheetProps.numPads = numPads
                passcodeSheetProps.alphaPads = alphaPads
            } else if (passcodeNew === passcode) {
                await snooze(delay)
                passcodeSheetProps?.onConfirm?.call(this, passcode, 'create')
                handleDismiss()
            } else {
                shakeDots()
                await snooze(delay)
                setPasscode('')
            }
            return
        }

        if (!passcodeSheetProps?.onConfirm) {
            await snooze(delay)
            handleDismiss()
            return
        }

        await snooze(30)
        const [check] = await passcodeSheetProps.onConfirm(passcode, 'confirm')
        if (!check) {
            shakeDots()
            await snooze(delay)
            setPasscode('')
            return
        }

        await snooze(delay)
        handleDismiss()
    }, [handleDismiss, passcode, passcodeNew, passcodeSheetProps, shakeDots])

    const handleClickAlphaPad = useCallback((value: string) => {
        if (passcode.length !== 4) return
        setPasscode(prev => prev + value)
    }, [passcode.length, setPasscode])

    const handleClickNumPad = useCallback((value: string) => {
        if (passcode.length >= 4) return
        setPasscode(prev => prev + value)
    }, [passcode.length, setPasscode])

    const handleClickDelete = useCallback((_: string) => {
        setPasscode(prev => prev.slice(0, -1))
    }, [])

    const renderAlphaPads = useCallback(() => {
        if (!passcodeSheetProps?.alphaPads) return null

        return passcodeSheetProps.alphaPads.map(each => {
            let content = <span>{each}</span>
            let action: any = handleClickAlphaPad
            switch (each) {
                case 'noop':
                    content = <React.Fragment/>
                    action = null
                    break
                case 'del':
                    content = <Icon icon={'mingcute:arrow-left-line'}/>
                    action = handleClickDelete
                    break
                default:
            }

            return (
                <div key={each} className={cn(
                    'max-w-[72px]',
                    'flex justify-center items-center',
                    'px-4 py-3',
                    'text-3xl text-slate-300',
                    'hover:opacity-60',
                    'cursor-pointer'
                )} onClick={() => action?.call(this, each)}>
                    {content}
                </div>
            )
        })
    }, [handleClickAlphaPad, handleClickDelete, passcodeSheetProps?.alphaPads])

    const renderNumPads = useCallback(() => {
        if (!passcodeSheetProps?.numPads) return null

        return passcodeSheetProps.numPads.map(each => {
            let content = <span>{each}</span>
            let action: any = handleClickNumPad
            switch (each) {
                case 'bio':
                    content = <React.Fragment/>
                    action = null
                    break
                case 'del':
                    content = <Icon icon={'mingcute:arrow-left-line'} className={cn(
                        passcode.length === 0 ? 'hidden' : 'block'
                    )}/>
                    action = handleClickDelete
                    break
                default:
            }

            return (
                <div key={each} className={cn(
                    'max-w-[72px]',
                    'flex justify-center items-center',
                    'px-4 py-3',
                    'text-3xl text-slate-300',
                    'hover:opacity-60',
                    'cursor-pointer'
                )} onClick={() => action?.call(this, each)}>
                    {content}
                </div>
            )
        })
    }, [handleClickDelete, handleClickNumPad, passcode.length, passcodeSheetProps?.numPads])

    const renderDots = useCallback(() => {
        const dots = Array.from({ length: 5 }).map((_, idx) => {
            const on = idx < passcode.length
            return (
                <div key={idx} className={cn(
                    'size-6 aspect-square rounded-full',
                    on ? 'bg-white' : 'bg-slate-600'
                )}/>
            )
        })
        dots.splice(4, 0, <span key='+' className='text-3xl text-slate-400 font-light'>{'+'}</span>)

        return (
            <div className={cn(
                'flex justify-center items-center gap-x-4'
            )} ref={dotsRef}>
                {dots}
            </div>
        )
    }, [passcode])

    const _styles = useCallback(async () => {
        let parent = containerRef.current?.parentElement
        while (!parent) {
            await snooze(100)
            parent = containerRef.current?.parentElement
        }
        parent?.setAttribute('style', 'height: 100%;')
    }, [])

    useEffect(() => {
        if (!passcodeSheetProps) return

        _styles()
    }, [_styles, passcodeSheetProps])

    useEffect(() => {
        if (passcode.length !== 5) return

        handleConfirm()
    }, [handleConfirm, passcode.length])

    return (
        <BottomSheet
            open={!!passcodeSheetProps}
            onDismiss={passcodeSheetProps?.dismissible ? handleDismiss : undefined}
            snapPoints={({ maxHeight }) => maxHeight * 0.92}
            className={'sheet-passcode'}
        >
            <div className={cn(
                'w-full max-w-screen-sm h-full',
                'flex flex-col justify-start items-center',
                'mx-auto p-4'
            )} ref={containerRef}>
                <div className={cn(
                    'w-full grow',
                    'flex flex-col justify-center items-center gap-y-6'
                )}>
                    <div className={cn(
                        'text-xl text-white text-center font-medium',
                        'flex flex-col justify-center items-center',
                        'whitespace-pre-wrap',
                        'px-4'
                    )}>
                        {
                            isEmpty(passcodeNew)
                            ? <span>{passcodeSheetProps?.text ?? 'Enter your wallet password'}</span>
                            : <span>{'Please re-enter your password'}</span>
                        }
                        {passcodeSheetProps?.subtext && (
                            <span className='text-base opacity-60'>{passcodeSheetProps?.subtext}</span>
                        )}
                    </div>
                    {renderDots()}
                </div>

                <div className={cn(
                    'w-full max-w-[420px]',
                    passcode.length >= 4
                    ? 'grid grid-cols-7 grid-rows-4 gap-x-4 gap-y-2'
                    : 'grid grid-cols-3 grid-rows-4 gap-x-4 gap-y-2',
                    'justify-items-center items-center'
                )}>
                    {
                        passcode.length >= 4
                        ? renderAlphaPads()
                        : renderNumPads()
                    }
                </div>
            </div>
        </BottomSheet>
    )
}
