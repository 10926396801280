import { isEmpty } from 'lodash'
import React, { HTMLAttributes, ImgHTMLAttributes } from 'react'

import Card from '../components/card'
import { cn } from '../libs/utils'


export const ImgPosition = (props: ImgHTMLAttributes<HTMLDivElement>) => {
    return (
        <div className={cn(
            'w-full h-full',
            props.className
        )}>
            {
                isEmpty(props.src) ? (
                    <div className={cn(
                        'w-full h-full',
                        'bg-slate-200',
                        'animate-pulse'
                    )}/>
                ) : (
                    <img
                        src={props.src} alt={props.alt ?? ''}
                        className={cn(
                            'w-full h-full',
                            'object-cover object-center'
                        )}
                    />
                )
            }
        </div>
    )
}

export interface AdapterDataPlaceholderProps extends HTMLAttributes<HTMLDivElement> {
    text?: string
    subtext?: string
}

export const AdapterDataPlaceholder = ({
    text,
    subtext,
    ...props
}: AdapterDataPlaceholderProps) => {
    return (
        <Card.Blurry className={cn(
            'relative',
            'p-2',
            'text-sm',
            props.className
        )}>
            <div className={cn(
                'w-full',
                'grid grid-cols-4 grid-rows-2 gap-0.5',
                'aspect-[2/1]',
                'rounded-xl overflow-hidden'
            )}>
                <ImgPosition className='col-span-2 row-span-2'/>
                <ImgPosition className='col-span-1 row-span-1'/>
                <ImgPosition className='col-span-1 row-span-1'/>
                <ImgPosition className='col-span-1 row-span-1'/>
                <ImgPosition className='col-span-1 row-span-1'/>
            </div>

            <div className={cn(
                'w-full',
                'absolute left-1/2 top-1/2 -translate-x-1/2 -translate-y-1/2',
                'flex flex-col justify-center items-center gap-2',
                'text-center'
            )}>
                <span className='text-lg'>{text}</span>
                <span className='text-sm text-slate-600 whitespace-pre-wrap'>{subtext}</span>
            </div>
        </Card.Blurry>
    )
}
