import { QueryClient, QueryClientProvider } from '@tanstack/react-query'
import BigNumber from 'bignumber.js'
import React from 'react'
import { BrowserRouter, Navigate, Route, Routes } from 'react-router-dom'

import Popup from './components/popup'
import Sheet from './components/sheet'
import Toast from './components/toast'
import AccountProvider from './contexts/account'
import ChainProvider from './contexts/chain'
import DeviceProvider from './contexts/device'
import KeyringProvider from './contexts/keyring'
import ViewAdapter from './views/adapter'
import ViewKeyring from './views/keyring'
import ViewMain from './views/main'


const queryClient = new QueryClient({
    defaultOptions: {
        queries: {
            staleTime: 300_000,
            refetchInterval: 180_000
        }
    }
})

BigNumber.config({ DECIMAL_PLACES: 18, EXPONENTIAL_AT: [-180, 180] })

function App() {
    return (
        <BrowserRouter>
            <QueryClientProvider client={queryClient}>
                <KeyringProvider>
                    <AccountProvider>
                        <DeviceProvider>
                            <ChainProvider>
                                <Routes>
                                    <Route path='/' element={<ViewMain.Layout/>}>
                                        <Route index element={<ViewMain.Wallet/>}/>
                                        <Route path='/playing' element={<ViewMain.Playing/>}/>
                                        <Route path='/settings' element={<ViewMain.Settings/>}/>
                                    </Route>

                                    <Route path='/keyring' element={<ViewKeyring.Create/>}/>
                                    <Route path='/keyring/:provider' element={<ViewKeyring.OAuth/>}/>
                                    <Route path='/adapter/:adapterId' element={<ViewAdapter/>}/>
                                    <Route path='*' element={<Navigate to='/'/>}/>
                                </Routes>

                                <Toast.Container/>
                                <Popup.Container/>

                                <Sheet.Auth/>
                                <Sheet.PassCode/>
                                <Sheet.Permissions/>

                                {/* TODO merge Sheet.Agreement + Sheet.Consent */}
                                <Sheet.Agreement/>
                                <Sheet.Consent/>

                            </ChainProvider>
                        </DeviceProvider>
                    </AccountProvider>
                </KeyringProvider>
            </QueryClientProvider>
        </BrowserRouter>
    )
}

export default App
