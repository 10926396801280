import React, { HTMLAttributes } from 'react'

import { cn } from '../../libs/utils'


export interface ContainerScreenProps extends HTMLAttributes<HTMLDivElement> {

}

export const ContainerScreen = ({
    ...props
}: ContainerScreenProps) => {
    return (
        <div className={cn(
            'relative',
            'max-w-screen-sm w-full min-h-screen h-full',
            'flex flex-col justify-center items-center',
            'overflow-x-hidden overflow-y-scroll scrollbar-hide overscroll-none',
            'mx-auto',
            props.className
        )}>
            {props.children}
        </div>
    )
}
