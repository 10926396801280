import { LayoutMain } from './layout-main'
import { ViewMainPlaying } from './view-main-playing'
import { ViewMainSettings } from './view-main-settings'
import { ViewMainWallet } from './view-main-wallet'


const ViewMain = {
    Layout: LayoutMain,
    Wallet: ViewMainWallet,
    Playing: ViewMainPlaying,
    Settings: ViewMainSettings
}

export default ViewMain
