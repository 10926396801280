import { Icon } from '@iconify/react'
import React, { HTMLAttributes } from 'react'

import { cn } from '../../libs/utils'


export interface BadgeIconProps extends HTMLAttributes<HTMLSpanElement> {
    text: string
    icon?: string
    color?: string
}

export const BadgeIcon = ({
    text,
    icon,
    color,
    ...props
}: BadgeIconProps) => {
    return (
        <span className={cn(
            'w-fit',
            'inline-flex items-center gap-x-0.5',
            'rounded-full',
            'px-2 py-1',
            'text-sm font-medium',
            'ring-1 ring-inset ring-slate-200',
            props.className
        )}>
            {icon && <Icon icon={icon} className='size-5 shrink-0'/>}
            {text}
        </span>
    )
}

export const BadgeIconDataTag = ({ text = '' }: { text?: string }) => <BadgeIcon text={text} icon={'ph:cube-duotone'} className='text-slate-600'/>
export const BadgeIconWallet = ({ text = '' }: { text?: string }) => <BadgeIcon text={text} icon={'ph:wallet-duotone'} className='text-slate-600'/>
