import { FirebaseOptions, getApps, initializeApp } from '@firebase/app'
import { browserLocalPersistence, connectAuthEmulator, getAuth, setPersistence } from '@firebase/auth'
import { connectFirestoreEmulator, initializeFirestore, persistentLocalCache } from '@firebase/firestore'
import { connectFunctionsEmulator, getFunctions, httpsCallable } from '@firebase/functions'
import { getStorage, ref } from '@firebase/storage'

import { firebaseConfig } from './config'


export const useEmulator = false

export const firebaseApp = getApps().length === 0
                           ? initializeApp(firebaseConfig as FirebaseOptions)
                           : getApps()[0]

export const _auth = getAuth(firebaseApp)
setPersistence(_auth, browserLocalPersistence)

export const _db = initializeFirestore(firebaseApp, {
    localCache: persistentLocalCache()
    // TODO @firebase/firestore: Firestore (10.13.2): Error using user provided cache. Falling back to memory cache: FirebaseError: [code=failed-precondition]: Failed to obtain exclusive access to the persistence layer. To allow shared access, multi-tab synchronization has to be enabled in all tabs. If you are using `experimentalForceOwningTab:true`, make sure that only one tab has persistence enabled at any given time.
    // localCache: persistentLocalCache({tabManager: persistentMultipleTabManager()})
    // cacheSizeBytes: CACHE_SIZE_UNLIMITED,
})

export const _storage = getStorage(firebaseApp)
export const storageRef = (path: string) => ref(_storage, path)

export const _functions = getFunctions(firebaseApp)
export const callableOf = <RequestData = unknown, ResponseData = unknown>(name: string) =>
    useEmulator
    ? httpsCallable<RequestData, ResponseData>(_functions, name)
    : httpsCallable<RequestData, ResponseData>(getFunctions(firebaseApp, 'asia-northeast3'), name)

if (useEmulator) {
    connectAuthEmulator(_auth, 'http://127.0.0.1:9099')
    connectFirestoreEmulator(_db, '127.0.0.1', 8080)
    connectFunctionsEmulator(_functions, '127.0.0.1', 5001)
    // connectStorageEmulator(storage, '127.0.0.1', 9199)
}
