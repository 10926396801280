import { Switch } from '@headlessui/react'
import React, { useCallback } from 'react'

import { cn } from '../../libs/utils'


export interface ButtonSwitchProps {
    checked?: boolean
    onBeforeOn?: () => Promise<boolean>
    onBeforeOff?: () => Promise<boolean>
    onChange?: (value: boolean) => void
}

export const ButtonSwitch = ({
    checked,
    onBeforeOn,
    onBeforeOff,
    onChange
}: ButtonSwitchProps) => {
    const handleChange = useCallback(async (value: boolean) => {
        const onBefore = value ? onBeforeOn : onBeforeOff
        if (onBefore) {
            const validation = await onBefore()
            if (!validation) return
        }

        onChange?.call(this, value)
    }, [onBeforeOff, onBeforeOn, onChange])

    return (
        <React.Fragment>
            <Switch
                checked={checked}
                onChange={handleChange}
                className={cn(
                    'group relative',
                    'inline-flex',
                    'h-6 w-11',
                    'flex-shrink-0',
                    'cursor-pointer',
                    'rounded-full',
                    'border-2 border-transparent',
                    'bg-slate-300',
                    'transition-colors duration-200 ease-in-out data-[checked]:bg-cyan-500'
                )}
            >
                <span
                    aria-hidden='true'
                    className={cn(
                        'pointer-events-none',
                        'inline-block',
                        'h-5 w-5',
                        'transform',
                        'rounded-full',
                        'bg-white',
                        'shadow',
                        'ring-0',
                        'transition duration-200 ease-in-out group-data-[checked]:translate-x-5'
                    )}
                />
            </Switch>
        </React.Fragment>
    )
}
