import React, { useMemo } from 'react'
import { Navigate } from 'react-router-dom'

import { AdapterIgSteps } from '../../adapter/ig'
import { AdapterNrcSteps } from '../../adapter/nrc'
import { AdapterZg } from '../../adapter/zg'
import Page from '../../components/page'
import { useActiveAdapter } from '../../hooks/use-adapter'


const ViewAdapter = () => {
    const { adapter } = useActiveAdapter()

    const Components = useMemo(() => {
        switch (adapter?.id) {
            case 'zg':
                return AdapterZg
            case 'ig':
                return AdapterIgSteps
            case 'nrc':
                return AdapterNrcSteps
            default:
        }
        return React.Fragment
    }, [adapter?.id])

    if (!adapter) return <Navigate to={'/'} replace={true}/>
    return (
        <Page.Nested back={true} title={adapter.cta}>
            <Components/>
        </Page.Nested>
    )
}

export default ViewAdapter
