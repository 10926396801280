import { AuthError, AuthErrorCodes, createUserWithEmailAndPassword, GoogleAuthProvider, signInWithCustomToken, signInWithEmailAndPassword, signInWithPopup, UserCredential } from '@firebase/auth'
import { DocumentReference, QuerySnapshot, Unsubscribe } from '@firebase/firestore'
import { getDownloadURL, uploadString } from '@firebase/storage'
import axios from 'axios'
import { collection, doc, getDoc, getDocs, limit, onSnapshot, orderBy, query, setDoc, startAfter, updateDoc, where, writeBatch } from 'firebase/firestore'
import { isEmpty, omitBy } from 'lodash'
import moment from 'moment'
import { nanoid } from 'nanoid'

import { AdapterConsent, AdapterStatus, AdapterTask } from '../adapter/adapter.types'
import { _auth, _db, callableOf, storageRef, useEmulator } from './firebase'
import { encryptData, splitSecretToShares } from './helper/helper.crypto'
import { getKeyringShare1, setCachedKeyring, setKeyringShare1 } from './helper/helper.keyring'
import logger from './helper/helper.logger'
import { Account, AccountCreateCall, Keyring, Share1, Share2, Share3 } from './types/account.types'
import { DataConnection, NextToken } from './types/api.types'
import { AuthProviderType } from './types/auth.types'
import { buildQueryString, getRedirectUrlOf } from './utils'


const auth = {
    currentUser: () => _auth.currentUser,
    stateReady: async () => {
        return await _auth.authStateReady()
    },
    signUpWithEmail: async (email: string, password: string): Promise<string | undefined> => {
        try {
            const credential = await createUserWithEmailAndPassword(_auth, email, password)
            return credential.user.uid
        } catch (e: any) {
            if (e satisfies AuthError) {
                if (e.code === AuthErrorCodes.EMAIL_EXISTS) {
                    return await auth.signInWithEmail(email, password)
                }
            }
        }
    },
    signInWithEmail: async (email: string, password: string): Promise<string | undefined> => {
        const credential = await signInWithEmailAndPassword(_auth, email, password)
        return credential.user.uid
    },
    signInWithGoogle: async () => {
        const provider = new GoogleAuthProvider()
        provider.addScope('https://www.googleapis.com/auth/contacts.readonly')

        const credential = await signInWithPopup(_auth, provider)
        return credential.user.uid
    },
    getOAuthUrlOf: (provider: AuthProviderType): string => {
        switch (provider) {
            case 'google': {
                const url = new URL('https://accounts.google.com/o/oauth2/auth')
                url.search = buildQueryString({
                    client_id: process.env.REACT_APP_GOOGLE_CLIENT_ID,
                    scope: [
                        'https://www.googleapis.com/auth/userinfo.email',
                        'https://www.googleapis.com/auth/userinfo.profile'
                    ].join(' '),
                    response_type: 'code',
                    redirect_uri: getRedirectUrlOf('google')
                })
                return url.toString()
            }
            case 'discord': {
                const url = new URL('https://discord.com/oauth2/authorize')
                url.search = buildQueryString({
                    client_id: process.env.REACT_APP_DISCORD_CLIENT_ID,
                    scope: ['identity', 'email'].join('+'),
                    response_type: 'code',
                    redirect_uri: getRedirectUrlOf('discord')
                })
                return url.toString()
            }
            case 'x': {
                const challenge = nanoid()
                const url = new URL('https://twitter.com/i/oauth2/authorize')
                url.search = buildQueryString({
                    client_id: process.env.REACT_APP_X_CLIENT_ID,
                    scope: ['tweet.read', 'users.read', 'offline.access'].join(' '),
                    response_type: 'code',
                    redirect_uri: getRedirectUrlOf('x'),
                    code_challenge_method: 'plain',
                    code_challenge: challenge,
                    state: 'zing'
                })
                localStorage.setItem(getRedirectUrlOf('x'), challenge)
                return url.toString()
            }
            case 'reddit': {
                const url = new URL('https://www.reddit.com/api/v1/authorize.compact')
                url.search = buildQueryString({
                    client_id: process.env.REACT_APP_REDDIT_CLIENT_ID,
                    scope: ['identity',].join(' '),
                    response_type: 'code',
                    redirect_uri: getRedirectUrlOf('reddit'),
                    duration: 'permanent',
                    state: 'zing'
                })
                return url.toString()
            }
            default:
                break
        }
                return ''
    },
    authorize: async (provider: string, code: string, payload: {
        redirect?: string,
        challenge?: string
    }): Promise<string | null> => {
        const endpoint = 'https://account-authorize-d6dnltbf3q-du.a.run.app'
        const { data } = await axios.post(endpoint, { provider, code, ...payload })

        const accessToken = data?.result
        if (!accessToken || isEmpty(accessToken)) return null

        const credential = await signInWithCustomToken(_auth, accessToken)
        return credential.user.uid
    },
    signInWithCustomToken: async (customToken: string): Promise<UserCredential> => {
        return await signInWithCustomToken(_auth, customToken)
    },
    createAccount: async (keyring: Keyring, passcode: string) => {
        const [s1, s2, s3] = await splitSecretToShares(keyring.s0)
        const s3encrypted = await encryptData(s3, passcode)

        const call = callableOf<AccountCreateCall, boolean>('account-create')
        const result = await call({
            address: keyring.address,
            avatar: keyring.avatar,
            name: keyring.name,
            s2, s3encrypted
        })

        await Promise.all([
            setKeyringShare1(s1),
            setCachedKeyring(keyring)
        ])
        return result.data
    },
    signOut: async () => {
        await _auth.signOut()
    }
}

const database = {
    accountRef: (uid: string): DocumentReference => {
        return doc(_db, 'accounts', uid)
    },
    getKeyringShares: async (uid: string): Promise<Partial<Share1 & Share2 & Share3>> => {
        const [shares2doc, shares3doc] = await Promise.all([
            getDoc(doc(_db, '_shares2', uid)),
            getDoc(doc(_db, '_shares3', uid))
        ])

        const share1 = await getKeyringShare1()
        const share2 = shares2doc.data() as Share2 | undefined
        const share3 = shares3doc.data() as Share3 | undefined
        return {
            uid: share2?.uid,
            address: share2?.address,
            avatar: share2?.avatar,
            name: share2?.name,
            s1: share1,
            s2: share2?.s2,
            s3encrypted: share3?.s3encrypted
        }
    },
    updateAccountFCM: async (uid: string, fcmToken: string) => {
        const ref = database.accountRef(uid)
        await setDoc(ref, { fcmToken }, { merge: true })
    },
    updateAccountATT: async (uid: string, attToken: string) => {
        const ref = database.accountRef(uid)
        await setDoc(ref, { attToken }, { merge: true })
    },
    updateAccountTokens: async (uid: string, tokens: { fcmToken: string, attToken: string }) => {
        const ref = database.accountRef(uid)
        const filtered = omitBy(tokens, isEmpty)
        await setDoc(ref, { ...filtered }, { merge: true })
    },
    updateAccountProfile: async (uid: string, avatar: string, name: string) => {
        const ref = database.accountRef(uid)
        await setDoc(ref, { avatar, name }, { merge: true })
    },
    updateAccountMetadata: async (uid: string, locale: string, tz: number) => {
        try {
            const ref = database.accountRef(uid)
            await setDoc(ref, { locale, tz }, { merge: true })
        } catch (e) {
            logger.error('updateAccountMetadata', e)
        }
    },
    updateAdapterNonce: async (uid: string, field: string, nonce: string) => {
        const ref = database.accountRef(uid)
        await updateDoc(ref, field, nonce)
    },
    updateAdapterConsent: async (uid: string, field: string, data: AdapterConsent) => {
        const ref = database.accountRef(uid)
        await updateDoc(ref, field, data)
    },
    queryAdapterData: async <DataModel = {}>(uid: string, path: string, nextToken?: NextToken, chunk: number = 30): Promise<DataConnection<DataModel>> => {
        const ref = collection(_db, 'accounts', uid, path)
        const q = nextToken
                  ? query(ref, orderBy('timestamp', 'desc'), startAfter(nextToken), limit(chunk))
                  : query(ref, orderBy('timestamp', 'desc'), limit(chunk))

        const snapshot = await getDocs(q)
        return {
            items: snapshot.docs.map(doc => doc.data() as DataModel),
            nextToken: snapshot.docs.length >= chunk ? snapshot.docs[snapshot.docs.length - 1] : undefined
        }
    },
    cancelAdapterTasks: async (uid: string, path: string) => {
        const ref = collection(_db, 'accounts', uid, path)
        const q = query(ref, where('status', '!=', AdapterStatus.Ok))
        const snapshot = await getDocs(q)

        const batch = writeBatch(_db)
        snapshot.forEach(doc => {
            batch.delete(doc.ref)
        })
        await batch.commit()
    },
    createAdapterTask: async (uid: string, path: string, params: Record<string, any> = {}) => {
        const task: AdapterTask = {
            id: nanoid(21),
            uid,
            status: AdapterStatus.Request,
            timestamp: moment().unix()
        }

        const ref = doc(_db, 'accounts', uid, path, task.id)
        await setDoc(ref, {
            ...params,
            ...task
        } as any, { merge: true })
    },
    onAdapterCollectionChanged: (
        uid: string,
        path: string,
        onNext?: (snapshot: QuerySnapshot, status: { pending: boolean, completed: boolean }) => void,
        chunk: number = 3
    ): Unsubscribe => {
        const ref = collection(_db, 'accounts', uid, path)
        const q = query(ref, orderBy('timestamp', 'desc'), limit(chunk))

        return onSnapshot(q, async (snapshot) => {
            const completed = snapshot.docChanges().some(change => {
                if (change.type === 'modified') {
                    const task = change.doc.data() as AdapterTask
                    return task.status === AdapterStatus.Ok
                }
            })

            const pending = snapshot.docs.some(doc => {
                const task = doc.data() as AdapterTask
                return task?.status === AdapterStatus.Request || task?.status === AdapterStatus.Running
            })

            onNext?.call(this, snapshot, { pending, completed })
        })
    },
    onAccountChanged: (
        uid: string,
        onNext?: (account: Account) => void
    ): Unsubscribe | undefined => {
        const ref = database.accountRef(uid)
        return onSnapshot(ref, async (snapshot) => {
            if (!snapshot.exists()) return

            const account = snapshot.data() as Account

            onNext?.call(this, account)
        })
    }
}

const functions = {
    nonceRequest: async (address: string) => {
        const endpoint = useEmulator
                         ? 'http://127.0.0.1:5001/a2-zing/asia-northeast3/nonce-request'
                         : 'https://nonce-request-d6dnltbf3q-du.a.run.app'

        const { data } = await axios.post(endpoint, { address })
        if (!isEmpty(data?.code)) throw new Error(data.code)

        return data?.result as string
    },
    nonceVerify: async (address: string, signature: string, avatar?: string, name?: string) => {
        const endpoint = useEmulator
                         ? 'http://127.0.0.1:5001/a2-zing/asia-northeast3/nonce-verify'
                         : 'https://nonce-verify-d6dnltbf3q-du.a.run.app'

        const { data } = await axios.post(endpoint, { address, signature, avatar, name })
        if (!isEmpty(data?.code)) throw new Error(data.code)

        return data?.result as string
    }
}

const storage = {
    uploadDataUrl: async (path: string, dataUrl: string) => {
        const ref = storageRef(path)
        try {
            const result = await uploadString(ref, dataUrl, 'data_url')
            return await getDownloadURL(result.ref)
        } catch {
            return ''
        }
    }
}

const Zing = {
    auth,
    database,
    functions,
    storage
}

export default Zing
