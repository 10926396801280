const config: { [key: string]: any } = {
    apiKey: process.env.REACT_APP_FIREBASE_API_KEY,
    authDomain: process.env.REACT_APP_FIREBASE_AUTH_DOMAIN,
    projectId: process.env.REACT_APP_FIREBASE_PROJECT_ID,
    storageBucket: process.env.REACT_APP_FIREBASE_STORAGE_BUCKET,
    messagingSenderId: process.env.REACT_APP_FIREBASE_MESSAGING_SENDER_ID,
    appId: process.env.REACT_APP_FIREBASE_APP_ID,
    measurementId: process.env.REACT_APP_MEASUREMENT_ID
}

Object.keys(config).forEach((key) => {
    const configValue = config[key] ?? ''
    if (configValue.charAt(0) === '"') {
        config[key] = configValue.substring(1, configValue.length - 1)
    }
})

export const firebaseConfig = config
