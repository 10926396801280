import { Unsubscribe } from '@firebase/firestore'
import React, { createContext, useCallback, useEffect, useRef, useState } from 'react'

import { useKeyring } from '../../hooks/use-keyring'
import { getDeviceMetadata } from '../../libs/helper/helper.device'
import { Account } from '../../libs/types/account.types'
import Zing from '../../libs/zing'


export interface AccountContextProps {
    account?: Account
}

export const Context = createContext<AccountContextProps>({
    account: undefined
})

const AccountProvider: React.FC<{ children: React.ReactNode }> = ({ children }) => {
    const { uid } = useKeyring()

    const [account, setAccount] = useState<Account>()
    const unsub = useRef<Unsubscribe>()

    const attachAccountListener = useCallback(async () => {
        if (!uid) return
        const metadata = await getDeviceMetadata()

        unsub.current?.call(this)
        unsub.current = Zing.database.onAccountChanged(uid, async (account) => {
            if (account.locale !== metadata.locale || account.tz !== metadata.tz) {
                await Zing.database.updateAccountMetadata(uid, metadata.locale, metadata.tz)
            }
            setAccount(account)
        })
    }, [uid])

    useEffect(() => {
        if (!uid) return

        attachAccountListener()
        return () => unsub.current?.call(this)
    }, [attachAccountListener, uid])

    return (
        <Context.Provider value={{
            account
        }}>{children}</Context.Provider>
    )
}

export default AccountProvider
