import { Icon } from '@iconify/react'
import { useSetAtom } from 'jotai'
import React, { useCallback, useEffect } from 'react'

import { toastAlarmsAtom, ToastProps } from '../../hooks/use-toasts'
import { cn } from '../../libs/utils'


export interface ToastAlarmProps extends ToastProps {

}

export const ToastAlarm = ({
    id,
    text,
    subtext,
    icon,
    iconClassName,
    src,
    ttl,
    onClick,
    ...props
}: ToastAlarmProps) => {
    const setToast = useSetAtom(toastAlarmsAtom)

    const handleDismiss = useCallback(() => {
        setToast((prev) => prev.filter((each) => each.id !== id))
    }, [id, setToast])

    const handleClick = useCallback(() => {
        if (onClick) {
            onClick()
        }
        handleDismiss()
    }, [handleDismiss, onClick])

    const handleClose = useCallback((e: React.MouseEvent) => {
        e.stopPropagation()
        handleDismiss()
    }, [handleDismiss])

    useEffect(() => {
        let timeout = undefined
        if (ttl !== 0) {
            timeout = setTimeout(() => handleDismiss(), ttl ?? 4500)
        }

        return () => {
            if (timeout) {
                return clearTimeout(timeout)
            }
        }
    }, [handleDismiss, ttl])

    return (
        <div className={cn(
            'pointer-events-auto',
            'w-full max-w-xs',
            'rounded-xl overflow-hidden',
            'bg-white',
            'ring-1 ring-black/5',
            'shadow-xl'
        )} onClick={handleClick}>
            <div className='relative flex items-start gap-x-3 p-4'>
                {src && (
                    <div className='flex-shrink-0'>
                        <img src={src} alt='' className={cn(
                            'size-6',
                            iconClassName
                        )}/>
                    </div>
                )}

                {icon && (
                    <div className='flex-shrink-0'>
                        <Icon icon={icon} className={cn(
                            'size-6 text-slate-600',
                            iconClassName
                        )}/>
                    </div>
                )}

                <div className='flex flex-col items-start grow mr-8'>
                    {text && <p className='text-sm font-medium text-slate-900'>{text}</p>}
                    {subtext && <p className='text-sm text-slate-600'>{subtext}</p>}
                </div>
                <div className='absolute top-0 right-0'>
                    <button type='button' onClick={handleClose} className='p-4'>
                        <Icon icon={'heroicons:x-mark'} className='size-5 text-slate-600'/>
                    </button>
                </div>
            </div>
        </div>
    )

    // eslint-disable-next-line no-unreachable
    // return (
    //     <Transition
    //         as={Fragment}
    //         enter='transform ease-out duration-200 transition'
    //         enterFrom='translate-x-full'
    //         enterTo='translate-x-0 opacity-100'
    //         leave='transition ease-in duration-200'
    //         leaveFrom='translate-x-0'
    //         leaveTo='translate-x-full'
    //         show={appear}
    //     >
    //         <div className={cn(
    //             'pointer-events-auto',
    //             'w-full max-w-xs',
    //             'rounded-lg overflow-hidden',
    //             'bg-white',
    //             'ring-1 ring-black/5',
    //             'shadow-xl'
    //         )} onClick={handleClick}>
    //             <div className='relative flex items-start gap-x-3 p-4'>
    //                 {src && (
    //                     <div className='flex-shrink-0'>
    //                         <img src={src} alt='' className={cn(
    //                             'size-6',
    //                             iconClassName
    //                         )}/>
    //                     </div>
    //                 )}
    //
    //                 {icon && (
    //                     <div className='flex-shrink-0'>
    //                         <Icon icon={icon} className={cn(
    //                             'size-6 text-slate-600',
    //                             iconClassName
    //                         )}/>
    //                     </div>
    //                 )}
    //
    //                 <div className='flex flex-col items-start grow mr-8'>
    //                     {text && <p className='text-sm font-medium text-slate-900'>{text}</p>}
    //                     {subtext && <p className='text-sm text-slate-600'>{subtext}</p>}
    //                 </div>
    //                 <div className='absolute top-0 right-0'>
    //                     <button type='button' onClick={handleClose} className='p-4'>
    //                         <Icon icon={'heroicons:x-mark'} className='size-5 text-slate-600'/>
    //                     </button>
    //                 </div>
    //             </div>
    //         </div>
    //     </Transition>
    // )
}
