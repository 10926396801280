import { Dialog, DialogBackdrop } from '@headlessui/react'
import { AnimatePresence } from 'framer-motion'
import { useAtom } from 'jotai'
import { atom } from 'jotai/index'
import React, { HTMLAttributes, useCallback, useMemo } from 'react'

import { cn } from '../../libs/utils'


export const popupAtom = atom<PopupAtomProps | null>()

export interface PopupAtomProps {
    panel: React.ReactNode | null
    preventClose?: boolean
}

export interface DialogPopupContainerProps extends HTMLAttributes<HTMLDivElement> {
}

export const PopupContainer = ({
    ...props
}: DialogPopupContainerProps) => {
    const [popup, setPopup] = useAtom(popupAtom)
    const open = useMemo(() => !!popup?.panel, [popup])

    const handleClose = useCallback(() => {
        if (popup?.preventClose === true) return

        setPopup(null)
    }, [popup?.preventClose, setPopup])

    return (
        <Dialog
            open={open}
            onClose={handleClose}
            className='relative z-20'
        >
            <DialogBackdrop
                transition
                className={cn(
                    'fixed inset-0',
                    'bg-black/60',
                    'transition-opacity',
                    'data-[closed]:opacity-0',
                    'data-[enter]:duration-300',
                    'data-[enter]:ease-out',
                    'data-[leave]:duration-200',
                    'data-[leave]:ease-in'
                )}
            />

            <div className='fixed inset-0 z-20 w-screen overflow-y-auto'>
                <div className='w-full min-h-full flex flex-col justify-center items-center p-12'>
                    <AnimatePresence>
                        {popup?.panel && (
                            <React.Fragment>
                                {popup?.panel}
                            </React.Fragment>
                        )}
                    </AnimatePresence>
                </div>
            </div>
        </Dialog>
    )
}
