import { Icon } from '@iconify/react'
import { isEmpty } from 'lodash'
import React, { HTMLAttributes, useCallback, useMemo } from 'react'

import { Adapter } from '../../adapter/adapter.types'
import { useAdapter } from '../../hooks/use-adapter'
import { cn } from '../../libs/utils'
import { BadgeIconDataTag } from '../badge/badge-icon'
import { LayoutAdapterIcon } from '../layout/layout-adapter-icon'
import { CardBlurry } from './card-blurry'


export interface CardAdapterProps extends HTMLAttributes<HTMLDivElement> {
    adapter?: Adapter
    action?: React.ReactElement
}

export const CardAdapter = ({
    adapter,
    action,
    ...props
}: CardAdapterProps) => {
    const { profile, display } = useAdapter(adapter?.id)

    const rewards = useMemo(() => {
        const rewards = adapter?.tokenizer.rewards
        if (!rewards) return

        return rewards.filter(each => !isEmpty(each.unit))
    }, [adapter?.tokenizer.rewards])

    const renderBadgeView = useCallback(() => {
        return rewards?.map((each, idx) => {
            return <BadgeIconDataTag key={idx} text={`${each.unit}: ${profile?.[each.key] ?? 0}`}/>
        })
    }, [profile, rewards])

    return (
        <CardBlurry className={cn(
            'text-sm gap-y-4',
            props.className
        )} onClick={props.onClick}>
            <div className={cn(
                'w-full flex items-center',
                display ? 'gap-x-4' : 'gap-x-2'
            )}>
                <LayoutAdapterIcon
                    icon={adapter?.icon}
                    display={display}
                />

                <div className={cn(
                    'grow flex flex-col justify-center items-start'
                )}>
                    <div className={cn(
                        'leading-tight',
                        'text-base font-semibold text-slate-900',
                        'whitespace-pre-wrap'
                    )}>
                        <span>{profile?.username ?? adapter?.cta ?? ' '}</span>
                    </div>
                    <div className='flex items-center h-4'>
                        <span className='text-sm text-slate-900'>{adapter?.name}</span>
                        {adapter?.tokenizer.version === 'alliance' && (
                            <Icon icon='heroicons:check-badge-solid' className='size-4 text-cyan-500 ml-0.5'/>
                        )}
                    </div>
                </div>

                {action}
            </div>

            {!isEmpty(rewards) && (
                <div className={cn(
                    'w-full flex items-center flex-wrap gap-2'
                )}>
                    {renderBadgeView()}
                </div>
            )}
        </CardBlurry>
    )
}
