import BigNumber from 'bignumber.js'
import { AbiCoder, BaseContract, JsonRpcProvider, ZeroAddress } from 'ethers'

import { ERC20Abi } from '../abi/erc20.abi'
import { Multicall3Abi } from '../abi/multicall3.abi'
import Addresses from '../blockchain/addresses'
import { IToken } from '../types/blockchain.types'
import logger from './helper.logger'
import { Zero } from './helper.number'


export const getBalances = async (provider: JsonRpcProvider, tokens: IToken[], chainId: number, account?: string): Promise<IToken[]> => {
    account = account ?? ZeroAddress
    const result: IToken[] = tokens.map(each => {
        return { ...each, balance: Zero }
    })

    const multicall = BaseContract.from(Addresses.multicall3, Multicall3Abi, provider)
    const calls = tokens.map(each => {
        const address = each.address[chainId]
        return address === ZeroAddress ? {
            target: Addresses.multicall3,
            allowFailure: true,
            callData: multicall.interface.encodeFunctionData('getEthBalance', [account])
        } : {
            target: address,
            allowFailure: true,
            callData: BaseContract.from(address, ERC20Abi).interface.encodeFunctionData('balanceOf', [account])
        }
    })

    const response = await multicall.aggregate3.staticCall(calls)
    response.map(([success, data]: [boolean, any], idx: number) => {
        try {
            const value = AbiCoder.defaultAbiCoder().decode(['uint256'], data)[0]
            result[idx].balance = BigNumber(value.toString())
        } catch (e) {
            result[idx].balance = Zero
            logger.error('helper.multicall/getBalances', e)
        }
    })
    return result
}

