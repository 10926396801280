import { Icon } from '@iconify/react'
import React, { ButtonHTMLAttributes, useMemo } from 'react'

import { cn } from '../../libs/utils'


export interface ButtonCoinProps extends ButtonHTMLAttributes<HTMLButtonElement> {
    value?: string
    unit?: string
    size?: 'md' | 'lg'
}

export const ButtonPoint = ({
    value,
    unit,
    size,
    ...props
}: ButtonCoinProps) => {
    const sizes = useMemo(() => {
        switch (size) {
            case 'lg':
                return ['h-10', 'text-2xl', 'size-8', 'text-base']
            default:
                return ['h-9', 'text-base', 'size-6', 'text-sm']
        }
    }, [size])

    return (
        <button onClick={props.onClick} className={cn(
            'flex items-center',
            sizes[0],
            'px-2.5 py-1.5',
            'bg-white',
            'rounded-xl',
            'shadow-md',
            props.className
        )} type={props.type} disabled={props.disabled}>
            <span className={cn(
                sizes[1],
                'text-amber-500',
                'font-extrabold',
                'mx-0.5',
                'select-none'
            )}>{value ?? '0'}</span>
            <Icon icon='fluent-emoji:coin' className={cn(
                sizes[2]
            )}/>

            {unit && (
                <span className={cn(
                    sizes[3],
                    'self-end',
                    'text-amber-500',
                    'font-bold',
                    'ml-0.5',
                    'select-none',
                    'leading-tight'
                )}>
                    {'/ '}{unit}
                </span>
            )}
        </button>
    )
}
