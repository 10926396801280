export enum BridgeEnv {
    iOS = 'ios',
    Android = 'android',
    Web = 'web',
    Undetermined = 'undetermined'
}

export const currentEnv = (): BridgeEnv => {
    let env = window.location.host.split('.')[0].toLowerCase()
    switch (env) {
        case BridgeEnv.iOS:
        case BridgeEnv.Android:
            break
        default:
            env = BridgeEnv.Web
            break
    }
    return env as BridgeEnv
}

export const _bridgeEnv = currentEnv()
export const isWebEnv = _bridgeEnv === BridgeEnv.Web
export const isNativeEnv = _bridgeEnv === BridgeEnv.iOS || _bridgeEnv === BridgeEnv.Android
