import { ButtonHTMLAttributes } from 'react'

import { cn } from '../../libs/utils'


export interface ButtonTextProps extends ButtonHTMLAttributes<HTMLButtonElement> {
}

export const ButtonText = ({
    ...props
}: ButtonTextProps) => {

    return (
        <button onClick={props.onClick} className={cn(
            'flex items-center',
            'underline underline-offset-2 decoration-0',
            props.className
        )} type={props.type} disabled={props.disabled}>
            {props.children}
        </button>
    )
}
