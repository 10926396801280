import { AnimatePresence } from 'framer-motion'
import React, { useCallback, useEffect } from 'react'
import { Outlet, useLocation } from 'react-router-dom'

import Background from '../../components/background'
import Container from '../../components/container'
import Header from '../../components/header'
import Tab from '../../components/tab'
import { KeyringState } from '../../contexts/keyring/keyring-provider'
import { useKeyring } from '../../hooks/use-keyring'
import { useToasts } from '../../hooks/use-toasts'
import { PageRecover } from '../keyring/page-recover'
import ViewPending from '../view-pending'


export const LayoutMain: React.FC = () => {
    const { state, initialize } = useKeyring()
    
    const { pathname } = useLocation()
    const { showCreateWalletAlarm, hideCreateWalletAlarm } = useToasts()
    
    useEffect(() => {
        if (state === KeyringState.KeyringRequired && pathname === '/') {
            showCreateWalletAlarm()
        } else if (state === KeyringState.KeyringLoaded || pathname.startsWith('/keyring')) {
            hideCreateWalletAlarm()
        }
    }, [hideCreateWalletAlarm, pathname, showCreateWalletAlarm, state])
    
    const renderContents = useCallback(() => {
        switch (state) {
            case KeyringState.Failed:
                return <PageRecover onRecover={initialize}/>
            case KeyringState.Undetermined:
                return <ViewPending/>
            default:
                return (
                    <React.Fragment>
                        <Header.Main logo={true}/>
                        <AnimatePresence mode='wait'>
                            <Outlet/>
                        </AnimatePresence>
                        <Tab.Container/>
                    </React.Fragment>
                )
        }
    }, [initialize, state])
    
    return (
        <Background.Blob>
            <Container.Screen>
                {renderContents()}
            </Container.Screen>
        </Background.Blob>
    )
}
