import { Icon } from '@iconify/react'
import { atom, useAtom } from 'jotai'
import { isEmpty } from 'lodash'
import React, { useCallback, useEffect, useState } from 'react'
import { BottomSheet } from 'react-spring-bottom-sheet'

import { BridgeSig, useBridgeMedia } from '../../hooks/use-bridge'
import { cn, snooze } from '../../libs/utils'
import { ButtonSolid } from '../button/button-solid'
import { ButtonText } from '../button/button-text'
import { SpinnerDot3 } from '../spinner/spinner-dot3'


export interface ImageUploaderPayload {
    imageData: string
}

export interface SheetImageUploaderProps {
    open: boolean
    setOpen: (value: boolean) => void
    placeholder?: string
    title?: string
    message?: string
    onUpload?: (payload: ImageUploaderPayload) => void
    tag?: string
}

export const mediaPickRestoreAtom = atom<string>('')

export const SheetImageUploader = ({
    open,
    setOpen,
    placeholder,
    title,
    message,
    onUpload,
    tag
}: SheetImageUploaderProps) => {
    const { mediaPick } = useBridgeMedia()
    const [imageData, setImageData] = useState<string | null>(null)
    const [submitting, setSubmitting] = useState(false)

    const [mediaPickRestore, setMediaPickRestore] = useAtom(mediaPickRestoreAtom)

    const handleClose = useCallback(() => {
        setImageData(null)
        setOpen(false)
    }, [setOpen])

    const handleSelect = useCallback(async () => {
        if (tag) {
            localStorage.setItem(BridgeSig.mediaPick, tag)
        }

        const picked = await mediaPick()
        setImageData(picked)
        localStorage.removeItem(BridgeSig.mediaPick)
    }, [mediaPick, tag])

    const handleUpload = useCallback(async () => {
        if (submitting || !imageData) return
        setSubmitting(true)

        await Promise.allSettled([
            onUpload?.call(this, { imageData }),
            snooze(1000)
        ])

        setSubmitting(false)
        setTimeout(() => setOpen(false), 100)
    }, [imageData, onUpload, setOpen, submitting])

    useEffect(() => {
        if (!isEmpty(mediaPickRestore)) {
            setImageData(mediaPickRestore)
            setMediaPickRestore('')
            localStorage.removeItem(BridgeSig.mediaPick)
        }
    }, [mediaPickRestore, setMediaPickRestore])

    return (
        <BottomSheet
            open={open}
            snapPoints={({ minHeight }) => minHeight}
        >
            <div className={cn(
                'w-full',
                'flex flex-col gap-y-4',
                'p-4'
            )}>
                <div className='w-full flex items-center gap-x-2'>
                    <ButtonText className={cn(
                        'flex items-center gap-x-0.5',
                        'text-cyan-600 font-semibold no-underline'
                    )} onClick={handleClose}>
                        <Icon icon={'mingcute:close-line'} className='size-5'/>
                        <span>Close</span>
                    </ButtonText>
                </div>

                <div className={cn(
                    'relative w-full aspect-[4/4]',
                    'rounded-xl overflow-hidden',
                    'ring-1 ring-black/15'
                )} onClick={handleSelect}>

                    {(imageData ?? placeholder) && (
                        <img className={cn(
                            'w-full h-full object-cover object-top'
                        )} src={imageData ?? placeholder} alt=''/>
                    )}

                    {!imageData && (
                        <React.Fragment>
                            <div className='absolute inset-0 bg-black/60'/>
                            <p className='absolute left-4 right-4 bottom-4 text-white'>
                                {title && <h3 className='text-2xl'>{title}</h3>}
                                {message && <span className='whitespace-pre-wrap'>{message}</span>}
                            </p>
                        </React.Fragment>
                    )}
                </div>

                <div className='w-full h-12 flex items-center gap-x-2'>
                    <ButtonSolid className={cn(
                        'h-full aspect-square',
                        'flex justify-center items-center gap-x-1',
                        'text-cyan-600'
                    )} onClick={handleSelect}>
                        <Icon icon='mingcute:photo-album-line' className='size-6'/>
                    </ButtonSolid>

                    <ButtonSolid className={cn(
                        'container h-full',
                        'flex justify-center items-center gap-x-1',
                        'bg-cyan-500 text-white font-semibold'
                    )} onClick={handleUpload} disabled={!imageData}>
                        {
                            submitting
                            ? <SpinnerDot3 className='size-6 text-white'/>
                            : <span className='text-lg'>Continue</span>
                        }
                    </ButtonSolid>
                </div>
            </div>
        </BottomSheet>
    )
}
