import { AuthError, AuthErrorCodes } from '@firebase/auth'
import { Icon } from '@iconify/react'
import { useAtom } from 'jotai'
import { head } from 'lodash'
import React, { useCallback, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { BottomSheet } from 'react-spring-bottom-sheet'
import { object, string, ValidationError } from 'yup'

import { authSheetPropsAtom } from '../../hooks/use-sheet-auth'
import { useToasts } from '../../hooks/use-toasts'
import { AuthProviderType } from '../../libs/types/auth.types'
import { cn } from '../../libs/utils'
import Zing from '../../libs/zing'
import Button from '../button'
import Label from '../label'
import { SpinnerDot3 } from '../spinner/spinner-dot3'


const SchemaEmail = object().shape({
    email: string().email().required()
})

const SchemaSignIn = object().shape({
    email: string().email().required(),
    password: string().min(6, 'Too short!').required()
})

export const SheetAuth = () => {
    const navigate = useNavigate()
    const [authSheetProps, setPasscodeProps] = useAtom(authSheetPropsAtom)
    const { showOopsAlarm, showWrongPasswordAlarm } = useToasts()

    const [inputs, setInputs] = useState({ email: '', password: '' })
    const [errors, setErrors] = useState({ email: undefined, password: undefined })

    const [submitting, setSubmitting] = useState(false)
    const [useEmailProvider, setUseEmailProvider] = useState(false)
    const [passwordVisibility, setPasswordVisibility] = useState(false)

    const handleDismiss = useCallback(() => {
        setPasscodeProps(false)
    }, [setPasscodeProps])

    const handleInputChange = useCallback((e: React.FormEvent<HTMLInputElement>) => {
        const key = e.currentTarget.id
        const value = e.currentTarget.value

        setInputs((prev) => {
            return { ...prev, [key]: value }
        })
    }, [])

    const handleInputButton = useCallback((e: React.MouseEvent<any>) => {
        e.stopPropagation()

        useEmailProvider
        ? setPasswordVisibility(prev => !prev)
        : setInputs(prev => {
            return { ...prev, email: '' }
        })
    }, [useEmailProvider])

    const validateInputs = useCallback(async () => {
        const schema = useEmailProvider ? SchemaSignIn : SchemaEmail
        const values = await schema.validate(inputs).catch((e: ValidationError) => {
            setErrors((prev) => {
                return { ...prev, [e.path?.toString() ?? '']: head(e.errors) }
            })
        })

        if (!values) return
        setErrors({ email: undefined, password: undefined })

        return values
    }, [inputs, useEmailProvider])

    const handleAuthProvider = useCallback(async (provider: AuthProviderType) => {
        switch (provider) {
            case 'email': {
                if (useEmailProvider) {
                    const values = await validateInputs()
                    if (!values || submitting) return
                    setSubmitting(true)

                    try {
                        const uid = await Zing.auth.signUpWithEmail(inputs.email, inputs.password)
                        if (!uid) showOopsAlarm()

                        handleDismiss()
                        navigate('/keyring', { replace: true })
                    } catch (e: any) {
                        if (e satisfies AuthError) {
                            if (e.code === AuthErrorCodes.INVALID_PASSWORD ||
                                e.code === AuthErrorCodes.INVALID_LOGIN_CREDENTIALS) {
                                showWrongPasswordAlarm()
                            } else {
                                console.error('SheetAuth/handleAuthProvider', e)
                            }
                        }
                    } finally {
                        setSubmitting(false)
                    }
                } else {
                    const values = await validateInputs()
                    if (!values) return
                    setUseEmailProvider(true)
                }
                break
            }
            case 'google':
            case 'discord':
            case 'reddit':
            case 'x':
                window.open(Zing.auth.getOAuthUrlOf(provider), '_self')
                break
            default:
                break
        }
    }, [handleDismiss, inputs.email, inputs.password, navigate, showOopsAlarm, showWrongPasswordAlarm, submitting, useEmailProvider, validateInputs])

    const clearAuthProvider = useCallback(() => {
        setInputs({ email: '', password: '' })
        setUseEmailProvider(false)
        setPasswordVisibility(false)
    }, [])

    return (
        <BottomSheet
            open={authSheetProps}
            onDismiss={handleDismiss}
            snapPoints={({ minHeight }) => minHeight}
            className={'sheet-auth'}
        >
            <div className={cn(
                'w-full max-w-screen-sm h-full',
                'flex flex-col justify-start items-start gap-y-6',
                'mx-auto p-4'
            )}>
                <div className='w-full flex flex-col gap-y-2'>
                    <Label.Section text={'Create Your Wallet'} subtext={'Your data wallet with one click'}/>
                    {
                        <div className={cn(
                            'relative',
                            'rounded-2xl',
                            'px-3 pb-1.5 pt-2.5',
                            'bg-white',
                            'ring-1 ring-inset ring-cyan-500',
                            'focus-within:ring-2 focus-within:ring-cyan-500'
                        )}>
                            <label htmlFor='password' className={cn(
                                'flex justify-start items-center gap-x-1',
                                'text-sm text-slate-600 font-medium'
                            )}>
                                <span>{useEmailProvider ? `${inputs.email}` : 'Email'}</span>
                                <span className='text-rose-600 truncate'>
                                    {useEmailProvider && errors.password && <React.Fragment>{errors.password}</React.Fragment>}
                                    {!useEmailProvider && errors.email && <React.Fragment>{errors.email}</React.Fragment>}
                                </span>
                            </label>

                            <input
                                id={useEmailProvider ? 'password' : 'email'}
                                type={useEmailProvider ? (passwordVisibility ? 'text' : 'password') : 'email'}
                                autoComplete={useEmailProvider ? 'current-password webauthn' : 'email webauthn'}
                                placeholder={useEmailProvider ? 'password' : 'zing@example.com'}
                                className={cn(
                                    'block',
                                    'w-full',
                                    'border-0 p-0',
                                    'text-slate-900',
                                    'placeholder:text-slate-400',
                                    'focus:ring-0 focus:outline-0'
                                )}
                                style={{ outline: 'none' }}
                                value={useEmailProvider ? inputs.password : inputs.email}
                                onChange={handleInputChange}
                            />

                            <Icon icon={
                                useEmailProvider
                                ? (passwordVisibility ? 'mingcute:eye-close-line' : 'mingcute:eye-2-line')
                                : 'mingcute:close-line'
                            } className={cn(
                                'absolute right-2 bottom-0',
                                'size-9 p-2',
                                'text-slate-400'
                            )} onClick={handleInputButton}/>
                        </div>
                    }

                    <div className='w-full flex items-center gap-x-2'>
                        {useEmailProvider && (
                            <Button.Solid className={cn(
                                'w-16 h-11 flex justify-center items-center',
                                'shadow-md'
                            )} onClick={clearAuthProvider}>
                                <Icon icon={'mingcute:arrow-left-line'}/>
                            </Button.Solid>
                        )}

                        <Button.Solid className={cn(
                            'w-full h-11 flex justify-center items-center',
                            'bg-cyan-500 text-white font-semibold'
                        )} onClick={() => handleAuthProvider('email')}>
                            {
                                submitting
                                ? <SpinnerDot3 className='size-6 text-white'/>
                                : <span>{useEmailProvider ? 'Sign In' : 'Continue with Email'}</span>
                            }
                        </Button.Solid>
                    </div>
                </div>

                <div className='w-full relative'>
                    <div aria-hidden='true' className='absolute inset-0 flex items-center'>
                        <div className='w-full border-t border-slate-200'/>
                    </div>
                    <div className='relative flex justify-center text-sm/6 font-medium'>
                        <span className='bg-white px-6 text-slate-900/60'>Or continue with</span>
                    </div>
                </div>

                <div className={cn(
                    'w-full',
                    'grid grid-cols-3 gap-4 items-center',
                    'mb-6'
                )}>
                    <Button.Solid className={cn(
                        'w-full flex justify-center items-center gap-x-1',
                        'ring-1 ring-black',
                        'shadow-none',
                        'col-span-3'
                    )} onClick={() => handleAuthProvider('google')}>
                        <Icon icon={'logos:google-icon'} className='size-5'/>
                        <span>Google</span>
                    </Button.Solid>

                    <Button.Solid className={cn(
                        'flex justify-center items-center gap-x-1',
                        'ring-1 ring-black',
                        'shadow-none'
                    )} onClick={() => handleAuthProvider('discord')}>
                        <Icon icon={'logos:discord-icon'} className='size-5 stroke-black'/>
                    </Button.Solid>

                    <Button.Solid className={cn(
                        'flex justify-center items-center gap-x-1',
                        'ring-1 ring-black',
                        'shadow-none'
                    )} onClick={() => handleAuthProvider('reddit')}>
                        <Icon icon={'logos:reddit-icon'} className='size-5'/>
                    </Button.Solid>

                    <Button.Solid className={cn(
                        'flex justify-center items-center gap-x-1',
                        'ring-1 ring-black',
                        'shadow-none',
                        'hidden'
                    )} onClick={() => handleAuthProvider('apple')}>
                        <Icon icon={'logos:apple'} className='size-5'/>
                    </Button.Solid>

                    <Button.Solid className={cn(
                        'flex justify-center items-center gap-x-1',
                        'ring-1 ring-black',
                        'shadow-none'
                    )} onClick={() => handleAuthProvider('x')}>
                        <Icon icon={'logos:x'} className='size-5'/>
                    </Button.Solid>
                </div>
            </div>
        </BottomSheet>
    )
}
