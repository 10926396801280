import { ButtonFloating } from './button-floating'
import { ButtonIcon } from './button-icon'
import { ButtonPoint } from './button-point'
import { ButtonSolid } from './button-solid'
import { ButtonSwitch } from './button-switch'
import { ButtonText } from './button-text'
import { ButtonZing } from './button-zing'


const Button = {
    Solid: ButtonSolid,
    Icon: ButtonIcon,
    Text: ButtonText,
    Switch: ButtonSwitch,
    Zing: ButtonZing,
    Point: ButtonPoint,
    Floating: ButtonFloating
}

export default Button
