import { HeaderMain } from './header-main'
import { HeaderPage } from './header-page'


const Header = {
    Main: HeaderMain,
    Page: HeaderPage
}

export default Header
