import React, { HTMLAttributes } from 'react'

import Logo from '../components/logo'
import { cn } from '../libs/utils'


export interface ViewPendingProps extends HTMLAttributes<HTMLDivElement> {

}

const ViewPending = ({
    ...props
}: ViewPendingProps) => {
    return (
        <div className={cn(
            'w-full h-full min-h-screen',
            'flex flex-col justify-center items-center',
            props.className
        )}>
            <Logo.Text className='text-white animate-tada mb-2'/>
        </div>
    )
}

export default ViewPending
