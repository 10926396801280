import React, { HTMLAttributes } from 'react'

import AssetBackground from '../../assets/background'
import { cn } from '../../libs/utils'


export interface BackgroundBlobProps extends HTMLAttributes<HTMLDivElement> {

    backgroundClassName?: string
}

export const BackgroundBlob = ({
    backgroundClassName,
    ...props
}: BackgroundBlobProps) => {

    return (
        <div className={cn(
            'relative',
            'w-full h-full min-h-svh',
            'flex flex-col justify-start items-center',
            backgroundClassName
        )}>
            <AssetBackground.Gradients className='fixed top-1/2 left-1/2 -translate-y-1/2 -translate-x-1/2'/>
            {props.children}
        </div>
    )
}
