import { Icon } from '@iconify/react'
import React, { useCallback, useEffect } from 'react'
import { useNavigate } from 'react-router-dom'

import Button from '../../components/button'
import { PageAnimatedOnboarding } from '../../components/page/page-animated-onboarding'
import { KeyringState } from '../../contexts/keyring/keyring-provider'
import { useKeyring } from '../../hooks/use-keyring'
import { useSheetAgreement } from '../../hooks/use-sheet-agreement'
import { cn, snooze } from '../../libs/utils'


interface PageRecoverProps {
    onRecover: () => void
}

export const PageRecover = ({
    onRecover
}: PageRecoverProps) => {
    const navigate = useNavigate()
    const { state, signOut } = useKeyring()

    const { showPasscodeForgotAgreementAsync } = useSheetAgreement()

    const handleForgotPasscode = useCallback(async () => {
        const agreed = await showPasscodeForgotAgreementAsync()
        if (!agreed) return

        await signOut()
        await snooze(60)
        navigate('/', { replace: true })
    }, [navigate, showPasscodeForgotAgreementAsync, signOut])

    useEffect(() => {
        if (state === KeyringState.KeyringLoaded) {
            navigate('/', { replace: true })
        }
    }, [navigate, state])

    return (
        <PageAnimatedOnboarding key={'PageProfile'}>
            <div className={cn(
                'absolute top-[15%]',
                'w-full',
                'flex flex-col justify-center items-start',
                'px-4'
            )}>
                <div className='text-4xl font-semibold text-slate-900/15'>
                    Wallet Recovery
                </div>
                <div className='text-xl font-normal text-slate-900/60'>
                    Enter your wallet password
                </div>
            </div>

            <div className={cn(
                'flex flex-col justify-center items-center gap-y-8'
            )}>
                <Button.Solid className={cn(
                    'flex justify-center items-center gap-x-1'
                )} onClick={onRecover}>
                    <Icon icon='mingcute:refresh-3-line' className='size-4'/>
                    <span>Recover</span>
                </Button.Solid>

                <Button.Text className='text-slate-600' onClick={handleForgotPasscode}>
                    Forgot passcode?
                </Button.Text>
            </div>
        </PageAnimatedOnboarding>
    )
}
