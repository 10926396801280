import { ButtonHTMLAttributes } from 'react'

import { cn } from '../../libs/utils'


export interface ButtonSolidProps extends ButtonHTMLAttributes<HTMLButtonElement> {
}

export const ButtonSolid = ({
    ...props
}: ButtonSolidProps) => {

    return (
        <button onClick={props.onClick} className={cn(
            'flex items-center',
            'px-2.5 py-2.5',
            'bg-white',
            'rounded-xl',
            'shadow-md',
            'hover:brightness-105',
            props.disabled ? 'grayscale' : '',
            props.className
        )} type={props.type} disabled={props.disabled}>
            {props.children}
        </button>
    )
}
