import { useCallback, useContext, useEffect, useState } from 'react'

import { Context } from '../contexts/chain'
import logger from '../libs/helper/helper.logger'


export const useBlock = () => {
    const { provider } = useContext(Context)
    const [block, setBlock] = useState(0)

    const handleBlockEvent = useCallback((latest: number) => {
        console.log('handleBlockEvent', latest)
        setBlock(block => Math.max(latest, block))
    }, [setBlock])

    useEffect(() => {
        if (!provider) return

        provider.getBlockNumber()
                .then(handleBlockEvent)
                .catch((e: any) => logger.error('useBlock', e))

        provider.on('block', handleBlockEvent)
        return () => {
            provider.removeListener('block', handleBlockEvent)
        }
    }, [provider, handleBlockEvent])

    return block
}
