import { useSetAtom } from 'jotai'
import React, { useCallback } from 'react'

import { AdapterTask } from '../adapter/adapter.types'
import { DialogPanelLoader } from '../components/popup/panel/dialog-panel-loader'
import { DialogPanelPoints } from '../components/popup/panel/dialog-panel-points'
import { popupAtom } from '../components/popup/popup-container'


export const usePopupLoader = () => {
    const setPopup = useSetAtom(popupAtom)

    const show = useCallback(() => {
        setPopup({
            panel: <DialogPanelLoader/>,
            preventClose: true
        })
    }, [setPopup])

    const close = useCallback(() => {
        setPopup(null)
    }, [setPopup])

    return [show, close]
}

export const usePopupPoints = () => {
    const setPopup = useSetAtom(popupAtom)

    const show = useCallback((data: Partial<AdapterTask>) => {
        setPopup({
            panel: <DialogPanelPoints data={data}/>,
            preventClose: false
        })
    }, [setPopup])

    const close = useCallback(() => {
        setPopup(null)
    }, [setPopup])

    return [show, close]
}
