import React, { HTMLAttributes } from 'react'

import { cn } from '../../libs/utils'
import Header from '../header'
import { HeaderPageProps } from '../header/header-page'


export interface PageNestedProps extends HTMLAttributes<HTMLDivElement>, HeaderPageProps {

}

export const PageNested = ({
    ...props
}: PageNestedProps) => {

    return (
        <div className={cn(
            'relative',
            'w-full h-full',
            'scrollbar-hide',
            'pt-14',
            props.className
        )}>
            <Header.Page
                back={props.back}
                title={props.title}
                icon={props.icon}
                logo={props.logo}
                to={props.to}
            />
            {props.children}
        </div>
    )
}
