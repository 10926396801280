import PartyPopper from './party_popper.png'
import Robot from './robot.png'


const AssetAnimation: Record<string, string> = {
    Robot: Robot as string,
    PartyPopper: PartyPopper as string
}

export default AssetAnimation
