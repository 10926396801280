import { ProfileAdapter } from './profile-adapter'
import { ProfileAddress } from './profile-address'
import { ProfileAvatar } from './profile-avatar'
import { ProfileName } from './profile-name'


const Profile = {
    Name: ProfileName,
    Avatar: ProfileAvatar,
    Address: ProfileAddress,
    Adapter: ProfileAdapter
}

export default Profile
