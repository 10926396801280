import React, { forwardRef } from 'react'

import { cn } from '../../libs/utils'
import { PageAnimated, PageAnimatedProps } from './page-animated'


const MotionVariants = {
    initial: (forward: boolean) => ({ opacity: 0, y: forward ? '10%' : '-10%' }),
    animate: { opacity: 1, y: '0%' },
    exit: (forward: boolean) => ({ opacity: 0, y: forward ? '-10%' : '10%' })
}

export interface PageAnimatedTabProps extends PageAnimatedProps {
    forward?: any
}

export const PageAnimatedTab = forwardRef<HTMLDivElement, PageAnimatedTabProps>(({
    forward = true,
    children,
    ...props
}, ref) => {
    return (
        <PageAnimated
            className={cn(
                'relative',
                'flex flex-col items-center',
                'pb-16',
                props.className
            )}
            key={props.key}
            variants={MotionVariants}
            custom={forward}
            initial='initial'
            animate='animate'
            exit='exit'
            ref={ref}
        >
            {children}
        </PageAnimated>
    )
})

PageAnimatedTab.displayName = 'PageAnimatedTab'
