import { QueryFunctionContext, QueryKey, useInfiniteQuery, useQueryClient } from '@tanstack/react-query'
import firebase from 'firebase/compat'
import { head, isEmpty } from 'lodash'
import moment from 'moment/moment'
import { nanoid } from 'nanoid'
import { useCallback, useEffect, useMemo, useRef, useState } from 'react'
import { useParams } from 'react-router-dom'

import { Adapters } from '../adapter/adapter.list'
import { Adapter, AdapterConsent, AdapterProfile } from '../adapter/adapter.types'
import { DataConnection, NextToken } from '../libs/types/api.types'
import Zing from '../libs/zing'
import { useAccount } from './use-account'


export const useAdapter = (adapterId?: string) => {
    const { uid, account } = useAccount()

    const adapter: Adapter | undefined = useMemo(() => {
        return Adapters.find(adapter => adapter.id === adapterId)
    }, [adapterId])

    const profile: AdapterProfile | undefined = useMemo(() => {
        if (!account || !adapter) return
        return account[adapter.tokenizer.path.profile]
    }, [account, adapter])

    const display = useMemo(() => {
        return profile?.displayUrl ?? adapter?.tokenizer.fallback?.display
    }, [adapter?.tokenizer.fallback?.display, profile?.displayUrl])

    const nonce: string | undefined = useMemo(() => {
        if (!account || !adapter) return

        const pathNonce = adapter.tokenizer.path.nonce
        if (!pathNonce) return

        return account[pathNonce]
    }, [account, adapter])

    const updateNonce = useCallback(async () => {
        if (!account || !adapter || !uid) return
        if (!isEmpty(nonce)) return

        const pathNonce = adapter.tokenizer.path.nonce
        if (!pathNonce) return

        const updated = `${nanoid(21)}==`
        await Zing.database.updateAdapterNonce(uid, pathNonce, updated)
    }, [account, adapter, nonce, uid])

    const consent: AdapterConsent | undefined = useMemo(() => {
        if (!account || !adapter) return

        const pathConsent = adapter.tokenizer.path.consent
        if (!pathConsent) return { status: true, timestamp: 0 }

        return account[pathConsent]
    }, [account, adapter])

    const updateConsent = useCallback(async (status: boolean) => {
        if (!uid || !adapter) return

        const pathConsent = adapter.tokenizer.path.consent
        if (!pathConsent) return

        const updated: AdapterConsent = { status, timestamp: moment().unix() }
        await Zing.database.updateAdapterConsent(uid, pathConsent, updated)
    }, [adapter, uid])

    return {
        adapter,
        profile,
        display,
        consent,
        updateConsent,
        nonce,
        updateNonce
    }
}

export const useActiveAdapter = () => {
    const { adapterId } = useParams()
    const { adapter, ...others } = useAdapter(adapterId)

    return {
        adapterId,
        adapter,
        ...others
    }
}

export const useAdapterDataQuery = <DataModel = {}>(adapterId?: string, chunk: number = 30) => {
    const queryClient = useQueryClient()
    const { uid } = useAccount()

    const adapter: Adapter | undefined = useMemo(() => {
        return Adapters.find(adapter => adapter.id === adapterId)
    }, [adapterId])

    const path = useMemo(() => {
        return adapter?.tokenizer.path.data
    }, [adapter?.tokenizer.path.data])

    const queryKey = useMemo(() => {
        return [uid, path]
    }, [path, uid])

    const getDataItems = useCallback(async (nextToken?: NextToken): Promise<DataConnection<DataModel>> => {
        if (!uid || !path) return { items: [] }

        return await Zing.database.queryAdapterData(uid, path, nextToken, chunk)
    }, [chunk, path, uid])

    const invalidate = useCallback(async () => {
        await queryClient.invalidateQueries({ queryKey })
    }, [queryClient, queryKey])

    const result = useInfiniteQuery<DataConnection<DataModel>, Error>({
        queryKey,
        queryFn: ({ pageParam: nextToken }: QueryFunctionContext<QueryKey, NextToken>) => {
            return getDataItems(nextToken)
        },
        initialPageParam: undefined,
        getNextPageParam: (lastPage, allPages) => {
            return lastPage.nextToken
        },
        enabled: !isEmpty(uid) && !isEmpty(path)
    })

    const hasData = useMemo(() => {
        const first = head(result.data?.pages) as DataConnection<DataModel>
        return !isEmpty(first?.items)
    }, [result.data?.pages])

    const next = useCallback(async () => {
        if (!result.hasNextPage || result.isFetchingNextPage) return

        await result.fetchNextPage()
    }, [result])

    return {
        ...result,
        invalidate,
        hasData,
        next
    }
}

export const useAdapterTask = (adapterId?: string, autoInvalidate: boolean = true) => {
    const queryClient = useQueryClient()
    const { uid } = useAccount()

    const [pending, setPending] = useState(false)
    const unsubRef = useRef<firebase.Unsubscribe>()

    const adapter: Adapter | undefined = useMemo(() => {
        return Adapters.find(adapter => adapter.id === adapterId)
    }, [adapterId])

    const path = useMemo(() => {
        return adapter?.tokenizer.path.task
    }, [adapter?.tokenizer.path.task])

    const queryKey = useMemo(() => {
        return [uid, path]
    }, [path, uid])

    const invalidate = useCallback(async () => {
        await queryClient.invalidateQueries({ queryKey })
    }, [queryClient, queryKey])

    const createTask = useCallback(async (params: Record<string, any> = {}) => {
        if (!uid || !path) return
        await Zing.database.createAdapterTask(uid, path, params)
    }, [path, uid])

    const cancelTasks = useCallback(async () => {
        if (!uid || !path) return
        await Zing.database.cancelAdapterTasks(uid, path)
    }, [path, uid])

    const detachTaskListener = useCallback(() => {
        unsubRef.current?.call(this)
    }, [])

    const attachTaskListener = useCallback(() => {
        if (!uid || !path) return
        detachTaskListener()

        unsubRef.current = Zing.database.onAdapterCollectionChanged(uid, path, (snapshot, status) => {
            setPending(status.pending)

            if (status.completed && autoInvalidate) {
                invalidate()
            }
        })
    }, [autoInvalidate, detachTaskListener, invalidate, path, uid])

    useEffect(() => {
        if (isEmpty(uid)) return

        attachTaskListener()
        return () => detachTaskListener()
    }, [attachTaskListener, detachTaskListener, uid])

    return {
        adapter,
        pending,
        createTask,
        cancelTasks,
        attachTaskListener,
        detachTaskListener
    }
}

export const useAdapterConsent = () => {
    const { uid, account } = useAccount()

    const consentOf = useCallback((adapterId: string): AdapterConsent | undefined => {
        const adapter = Adapters.find(adapter => adapter.id === adapterId)
        if (!account || !adapter) return

        const pathConsent = adapter.tokenizer.path.consent
        if (!pathConsent) return { status: true, timestamp: 0 }

        return account[pathConsent]
    }, [account])

    const updateConsentOf = useCallback(async (adapterId: string, status: boolean) => {
        const adapter = Adapters.find(adapter => adapter.id === adapterId)
        if (!account || !adapter || !uid) return

        const pathConsent = adapter.tokenizer.path.consent
        if (!pathConsent) return

        const updated: AdapterConsent = { status, timestamp: moment().unix() }
        await Zing.database.updateAdapterConsent(uid, pathConsent, updated)
    }, [account, uid])

    return {
        consentOf,
        updateConsentOf
    }
}
