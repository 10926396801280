import { ToastAlarm } from './toast-alarm'
import { ToastContainer } from './toast-container'


const Toast = {
    Container: ToastContainer,
    Alarm: ToastAlarm
}

export default Toast
