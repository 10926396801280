import { Icon } from '@iconify/react'
import { motion } from 'framer-motion'
import React, { useCallback, useEffect, useMemo, useState } from 'react'
import { useLocation } from 'react-router-dom'

import { useSafeNavigate } from '../../hooks/use-safe-navigate'
import { cn } from '../../libs/utils'


export interface TabProps {
    id: string
    icon: string
    label: string
}

export const TabContainer = () => {
    const { pathname } = useLocation()
    const { safeNavigate } = useSafeNavigate()

    const [tabs, tabIds] = useMemo(() => {
        const items = [
            { id: '/', icon: 'mingcute:wallet-4-fill', label: 'Wallet' },
            { id: '/playing', icon: 'mingcute:play-circle-fill', label: 'Playing' },
            { id: '/settings', icon: 'mingcute:settings-3-fill', label: 'Settings' }
        ]
        const ids = items.map(each => each.id)
        return [items, ids]
    }, [])

    const [activeTabId, setActiveTabId] = useState<string>(tabs[0].id)

    const handleChangeTab = useCallback((tabId: string) => {
        if (activeTabId === tabId) return

        const accepted = safeNavigate(tabId, { replace: true })
        if (!accepted) return

        setActiveTabId(tabId)
    }, [activeTabId, safeNavigate])

    const syncActiveTab = useCallback((tabId: string) => {
        setActiveTabId(tabId)
    }, [])

    useEffect(() => {
        if (tabIds.includes(pathname) && activeTabId !== pathname) {
            syncActiveTab(pathname)
        }
    }, [activeTabId, pathname, syncActiveTab, tabIds])

    return (
        <div className={cn(
            'fixed inset-x-0 bottom-0',
            'max-w-screen-sm w-full h-16',
            'flex justify-evenly items-center gap-x-1',
            'bg-white/60 backdrop-blur-md',
            'shadow-[rgba(0,0,0,0.1)_0px_2px_6px_4px]',
            'rounded-t-3xl',
            'mx-auto',
            'z-20'
        )}>
            {tabs.map((tab) => (
                <motion.button
                    className={cn(
                        'relative',
                        'flex flex-col justify-center items-center',
                        'bg-transparent text-slate-900 text-xs font-normal',
                        'px-4 py-2.5',
                        'rounded-full shadow-none'
                    )}
                    key={tab.id}
                    whileHover={{ scale: 1.1 }}
                    whileTap={{ scale: 0.9 }}
                    transition={{ type: 'spring', stiffness: 400, damping: 10 }}
                    onClick={() => handleChangeTab(tab.id)}
                >
                    <Icon icon={tab.icon} className={cn(
                        'size-8',
                        'select-none',
                        activeTabId === tab.id ? 'opacity-100' : 'opacity-40'
                    )}/>
                    <span className={cn(
                        'leading-3',
                        'select-none',
                        activeTabId === tab.id ? 'opacity-100' : 'opacity-60'
                    )}>{tab.label}</span>
                </motion.button>
            ))}
        </div>
    )
}
