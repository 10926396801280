import { isEmpty, last } from 'lodash'
import { HTMLAttributes, useMemo } from 'react'

import { cn } from '../../libs/utils'
import { ellipsisAddress, splitAddress } from '../../libs/utils'


export type ProfileAddressType = 'raw' | 'chunk4' | 'partial4' | 'ellipsis4' | 'ellipsis6'

export interface ProfileAddressProps extends HTMLAttributes<HTMLSpanElement> {
    address?: string
    format?: ProfileAddressType
}

export const ProfileAddress = ({
    address = '',
    format = 'raw',
    ...props
}: ProfileAddressProps) => {
    const parsed = useMemo(() => {
        switch (format) {
            case 'chunk4': {
                const chunk4 = splitAddress(address, 4)
                return `0x${chunk4.slice(0, 5).join(' ')}\n  ${chunk4.slice(5, 10).join(' ')}`
            }
            case 'partial4': {
                const chunk4 = splitAddress(address, 4)
                return `0x${chunk4[0]} ${chunk4[1]} .... .... ${last(chunk4)}`
            }
            case 'ellipsis4':
                return ellipsisAddress(address, 4)
            case 'ellipsis6':
                return ellipsisAddress(address, 6)
            default:
                return address
        }
    }, [address, format])

    if (isEmpty(parsed)) {
        return (
            <div className={cn(
                'w-full min-w-24 h-2',
                'mt-1',
                'rounded-md',
                'text-transparent',
                'whitespace-pre-wrap',
                'animate-pulse bg-slate-200',
                props.className
            )}>
                {' '}
            </div>
        )
    }

    return (
        <span className={cn(
            'font-mono',
            'text-sm',
            'text-slate-900',
            'font-medium',
            'tracking-tight',
            'whitespace-break-spaces',
            'text-start',
            props.className
        )}>{parsed}</span>
    )
}
