import { Icon } from '@iconify/react'
import moment from 'moment'
import React, { HTMLAttributes } from 'react'

import Card from '../../components/card'
import { cn } from '../../libs/utils'
import { NrcData } from './nrc.types'


export interface NrcDataCellProps extends HTMLAttributes<HTMLDivElement> {
    data: NrcData
}

export const NrcDataCell = ({
    data,
    ...props
}: NrcDataCellProps) => {

    return (
        <Card.Blurry className={cn(
            'p-2',
            'text-sm',
            props.className
        )}>
            <div className={cn(
                'w-full',
                'grid grid-cols-4 grid-rows-2 gap-0.5',
                'aspect-[2/1]',
                'rounded-xl overflow-hidden'
            )}>
                <div className='w-full h-full bg-slate-200 col-span-2 row-span-2 relative'>
                    <img className='w-full h-full object-cover object-bottom' src={data.displayUrl} alt=''/>
                    <div className={cn(
                        'absolute inset-0 bg-black/50',
                        'flex flex-col justify-center items-center',
                        'text-white'
                    )}>
                        <Icon icon={'mingcute:foot-fill'} className='size-8'/>
                        <span className='text-2xl font-semibold'>
                            {data?.distance ?? '-'}
                            <span className='text-sm'>{' '}km</span>
                        </span>
                        {data?.location && (
                            <span className='text-xs'>{data.location}</span>
                        )}
                    </div>
                </div>
                <div className={cn(
                    'relative size-full',
                    'flex flex-col justify-center items-center',
                    'bg-neutral-300'
                )}>
                    <Icon icon={'mingcute:time-line'} className='size-5'/>
                    <span className='text-lg font-semibold'>{data?.time ?? '-'}</span>
                    <span className='text-xs leading-tight opacity-60'>Time</span>
                </div>
                <div className={cn(
                    'size-full',
                    'flex flex-col justify-center items-center',
                    'bg-neutral-300'
                )}>
                    <Icon icon={'mingcute:fast-forward-line'} className='size-5'/>
                    <span className='text-lg font-semibold'>{data?.pace ?? '--'}</span>
                    <span className='text-xs leading-tight opacity-60'>Pace</span>
                </div>
                <div className={cn(
                    'size-full',
                    'flex flex-col justify-center items-center',
                    'bg-neutral-300'
                )}>
                    <Icon icon={'mingcute:fire-line'} className='size-5'/>
                    <span className='text-lg font-semibold'>{data?.calories ?? '-'}</span>
                    <span className='text-xs leading-tight opacity-60'>Calories</span>
                </div>
                <div className={cn(
                    'size-full',
                    'flex flex-col justify-center items-center',
                    'bg-neutral-300'
                )}>
                    <Icon icon={'mingcute:heartbeat-2-line'} className='size-5'/>
                    <span className='text-lg font-semibold'>{data?.heart ?? '--'}</span>
                    <span className='text-xs leading-tight opacity-60'>Heart Rate</span>
                </div>
            </div>

            <div className={cn(
                'w-full flex justify-between items-center gap-x-2'
            )}>
                <span className='px-1 mb-0.5 text-xs text-slate-600'>{moment.unix(data.timestamp).format('LL')}</span>
            </div>
        </Card.Blurry>
    )
}
