import React, { SVGProps } from 'react'


const BgGradients = ({ ref, ...props }: SVGProps<SVGElement>) => {
    return (
        <svg width='1200' height='1200' viewBox='0 0 1200 1200' fill='none' xmlns='http://www.w3.org/2000/svg' {...props}>
            <g clipPath='url(#clip0_1560_42)'>
                <g filter='url(#filter0_f_1560_42)'>
                    <path d='M442.174 656.878C540.204 656.878 619.674 595.317 619.674 519.378C619.674 443.439 540.204 381.878 442.174 381.878C344.143 381.878 264.674 443.439 264.674 519.378C264.674 595.317 344.143 656.878 442.174 656.878Z' fill='#38BDF8'/>
                    <path d='M871.783 760.217C969.814 760.217 1049.28 698.656 1049.28 622.717C1049.28 546.778 969.814 485.217 871.783 485.217C773.753 485.217 694.283 546.778 694.283 622.717C694.283 698.656 773.753 760.217 871.783 760.217Z' fill='#818CF8'/>
                    <path d='M327.5 795.272C425.531 795.272 505 733.712 505 657.772C505 581.833 425.531 520.272 327.5 520.272C229.469 520.272 150 581.833 150 657.772C150 733.712 229.469 795.272 327.5 795.272Z' fill='#C084FC'/>
                    <path d='M755.751 652C853.782 652 933.251 590.439 933.251 514.5C933.251 438.561 853.782 377 755.751 377C657.721 377 578.251 438.561 578.251 514.5C578.251 590.439 657.721 652 755.751 652Z' fill='#E879F9'/>
                    <path d='M597.792 823.81C695.823 823.81 775.292 762.249 775.292 686.31C775.292 610.37 695.823 548.81 597.792 548.81C499.762 548.81 420.292 610.37 420.292 686.31C420.292 762.249 499.762 823.81 597.792 823.81Z' fill='#22D3EE'/>
                </g>
            </g>
            <defs>
                <filter id='filter0_f_1560_42' x='-54' y='173' width='1307.28' height='854.81' filterUnits='userSpaceOnUse' colorInterpolationFilters='sRGB'>
                    <feFlood floodOpacity='0' result='BackgroundImageFix'/>
                    <feBlend mode='normal' in='SourceGraphic' in2='BackgroundImageFix' result='shape'/>
                    <feGaussianBlur stdDeviation='102' result='effect1_foregroundBlur_1560_42'/>
                </filter>
                <clipPath id='clip0_1560_42'>
                    <rect width='1200' height='1200' fill='white'/>
                </clipPath>
            </defs>
        </svg>
    )
}

export default BgGradients
