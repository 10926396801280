import { useAtom } from 'jotai/index'
import React, { useCallback, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { BottomSheet } from 'react-spring-bottom-sheet'

import { useKeyring } from '../../hooks/use-keyring'
import { agreementSheetPropsAtom } from '../../hooks/use-sheet-agreement'
import { cn, snooze } from '../../libs/utils'
import Button from '../button'
import { ButtonSolid } from '../button/button-solid'
import Label from '../label'


export const SheetAgreement = () => {
    const [sheetProps, setSheetProps] = useAtom(agreementSheetPropsAtom)

    const navigate = useNavigate()
    const [agreed, setAgreed] = useState(false)

    const { signOut } = useKeyring()

    const handleDismiss = useCallback(() => {
        setSheetProps(null)
    }, [setSheetProps])

    const handleLater = useCallback(async () => {
        sheetProps?.onSelect?.call(this, false)
        handleDismiss()
    }, [handleDismiss, sheetProps?.onSelect])

    const handleInputChanged = useCallback((e: React.ChangeEvent<HTMLInputElement>) => {
        setAgreed(e.target.checked)
    }, [])

    const handleConfirm = useCallback(async () => {
        sheetProps?.onSelect?.call(this, true)

        await snooze(60)
        handleDismiss()
    }, [handleDismiss, sheetProps?.onSelect])

    return (
        <BottomSheet
            open={!!sheetProps}
            onDismiss={handleLater}
            snapPoints={({ minHeight }) => minHeight}
        >
            <div className={cn(
                'w-full',
                'flex flex-col gap-y-6',
                'p-4'
            )}>
                <div className={cn(
                    'w-full',
                    'flex flex-col gap-y-2'
                )}>
                    {sheetProps?.title && (<Label.Section text={sheetProps.title}/>)}
                    {sheetProps?.texts?.map((text, idx) => {
                        return <p key={idx} className='text-sm text-slate-600'>{text}</p>
                    })}
                </div>
                <div className={cn(
                    'w-full',
                    'flex flex-col gap-y-2'
                )}>
                    <div className='relative flex items-start'>
                        <div className='flex h-6 items-center'>
                            <input
                                id='agreement'
                                name='agreement'
                                type='checkbox'
                                onChange={handleInputChanged}
                                className={cn(
                                    'size-5',
                                    'rounded border-rose-700',
                                    'text-rose-600 focus:ring-0'
                                )}
                            />
                        </div>
                        <div className='ml-2 text-sm/6'>
                            <label htmlFor='agreement' className='font-medium text-rose-700'>
                                {sheetProps?.agreement ?? 'I have read and agree to the information above'}
                            </label>
                        </div>
                    </div>

                    <ButtonSolid
                        className={cn(
                            'container',
                            'flex justify-center items-center gap-x-1',
                            'bg-rose-500 text-white font-semibold'
                        )}
                        onClick={handleConfirm}
                        disabled={!agreed}
                    >
                        <span>{sheetProps?.confirm ?? 'Confirm'}</span>
                    </ButtonSolid>

                    <Button.Solid className={cn(
                        'container',
                        'flex justify-center items-center gap-x-1'
                    )} onClick={handleLater}>
                        <span>{sheetProps?.cancel ?? 'Cancel'}</span>
                    </Button.Solid>
                </div>
            </div>
        </BottomSheet>
    )
}
