import { ClassValue, clsx } from 'clsx'
import { getAddress } from 'ethers'
import { chunk, isEmpty, shuffle } from 'lodash'
import { twMerge } from 'tailwind-merge'

import { AuthProviderType } from './types/auth.types'


export const formatPrice = (value: number = 0, locales: string = 'en-US') => {
    return new Intl.NumberFormat('en-US').format(value)
}

/**
 * strings
 * */

export const uidOf = (address?: string): string | undefined => {
    if (!address || isEmpty(address)) return

    address = address.startsWith('0x') ? address : `0x${address}`
    return getAddress(address).replace('0x', '').toLowerCase()
}

export const splitAddress = (address: string = '', by: number = 4, omit: boolean = true): string[] => {
    if (isEmpty(address)) return []
    if (omit) {
        address = address.startsWith('0x') ? address.slice(2) : address
    } else {
        address = address.startsWith('0x') ? address : `0x${address}`
    }
    return chunk([...address], by).map(each => each.join(''))
}

export const ellipsisAddress = (address: string = '', by: number = 6): string => {
    address = address.startsWith('0x') ? address : `0x${address}`
    const length = address.length

    if (length != 42) return ''
    return `${address.slice(0, by + 2)}...${address.slice(42 - by)}`
}

export const escape = (str: string) => {
    return str.replace(/\n/g, '\\n')
    // .replace(/\r/g, '\\r')
    // .replace(/\t/g, '\\t')
    // .replace(/\f/g, '\\f')
    // .replace(/\'/g, '\\\'')
    // .replace(/\"/g, '\\"')
    // .replace(/\&/g, '\\&')
    // .replace(/\b/g, '\\b')
}

export const parseTags = (text: string = '') => {
    // return text.split(/(#[\w\u00C0-\uD7A3\u3130-\u318F\uAC00-\uD7A3]+)/g)
    return text.split(/([#@][\w\u00C0-\uD7A3\u3130-\u318F\uAC00-\uD7A3]+)/g)
}

export const generatePasscodePads = () => {
    const NumStrings = ['0', '1', '2', '3', '4', '5', '6', '7', '8', '9']
    const AlphaStrings = ['A', 'B', 'C', 'D', 'E', 'F', 'G', 'H', 'I', 'J', 'K', 'L', 'M', 'N', 'O', 'P', 'Q', 'R', 'S', 'T', 'U', 'V', 'W', 'X', 'Y', 'Z']

    const numPads = shuffle(NumStrings)
    numPads.splice(9, 0, 'bio')
    numPads.splice(11, 0, 'del')

    const alphaPads = shuffle(AlphaStrings)
    alphaPads.splice(21, 0, 'noop')
    alphaPads.splice(27, 0, 'del')

    return { numPads, alphaPads }
}

export const buildQueryString = (query: Record<string, any>): string => {
    return Object.entries(query).map(([key, value]) =>
        key && value ? `${encodeURIComponent(key)}=${encodeURIComponent(value)}` : ''
    ).join('&')
}

export const getRedirectUrlOf = (provider: AuthProviderType) => {
    const location = window.location
    return `${location.protocol}//${location.host}/keyring/${provider}`
}

export const getFirstPathSegment = (path: string): string => {
    const regex = /^\/[^/]*/
    const match = path.match(regex)
    return match ? match[0] : ''
}

/**
 * paths
 * */

export function pathDepth(path: string = ''): number {
    const trimmedPath = path.replace(/^\/|\/$/g, '')
    if (trimmedPath === '') return 0

    const segments = trimmedPath.split('/')
    return segments.length
}

export function removeLastPath(path: string = ''): string {
    const trimmedPath = path.replace(/\/+$/, '')
    const lastSlashIndex = trimmedPath.lastIndexOf('/')

    if (lastSlashIndex === -1) return '/'
    return trimmedPath.substring(0, lastSlashIndex) || '/'
}

/**
 * numbers
 * */

export const randomOf = (max: number, min: number = 0): number => {
    min = Math.ceil(min)  // Round up to the next whole number
    max = Math.floor(max) // Round down to the previous whole number
    return Math.floor(Math.random() * (max - min + 1)) + min // Generate random integer
}

/**
 * enums
 * */

export const getEnumKeyByValue = (myEnum: any, enumValue: number | string): string => {
    let keys = Object.keys(myEnum).filter((x) => myEnum[x] == enumValue)
    return keys.length > 0 ? keys[0] : ''
}

/**
 * images
 * */

export const hexToRgba = (hex: string, alpha: number = 1): string => {
    // Remove the '#' if it's present
    hex = hex.replace('#', '')

    // If it's a shorthand hex code (#RGB), expand it to full form (#RRGGBB)
    if (hex.length === 3) {
        hex = hex.split('').map(char => char + char).join('')
    }

    // Convert the hex values to RGB components
    const bigint = parseInt(hex, 16)
    const r = (bigint >> 16) & 255
    const g = (bigint >> 8) & 255
    const b = bigint & 255

    // Return the RGBA string with the provided alpha value
    return `rgba(${r}, ${g}, ${b}, ${alpha})`
}

export const getLuminance = (r: number, g: number, b: number): number => {
    const [red, green, blue] = [r, g, b].map(v => {
        const sRGB = v / 255
        return sRGB <= 0.03928 ? sRGB / 12.92 : Math.pow((sRGB + 0.055) / 1.055, 2.4)
    })

    // Luminance formula: 0.2126*R + 0.7152*G + 0.0722*B
    return 0.2126 * red + 0.7152 * green + 0.0722 * blue
}

export const getComplementaryColor = (hex?: string): string => {
    if (!hex) return '#000000'

    // Ensure the color starts with a #
    if (hex.startsWith('#')) {
        hex = hex.slice(1)
    }

    // Convert 3-digit hex to 6-digit hex
    if (hex.length === 3) {
        hex = hex.split('').map(c => c + c).join('')
    }

    // Convert the hex string to RGB
    const r = parseInt(hex.slice(0, 2), 16)
    const g = parseInt(hex.slice(2, 4), 16)
    const b = parseInt(hex.slice(4, 6), 16)

    // Calculate the complementary color
    const compR = (255 - r).toString(16).padStart(2, '0')
    const compG = (255 - g).toString(16).padStart(2, '0')
    const compB = (255 - b).toString(16).padStart(2, '0')

    return `#${compR}${compG}${compB}`
}

export const extractMetadataFromBase64 = (base64: string = ''): { mimeType: string; ext: string } => {
    const match = base64.match(/^data:([a-zA-Z0-9-+/]+);base64,/)
    if (!match || isEmpty(match)) return { mimeType: '', ext: '' }

    const mimeType = match[1].toLowerCase()
    let ext = ''

    switch (mimeType) {
        case 'image/jpeg':
            ext = 'jpg'
            break
        case 'image/png':
            ext = 'png'
            break
        case 'image/gif':
            ext = 'gif'
            break
        case 'image/bmp':
            ext = 'bmp'
            break
        case 'image/webp':
            ext = 'webp'
            break
        case 'application/pdf':
            ext = 'pdf'
            break
        case 'text/plain':
            ext = 'txt'
            break
        case 'application/json':
            ext = 'json'
            break
        case 'application/xml':
            ext = 'xml'
            break
        case 'text/html':
            ext = 'html'
            break
        case 'audio/mpeg':
            ext = 'mp3'
            break
        case 'video/mp4':
            ext = 'mp4'
            break
    }
    return { mimeType, ext }
}

/**
 * snooze
 * */

export const snooze = (ms: number) => new Promise((resolve) => setTimeout(resolve, ms))
export const snoozeRandom = (min: number, max: number) => snooze(min + Math.round(Math.random() * Math.max(0, max - min)))

/**
 * classNames
 * */

export function cn(...inputs: ClassValue[]) {
    return twMerge(clsx(inputs))
}

/**
 * types
 * */

export const typeofIcon = (value: string = ''): boolean => {
    const prefixes = [
        'fluent-emoji',
        'fluent',
        'heroicons',
        'mingcute',
        'lucide',
        'mid',
        'uil'
    ]

    return prefixes.some(prefix => value.startsWith(prefix))
}

export const typeofColor = (color: string = ''): boolean => {
    const hexRegex = /^#([A-Fa-f0-9]{6}|[A-Fa-f0-9]{8})$/
    const rgbRegex = /^rgba?\(\s*(\d{1,3})\s*,\s*(\d{1,3})\s*,\s*(\d{1,3})\s*(,\s*(0|1|0?\.\d+)\s*)?\)$/

    return hexRegex.test(color) || rgbRegex.test(color)
}
