import AssetLogo from '../../assets/logo'
import { Adapter } from '../adapter.types'


export const AdapterZg: Adapter = {
    id: 'zg',
    icon: AssetLogo.Zing as string,
    name: 'ZING',
    cta: 'Daily Check-in',
    scheme: {
        android: 'com.openrhapsody.a2zing',
        googlePlay: '',
        apple: 'a2zing://',
        appStore: ''
    },
    tokenizer: {
        version: 'alliance',
        rewards: [{ key: 'collected', unit: 'Day', value: '10', repeat: true }],
        path: {
            profile: 'zg-user',
            data: 'zg-data'
        },
        fallback: {
            display: { color: '#68c3d4 ', stroke: '#ffffff', icon: 'mingcute:calendar-month-fill' }
        }
    }
}
