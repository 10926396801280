import { useEffect, useState } from 'react'


export const useWindowVisibility = (): boolean => {
    const [isWindowVisible, setIsWindowVisible] = useState<boolean>(!document.hidden)

    useEffect(() => {
        const handleVisibilityChange = () => {
            setIsWindowVisible(!document.hidden)
        }

        document.addEventListener('visibilitychange', handleVisibilityChange)
        return () => {
            document.removeEventListener('visibilitychange', handleVisibilityChange)
        }
    }, [])

    return isWindowVisible
}
