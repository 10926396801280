import { ZeroAddress } from 'ethers'

import { ChainMap } from './chains'
import TokenMap from './tokens'


const Addresses = {
    multicall3: '0xcA11bde05977b3631167028862bE2a173976CA11',
    [ChainMap.sepolia.id]: {
        [TokenMap.ethereum.id]: ZeroAddress,
        [TokenMap.tether.id]: '0x419Fe9f14Ff3aA22e46ff1d03a73EdF3b70A62ED',
        [TokenMap.bitcoin.id]: '0x66194F6C999b28965E0303a84cb8b797273B6b8b',
    }
}

export default Addresses
