import { CTR } from 'aes-js'
import { BytesLike, decodeBase64, encodeBase64, getBytes, hexlify, randomBytes, scrypt, toUtf8Bytes } from 'ethers'
import { combine, split } from 'shamir-secret-sharing'


const KdfParams = {
    N: 131072,
    r: 8,
    p: 1,
    dkLen: 16
}

/**
 * Shamir Secret Sharing
 * */

export const splitSecretToShares = async (secret: string, shares: number = 3, threshold: number = 2): Promise<string[]> => {
    const input = getBytes(secret)
    const chunks = await split(input, shares, threshold)
    return chunks.map(each => hexlify(each))
}

export const combineSecretShares = async (shares: string[]): Promise<string> => {
    const inputs = shares.map(each => getBytes(each))
    const output = await combine(inputs)
    return hexlify(output)
}

export async function encryptData(data: BytesLike, password: string): Promise<string> {
    const table = randomBytes(32)
    const passwordBytes = toUtf8Bytes(password, 'NFKC')
    const key = await scrypt(passwordBytes, table.slice(0, 16), KdfParams.N, KdfParams.r, KdfParams.p, KdfParams.dkLen)

    const aesCtr = new CTR(getBytes(key), table.slice(16, 32))
    const ciphertext = aesCtr.encrypt(getBytes(data))

    const encrypted = new Uint8Array(table.length + ciphertext.length)
    encrypted.set(table, 0)
    encrypted.set(ciphertext, 32)
    return encodeBase64(encrypted)
}

export async function decryptData(payload: string, password: string): Promise<string> {
    const data = decodeBase64(payload)
    const salt = data.slice(0, 16)
    const iv = data.slice(16, 32)
    const ciphertext = data.slice(32)

    const passwordBytes = toUtf8Bytes(password, 'NFKC')
    const key = await scrypt(passwordBytes, salt, KdfParams.N, KdfParams.r, KdfParams.p, KdfParams.dkLen)

    const aesCtr = new CTR(getBytes(key), iv)
    const decrypted = aesCtr.decrypt(ciphertext)
    return hexlify(decrypted)
}
