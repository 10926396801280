import { LabelCardTitle } from './label-card-title'
import { LabelSection } from './label-section'


const Label = {
    Section: LabelSection,
    CardTitle: LabelCardTitle
}

export default Label
