import { Icon } from '@iconify/react'
import QRCodeStyling from 'qr-code-styling'
import React, { useCallback, useEffect, useMemo, useRef } from 'react'
import { BottomSheet } from 'react-spring-bottom-sheet'

import AssetLogo from '../../assets/logo'
import { useBridgeClipboard } from '../../hooks/use-bridge'
import { useKeyring } from '../../hooks/use-keyring'
import { useWindowDimensions } from '../../hooks/use-window-dimensions'
import { cn, snooze } from '../../libs/utils'
import Button from '../button'
import Profile from '../profile'


export interface SheetReceiveProps {
    open: boolean
    onDismiss: () => void
}

export const SheetReceive = ({
    open,
    onDismiss
}: SheetReceiveProps) => {
    const containerRef = useRef<HTMLDivElement | null>(null)
    const qrContainerRef = useRef<HTMLDivElement | null>(null)
    
    const { clipboardSet } = useBridgeClipboard()
    const { width, height } = useWindowDimensions()
    const { keyring } = useKeyring()
    
    const qrCode = useMemo(() => {
        const size = Math.min(width, height) - 32 * 2
        return new QRCodeStyling({
            width: size,
            height: size,
            margin: 14,
            type: 'svg',
            data: keyring?.address,
            image: AssetLogo.ZingText,
            imageOptions: { margin: 8 },
            dotsOptions: { type: 'rounded' },
            cornersSquareOptions: { type: 'extra-rounded' }
        })
    }, [height, keyring?.address, width])
    
    const handleCopyAddress = useCallback(async () => {
        if (!keyring) return
        await clipboardSet(keyring.address, 'Address Copied!')
    }, [clipboardSet, keyring])
    
    const _styles = useCallback(async () => {
        let parent = containerRef.current?.parentElement
        while (!parent) {
            await snooze(100)
            parent = containerRef.current?.parentElement
        }
        parent?.setAttribute('style', 'height: 100%;')
        
        if (!qrContainerRef.current) return
        const child = qrContainerRef.current!.children[0]
        if (child) qrContainerRef.current!.removeChild(child)
        qrCode.append(qrContainerRef.current!)
    }, [qrCode])
    
    useEffect(() => {
        if (!open) return
        
        _styles()
    }, [_styles, open])
    
    return (
        <BottomSheet
            open={open}
            onDismiss={onDismiss}
            snapPoints={({ maxHeight }) => maxHeight * 0.82}
            className={'sheet-receive'}
        >
            <div className={cn(
                'w-full max-w-screen-sm h-full',
                'flex flex-col justify-start items-center',
                'mx-auto p-4'
            )} ref={containerRef}>
                <div className={cn(
                    'w-full grow',
                    'flex flex-col justify-center items-center gap-y-4'
                )}>
                    <div className={cn(
                        'w-fit h-fit',
                        'rounded-3xl overflow-hidden'
                    )} ref={qrContainerRef}/>
                    
                    <div className='flex flex-col justify-center items-center gap-y-2 px-4'>
                        <Profile.Name name={keyring?.name} size='xl' className='w-fit text-white'/>
                        <p className='text-white text-lg text-center'>
                            <span className='font-semibold text-xl'>{keyring?.address.slice(0, 8)}{' '}</span>
                            <span className='opacity-60'>{keyring?.address.slice(8, 22)}</span>
                            <br/>
                            <span className='opacity-60'>{keyring?.address.slice(22, 36)}</span>
                            <span className='font-semibold text-xl'>{' '}{keyring?.address.slice(-6)}</span>
                        </p>
                    </div>
                    
                    
                    <Button.Solid className={cn(
                        'w-full flex justify-center items-center gap-x-1'
                    )} onClick={handleCopyAddress}>
                        <Icon icon='mingcute:copy-2-line' className='size-5'/>
                        <span>Copy Address</span>
                    </Button.Solid>
                </div>
            </div>
        </BottomSheet>
    )
}
