import { cva, VariantProps } from 'class-variance-authority'
import React, { HTMLAttributes } from 'react'

import { cn } from '../../libs/utils'


const Styles = {
    it: 'container rounded-3xl p-4',
    theme: {
        solid: {
            backgroundColor: 'bg-white'
        },
        slate: {
            backgroundColor: 'bg-slate-300/30'
        },
        layer: {
            backgroundColor: 'bg-white/10'
        }
    }
}

// noinspection TypeScriptValidateTypes
const Variants = cva(Styles.it, {
    variants: {
        theme: {
            solid: Object.values(Styles.theme.solid),
            slate: Object.values(Styles.theme.slate),
            layer: Object.values(Styles.theme.layer)
        }
    },
    defaultVariants: {
        theme: 'solid'
    }
})

export interface ContainerProps extends HTMLAttributes<HTMLDivElement>, VariantProps<typeof Variants> {

}

export const ContainerBase = ({
    theme,
    ...props
}: ContainerProps) => {
    return (
        <div className={cn(
            Variants({ theme }),
            props.className
        )} onClick={props.onClick}>
            {props.children}
        </div>
    )
}
