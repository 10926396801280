import { HDNodeWallet, JsonRpcProvider, Mnemonic } from 'ethers'
import { BaseWallet } from 'ethers/lib.commonjs/wallet/base-wallet'
import { random } from 'lodash'
import React, { createContext, useCallback, useMemo, useState } from 'react'

import { useKeyring } from '../../hooks/use-keyring'
import Chains from '../../libs/blockchain/chains'
import { IChain } from '../../libs/types/blockchain.types'

// TODO select default chain
const defaultChain = Chains.find(each => each.testnet === true)!

export interface ChainContextProps {
    chain: IChain
    switchChain: (value: IChain) => void
    provider?: JsonRpcProvider
    signer?: BaseWallet
}

export const Context = createContext<ChainContextProps>({
    chain: defaultChain,
    switchChain(value: IChain): void {
    }
})

const ChainProvider: React.FC<{ children: React.ReactNode }> = ({ children }) => {
    const [chain, setChain] = useState<IChain>(defaultChain)

    const { keyring } = useKeyring()

    const provider = useMemo(() => {
        const idx = random(0, chain.endpoints.length - 1)
        const endpoint = chain.endpoints[idx]
        return new JsonRpcProvider(endpoint, chain.id)
    }, [chain.endpoints, chain.id])

    const signer = useMemo(() => {
        if (!keyring || !provider) return

        const mnemonic = Mnemonic.fromEntropy(keyring.s0)
        const wallet = HDNodeWallet.fromMnemonic(mnemonic)
        return wallet.connect(provider)
    }, [keyring, provider])

    const switchChain = useCallback((chain: IChain) => {
        setChain(chain)
    }, [])

    return (
        <Context.Provider value={{
            chain,
            switchChain,
            provider,
            signer
        }}>{children}</Context.Provider>
    )
}

export default ChainProvider
