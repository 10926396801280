import { Icon } from '@iconify/react'
import { isEmpty } from 'lodash'
import React, { HTMLAttributes, useCallback } from 'react'

import { useAdapter } from '../../hooks/use-adapter'
import { cn } from '../../libs/utils'
import Badge from '../badge'
import Layout from '../layout'


export interface ProfileAdapterProps extends HTMLAttributes<HTMLDivElement> {
    adapterId?: string
    // profile?: IgProfile
    // owner?: string
    // points?: string
    // action?: boolean
    // onAction?: () => void
    // float?: boolean
    // expand?: boolean
}

export const ProfileAdapter = ({
    adapterId,
    // profile,
    // owner,
    // points,
    // action = false,
    // onAction,
    // float = true,
    // expand = false,
    ...props
}: ProfileAdapterProps) => {
    const { adapter, profile, display } = useAdapter(adapterId)

    const renderBadgeView = useCallback(() => {
        return adapter?.tokenizer.rewards.map((reward, idx) => {
            return <Badge.IconDataTag key={idx} text={`${reward.unit}: ${profile?.[reward.key] ?? 0}`}/>
        })
    }, [adapter?.tokenizer.rewards, profile])

    return (
        <div className={cn(
            'container',
            'flex flex-col justify-center items-center shrink-0',
            'p-4',
            props.className
        )} onClick={props.onClick}>
            <Layout.AdapterIcon
                icon={adapter?.icon}
                display={display}
                size='lg'
            />

            <div className={cn(
                'leading-tight',
                'text-base font-semibold text-slate-900',
                'whitespace-pre-wrap',
                'mt-2'
            )}>
                <span>{profile?.username ?? adapter?.cta ?? ' '}</span>
            </div>
            <div className='flex items-center h-4'>
                <span className='text-sm text-slate-900'>{adapter?.name}</span>
                {adapter?.tokenizer.version === 'alliance' && (
                    <Icon icon='heroicons:check-badge-solid' className='size-4 text-cyan-500 ml-0.5'/>
                )}
            </div>

            {!isEmpty(adapter?.tokenizer.rewards) && (
                <div className={cn(
                    'w-full flex justify-center items-center flex-wrap gap-2 mt-2'
                )}>
                    {renderBadgeView()}
                </div>
            )}
        </div>
    )
}
