import { Icon } from '@iconify/react'
import React, { HTMLAttributes } from 'react'

import { useKeyring } from '../../hooks/use-keyring'
import { cn } from '../../libs/utils'
import Profile from '../profile'
import { ProfileAddressType } from '../profile/profile-address'


export interface WalletAccountProps extends HTMLAttributes<HTMLDivElement> {
    icon?: 'none' | 'chevron'
    format?: ProfileAddressType
    classNameAvatar?: string
    classNameName?: string
    classNameAddress?: string
}

export const WalletAccount = ({
    icon = 'chevron',
    format = 'ellipsis6',
    classNameAvatar = '',
    classNameName = '',
    classNameAddress = '',
    ...props
}: WalletAccountProps) => {
    const { keyring } = useKeyring()

    return (
        <div className={cn(
            'w-fit',
            'flex justify-center items-center',
            'gap-x-2 p-1.5',
            'rounded-full',
            'bg-white',
            'ring-1 ring-black/5',
            'cursor-pointer',
            'shadow-sm',
            icon === 'none' && 'pr-4',
            props.className
        )} onClick={props.onClick}>
            <Profile.Avatar avatar={keyring?.avatar} size='sm' className={classNameAvatar}/>
            <div className='flex flex-col items-start'>
                <Profile.Name name={keyring?.name} className={cn('text-sm', classNameName)}/>
                <Profile.Address address={keyring?.address} format={format} className={cn('text-xs', classNameAddress)}/>
            </div>
            {icon === 'chevron' && <Icon icon={'heroicons:chevron-right-solid'} className='size-5 text-slate-600 ml-1 mr-1'/>}
        </div>
    )
}
