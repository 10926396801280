import { atom, useSetAtom } from 'jotai'
import { useCallback } from 'react'


export const authSheetPropsAtom = atom<boolean>(false)

export const useSheetAuth = () => {
    const setAuthSheetProps = useSetAtom(authSheetPropsAtom)

    const showAuthSheet = useCallback(() => {
        setAuthSheetProps(true)
    }, [setAuthSheetProps])

    const hideAuthSheet = useCallback(() => setAuthSheetProps(false), [setAuthSheetProps])

    return {
        showAuthSheet, hideAuthSheet
    }
}
