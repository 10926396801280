import { Icon } from '@iconify/react'
import React, { useCallback, useEffect } from 'react'

import Button from '../../components/button'
import Card from '../../components/card'
import Page from '../../components/page'
import { useActiveAdapter } from '../../hooks/use-adapter'
import { useBridgeApp, useBridgeClipboard } from '../../hooks/use-bridge'
import { cn } from '../../libs/utils'
import { AdapterStepperProps } from '../../views/tokenizer/view-tokenizer-steps'


export const IgPageConnecting = ({ goto, forward }: AdapterStepperProps) => {
    const { adapter, nonce, updateNonce } = useActiveAdapter()
    const { clipboardSet } = useBridgeClipboard()
    const { appUri } = useBridgeApp()

    const handleCopy = useCallback(async () => {
        if (!nonce) return

        await clipboardSet(nonce, 'Verification code copied to clipboard')
    }, [clipboardSet, nonce])

    const handleSendMessage = useCallback(() => {
        appUri('https://www.instagram.com/a2.zing')
    }, [appUri])

    useEffect(() => {
        updateNonce()
    }, [updateNonce])

    return (
        <Page.AnimatedNested key={'ig.page-connecting'}>
            <div className={cn(
                'absolute top-[15%]',
                'w-full',
                'flex flex-col justify-center items-start',
                'px-4'
            )}>
                <div className='text-3xl font-semibold text-slate-900/15'>
                    Connect Instagram
                </div>
                <div className='text-lg font-normal text-slate-900/60'>
                    To verify your Instagram account, please send the code below via Instagram message.
                </div>
            </div>

            <div className={cn(
                'w-full',
                'flex flex-col justify-center items-center gap-y-4',
                'my-auto'
            )}>
                <Card.Blurry className='text-sm my-auto relative'>
                    <div className={cn(
                        'w-full',
                        'flex justify-between items-center'
                    )} onClick={handleCopy}>
                        <span className={cn(
                            'grow p-4',
                            'rounded-l-xl',
                            'bg-white',
                            'text-base',
                            'ring-1 ring-cyan-500',
                            'break-all'
                        )}>{nonce}</span>
                        <div className={cn(
                            'flex items-center gap-x-0.5 h-full px-2.5',
                            'rounded-r-xl',
                            'bg-cyan-500 text-white font-semibold',
                            'ring-1 ring-cyan-500',
                            'shrink-0'
                        )}>
                            <span>Copy</span>
                            <Icon icon={'mingcute:copy-2-line'} className='size-5'/>
                        </div>
                    </div>

                    <Button.Solid className={cn(
                        'container',
                        'flex flex-col justify-center items-center',
                        'bg-cyan-500 text-white font-semibold'
                    )} onClick={handleSendMessage}>
                        <span className='text-lg tracking-tighter'>Send Instagram Message</span>
                        <span>@A2.ZING</span>
                    </Button.Solid>
                </Card.Blurry>
            </div>

            <div className='fixed bottom-0 left-0 right-0 p-4'>
                <Card.App app={adapter}/>
            </div>
        </Page.AnimatedNested>
    )
}
