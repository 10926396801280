import { Icon } from '@iconify/react'
import { useAtom } from 'jotai/index'
import React, { useCallback, useEffect } from 'react'
import { BottomSheet } from 'react-spring-bottom-sheet'

import { useDevicePermissions } from '../../hooks/use-device-permissions'
import { permissionsSheetPropsAtom } from '../../hooks/use-sheet-permissions'
import { PermissionsStatus } from '../../libs/types/device.types'
import { cn, snooze } from '../../libs/utils'
import Button from '../button'
import { ButtonSolid } from '../button/button-solid'
import Label from '../label'


export const SheetPermissions = () => {
    const [permissionsProps, setPermissionsProps] = useAtom(permissionsSheetPropsAtom)
    const { checkPermission, requestPermission } = useDevicePermissions()

    const handleDismiss = useCallback(() => {
        setPermissionsProps(null)
    }, [setPermissionsProps])

    const checkStatus = useCallback(async () => {
        if (!permissionsProps?.key) return

        const status = await checkPermission(permissionsProps.key)
        if (status === PermissionsStatus.granted) {
            permissionsProps?.onConfirm?.call(this, permissionsProps.key, status)
            handleDismiss()
        }
    }, [checkPermission, handleDismiss, permissionsProps?.key, permissionsProps?.onConfirm])

    const handleRequest = useCallback(async () => {
        if (!permissionsProps?.key) return

        const status = await requestPermission(permissionsProps.key)
        if (status !== PermissionsStatus.permanentlyDenied) {
            await snooze(60)
            permissionsProps?.onConfirm?.call(this, permissionsProps.key, status)
            handleDismiss()
        }
    }, [permissionsProps?.key, permissionsProps?.onConfirm, requestPermission, handleDismiss])

    const handleLater = useCallback(async () => {
        if (!permissionsProps?.key) {
            handleDismiss()
            return
        }

        const status = await requestPermission(permissionsProps.key)
        permissionsProps?.onConfirm?.call(this, permissionsProps.key, status)
        handleDismiss()
    }, [handleDismiss, permissionsProps?.key, permissionsProps?.onConfirm, requestPermission])

    useEffect(() => {
        if (!permissionsProps?.key) return

        checkStatus()
    }, [permissionsProps?.key, checkStatus])

    return (
        <BottomSheet
            open={!!permissionsProps}
            onDismiss={handleLater}
            snapPoints={({ minHeight }) => minHeight}
        >
            <div className={cn(
                'relative',
                'w-full min-h-60',
                'flex flex-col justify-end gap-y-4',
                'p-4'
            )}>
                {permissionsProps?.icon && (
                    <Icon icon={permissionsProps.icon} className={cn(
                        'absolute -top-2 right-2',
                        'size-24',
                        'rotate-[24deg]',
                        'text-cyan-500/30'
                    )}/>
                )}

                <div className={cn(
                    'w-full',
                    'flex flex-col justify-center gap-y-2'
                )}>
                    {permissionsProps?.text && (
                        <Label.Section text={permissionsProps.text}/>
                    )}

                    {permissionsProps?.subtext && (
                        <p>
                            {permissionsProps.subtext}
                        </p>
                    )}
                </div>

                <div className={cn(
                    'w-full',
                    'flex flex-col justify-center items-center gap-y-4'
                )}>
                    <ButtonSolid
                        className={cn(
                            'container',
                            'flex justify-center items-center gap-x-1',
                            'bg-cyan-500 text-white font-semibold'
                        )}
                        onClick={handleRequest}
                    >
                        <span>{permissionsProps?.confirm ?? 'Allow'}</span>
                    </ButtonSolid>

                    <Button.Text className={cn(
                        'container',
                        'flex justify-center items-center gap-x-1'
                    )} onClick={handleLater}>
                        <span>Later</span>
                    </Button.Text>
                </div>
            </div>
        </BottomSheet>
    )
}
