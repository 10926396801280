import { Icon } from '@iconify/react'
import React, { ButtonHTMLAttributes } from 'react'

import { cn } from '../../libs/utils'


export interface ButtonIconProps extends ButtonHTMLAttributes<HTMLButtonElement> {
    icon?: string
    text?: string
    reverse?: boolean
}

export const ButtonIcon = ({
    icon,
    text,
    reverse = false,
    ...props
}: ButtonIconProps) => {

    return (
        <button onClick={props.onClick} className={cn(
            'flex justify-center items-center gap-1',
            'h-9',
            'px-2.5 py-1.5',
            'bg-white',
            'rounded-xl',
            'shadow-md',
            'text-cyan-500',
            'stroke-1 stroke-cyan-500',
            reverse ? 'flex-row-reverse' : '',
            props.className
        )} type={props.type} disabled={props.disabled}>
            {icon && <Icon icon={icon} className='size-4'/>}
            {text && <span className='font-extrabold'>{text}</span>}
        </button>
    )
}
