import { AnimationControls, motion, Target, TargetAndTransition, VariantLabels, Variants } from 'framer-motion'
import React, { Attributes, forwardRef, HTMLAttributes } from 'react'

import { cn } from '../../libs/utils'


export interface PageAnimatedProps extends HTMLAttributes<HTMLDivElement>, Attributes {
    variants?: Variants
    custom?: any
    initial?: boolean | Target | VariantLabels
    animate?: AnimationControls | TargetAndTransition | VariantLabels | boolean
    exit?: TargetAndTransition | VariantLabels
    children?: React.ReactNode
}

export const PageAnimated = forwardRef<HTMLDivElement, PageAnimatedProps>(({
    variants,
    custom,
    initial,
    animate,
    exit,
    children,
    ...props
}, ref) => {
    return (
        <motion.div
            className={cn(
                'w-full h-full',
                'overflow-y-scroll scrollbar-hide overscroll-none',
                props.className
            )}
            key={props.key}
            variants={variants}
            custom={custom}
            initial={initial}
            animate={animate}
            exit={exit}
            ref={ref}
        >
            {children}
        </motion.div>
    )
})

PageAnimated.displayName = 'PageAnimated'
