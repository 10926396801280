import React, { useEffect, useMemo, useRef } from 'react'
import { useLocation } from 'react-router-dom'
import { createNoise3D } from 'simplex-noise'

import { cn } from '../../libs/utils'


export const BackgroundWavy = ({
    children,
    className,
    containerClassName,
    colors,
    waveWidth,
    backgroundFill,
    blur = 10,
    speed = 'fast',
    waveOpacity = 0.5,
    ...props
}: {
    children?: any;
    className?: string;
    containerClassName?: string;
    colors?: string[];
    waveWidth?: number;
    backgroundFill?: string;
    blur?: number;
    speed?: 'slow' | 'fast';
    waveOpacity?: number;
    [key: string]: any;
}) => {
    const noise = createNoise3D()
    let w: number,
        h: number,
        nt: number,
        i: number,
        x: number,
        ctx: any,
        canvas: any
    const canvasRef = useRef<HTMLCanvasElement | null>(null)
    const getSpeed = () => {
        switch (speed) {
            case 'slow':
                return 0.001
            case 'fast':
                return 0.002
            default:
                return 0.001
        }
    }

    const init = () => {
        canvas = canvasRef.current
        ctx = canvas.getContext('2d')
        w = ctx.canvas.width = window.innerWidth
        h = ctx.canvas.height = window.innerHeight
        ctx.filter = `blur(${blur}px)`
        nt = 0
        window.onresize = function() {
            w = ctx.canvas.width = window.innerWidth
            h = ctx.canvas.height = window.innerHeight
            ctx.filter = `blur(${blur}px)`
        }
        render()
    }

    const waveColors = colors ?? [
        '#38bdf8',
        '#818cf8',
        '#c084fc',
        '#e879f9',
        '#22d3ee'
    ]

    const drawWave = (n: number) => {
        nt += getSpeed()
        for (i = 0 ; i < n ; i++) {
            ctx.beginPath()
            ctx.lineWidth = waveWidth || 50
            ctx.strokeStyle = waveColors[i % waveColors.length]
            for (x = 0 ; x < w ; x += 5) {
                let y = noise(x / 800, 0.3 * i, nt) * 100
                ctx.lineTo(x, y + h * 0.5) // adjust for height, currently at 50% of the container
            }
            ctx.stroke()
            ctx.closePath()
        }
    }

    let animationId: number
    const render = () => {
        ctx.fillStyle = backgroundFill || 'white'
        ctx.globalAlpha = waveOpacity || 0.5
        ctx.fillRect(0, 0, w, h)
        drawWave(5)
        animationId = requestAnimationFrame(render)
    }

    useEffect(() => {
        init()
        return () => {
            cancelAnimationFrame(animationId)
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    const { pathname } = useLocation()

    const dynamicStyles = useMemo(() => {
        switch (pathname) {
            case '/':
                /**
                 * Wallet.Balance Position with 'mt-80 (320px)'
                 * @see {@link ViewMain}
                 */
                return { top: `calc(-50vh + 320px)` }
            default:
                return {}
        }
    }, [pathname])

    return (
        <div
            className={cn(
                'w-full h-full min-h-svh',
                'flex flex-col items-center justify-center',
                containerClassName
            )}
        >
            <canvas
                className={cn(
                    'fixed',
                    'inset-0',
                    'z-0'
                )}
                ref={canvasRef}
                id='canvas'
                style={{
                    filter: `blur(${blur}px)`,
                    ...dynamicStyles
                }}
            />

            <div className={cn(
                'relative',
                'w-full h-full',
                className
            )} {...props}>
                {children}
            </div>
        </div>
    )
}
