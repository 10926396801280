import { isEmpty } from 'lodash'
import { useCallback } from 'react'
import secureLocalStorage from 'react-secure-storage'

import { AdapterScheme } from '../adapter/adapter.types'
import { isWebEnv } from '../libs/helper/helper.env'
import { useToasts } from './use-toasts'


export enum BridgeSig {
    metadata = 'metadata',
    keyringGet = 'keyring/get',
    keyringSet = 'keyring/set',
    keyringClear = 'keyring/clear',
    keyringGetShare1 = 'keyring/get-share1',
    keyringSetShare1 = 'keyring/set-share1',
    keyringClearShare1 = 'keyring/clear-share1',
    storageGet = 'storage/get',
    storageUpdate = 'storage/update',
    storageClear = 'storage/clear',
    permissionStatus = 'permission/status',
    permissionRequest = 'permission/request',
    permissionSettings = 'permission/settings',
    permissionFcmToken = 'permission/fcmToken',
    permissionAttToken = 'permission/attToken',
    clipboardSet = 'clipboard/set',
    clipboardGet = 'clipboard/get',
    appCheck = 'app/check',
    appLaunch = 'app/launch',
    appUri = 'app/uri',
    preferencesRead = 'preferences/read',
    preferencesWrite = 'preferences/write',
    preferencesRemove = 'preferences/remove',
    mediaPick = 'media/pick',
    mediaCamera = 'media/camera',
    toastSnack = 'toast/snack',
    toastAlarm = 'toast/alarm',
    toastData = 'toast/data',
    success = 'success',
    timeout = 'timeout',
    error = 'error',
    errorNoBridge = 'error/no-bridge'
}

export type StorageKeys = 'keyring:json'

export enum AppState {
    Unknown, Installed, NotInstalled
}

export const bridgeCall = async (sig: BridgeSig, payload?: Record<string, any>): Promise<any> => {
    if (!window.bridge) {
        console.error('bridgeCall', 'window.bridge is undefined')
        return
    }
    return await window.bridge.callHandler('bridgeHandler', sig.toString(), payload)
}

export const useBridgeMetadata = () => {
    const getMetadata = useCallback(async (): Promise<{ locale: string, tz: number } | null> => {
        return await bridgeCall(BridgeSig.metadata)
    }, [])

    return { getMetadata }
}

export const useBridgeStorage = () => {
    const storageGet = useCallback(async (key: StorageKeys): Promise<any | null> => {
        if (isWebEnv) {
            const value = secureLocalStorage.getItem(key)
            if (!value) return null
            return key.endsWith(':json') ? JSON.parse(value as string) : value
        }
        return await bridgeCall(BridgeSig.storageGet, { key })
    }, [])

    const storageUpdate = useCallback(async (key: StorageKeys, value: string) => {
        if (isWebEnv) {
            secureLocalStorage.setItem(key, value)
            return true
        }
        await bridgeCall(BridgeSig.storageUpdate, { key, value })
    }, [])

    const storageClear = useCallback(async () => {
        if (isWebEnv) {
            secureLocalStorage.clear()
            return true
        }
        await bridgeCall(BridgeSig.storageClear)
    }, [])

    return { storageGet, storageUpdate, storageClear }
}

export const useBridgeClipboard = () => {
    const { showCopiedAlarm } = useToasts()

    const clipboardSet = useCallback(async (value: string, toast?: string) => {
        if (window.bridge) {
            await bridgeCall(BridgeSig.clipboardSet, { value, toast })
        } else {
            await navigator.clipboard.writeText(value)
            if (toast) showCopiedAlarm(toast)
        }
        return true
    }, [showCopiedAlarm])

    const clipboardGet = useCallback(async (): Promise<string> => {
        if (window.bridge) {
            return await bridgeCall(BridgeSig.clipboardGet) ?? ''
        } else {
            return await navigator.clipboard.readText()
        }
    }, [])

    return { clipboardSet, clipboardGet }
}

export const useBridgeApp = () => {
    const appCheck = useCallback(async (scheme: AdapterScheme): Promise<AppState> => {
        if (isWebEnv) return AppState.Unknown

        const result = await bridgeCall(BridgeSig.appCheck, { ...scheme })
        if (!result) return AppState.Unknown
        return result ? AppState.Installed : AppState.NotInstalled
    }, [])

    const appLaunch = useCallback(async (scheme: AdapterScheme): Promise<boolean> => {
        return await bridgeCall(BridgeSig.appLaunch, { ...scheme })
    }, [])

    const appUri = useCallback(async (uri?: string, internal: boolean = false): Promise<boolean> => {
        if (isEmpty(uri)) return false

        return await bridgeCall(BridgeSig.appUri, { uri, internal })
    }, [])

    return { appCheck, appLaunch, appUri }
}

export const useBridgePreferences = () => {
    const preferencesRead = useCallback(async (key: string) => {
        return await bridgeCall(BridgeSig.preferencesRead, { key }) ?? ''
    }, [])

    const preferencesWrite = useCallback(async (key: string, value: string) => {
        await bridgeCall(BridgeSig.preferencesWrite, { key, value })
    }, [])

    const preferencesRemove = useCallback(async (key: string) => {
        await bridgeCall(BridgeSig.preferencesRemove, { key })
    }, [])
}

export const useBridgeMedia = () => {
    const mediaPick = useCallback(async (): Promise<string | null> => {
        return await bridgeCall(BridgeSig.mediaPick)
    }, [])

    return { mediaPick }
}
