import { isEmpty } from 'lodash'
import React, { createContext, useCallback, useEffect } from 'react'

import { useAccount } from '../../hooks/use-account'
import { useDevicePermissions } from '../../hooks/use-device-permissions'
import Zing from '../../libs/zing'
import { DeviceReceiver } from './device-receiver'


export interface DeviceContextProps {
}

export const Context = createContext<DeviceContextProps>({})

const DeviceProvider: React.FC<{ children: React.ReactNode }> = ({ children }) => {
    const { fcmToken, attToken } = useDevicePermissions()
    const { account } = useAccount()

    const handleTokenUpdates = useCallback(async () => {
        if (!account) return

        const fcmChanged = !isEmpty(fcmToken) && account?.fcmToken !== fcmToken
        const attChanged = !isEmpty(attToken) && account?.attToken !== attToken
        if (fcmChanged || attChanged) {
            await Zing.database.updateAccountTokens(account.uid, { fcmToken, attToken })
        }
    }, [account, attToken, fcmToken])

    useEffect(() => {
        // TODO stateful test
        if (!account) return

        handleTokenUpdates()
    }, [account, fcmToken, attToken, handleTokenUpdates])

    return (
        <Context.Provider value={{}}>
            {children}
            <DeviceReceiver/>
        </Context.Provider>
    )
}

export default DeviceProvider
