import { AdapterIg } from './ig/ig.adapter'
import { AdapterNRC } from './nrc/nrc.adapter'
import { AdapterZg } from './zg/zg.adapter'


export const Adapters = [
    AdapterZg,
    AdapterIg,
    AdapterNRC
]
