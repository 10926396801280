import { DialogPanel, DialogTitle } from '@headlessui/react'
import { Icon } from '@iconify/react'
import { useSetAtom } from 'jotai'
import React, { useCallback, useState } from 'react'

import { AdapterTask } from '../../../adapter/adapter.types'
import AssetAnimation from '../../../assets/animation'
import { cn } from '../../../libs/utils'
import Button from '../../button'
import { popupAtom } from '../popup-container'


export interface DialogPanelPointsProps {
    data: Partial<AdapterTask>
}

export const DialogPanelPoints = ({
    data
}: DialogPanelPointsProps) => {
    const setPopup = useSetAtom(popupAtom)
    const [claim, setClaim] = useState(false)

    const handleClaim = useCallback(() => {
        setClaim(true)
        setTimeout(() => setPopup(null), 360)
    }, [setPopup])

    return (
        <DialogPanel
            transition
            className={cn(
                'relative w-full',
                'flex flex-col items-center',
                'bg-white',
                'px-4 pt-8 pb-4',
                'rounded-2xl overflow-hidden',
                'shadow-2xl',
                'transform',
                'transition-all',
                'data-[closed]:translate-y-4',
                'data-[closed]:opacity-0',
                'data-[enter]:duration-300',
                'data-[enter]:ease-out',
                'data-[leave]:duration-200',
                'data-[leave]:ease-in'
            )}
        >
            <div className={cn(
                'w-full',
                'flex flex-col justify-center items-center gap-y-2',
                'text-center'
            )}>
                <img src={AssetAnimation.PartyPopper} className='size-24' alt='Great!'/>
                <div className='text-base text-slate-600 whitespace-pre-wrap'>
                    <DialogTitle as='h3' className='text-2xl font-semibold text-slate-900'>Congratulations!</DialogTitle>
                    {`You've earned ${data?.points ?? '--'} points`}
                </div>
                <Button.Point value={data?.points ?? '--'} size='lg' className='shadow-none'/>

                <Button.Solid className={cn(
                    'w-full flex justify-center items-center',
                    'bg-cyan-500 text-white font-semibold'
                )} onClick={handleClaim}>
                    {
                        claim
                        ? <Icon icon={'mingcute:check-line'} className='size-6'/>
                        : <span>Claim Points</span>
                    }
                </Button.Solid>
            </div>
        </DialogPanel>
    )
}
