const logger = {
    error: (from: any, ...data: any[]) => {
        if (from) {
            console.log(`\t Oops! [${from.toString()}]`, data)
        } else {
            console.log(`\t Oops!`, data)
        }
    }
}

export default logger
