import { useSetAtom } from 'jotai'
import { isEmpty } from 'lodash'
import React, { useCallback, useEffect } from 'react'
import { useLocation, useNavigate } from 'react-router-dom'

import { Adapters } from '../../adapter/adapter.list'
import { mediaPickRestoreAtom } from '../../components/sheet/sheet-image-uploader'
import { BridgeSig } from '../../hooks/use-bridge'
import { useToasts } from '../../hooks/use-toasts'
import { isWebEnv } from '../../libs/helper/helper.env'


export const DeviceReceiver = () => {
    const navigate = useNavigate()
    const { pathname } = useLocation()
    const { showAlarm } = useToasts()

    const setMediaPickRestore = useSetAtom(mediaPickRestoreAtom)

    const handleInvocation = useCallback((sig: string, payload: string) => {
        if (!sig) return
        try {
            const p = JSON.parse(payload)
            switch (sig as BridgeSig) {
                case 'toast/data': {
                    if (p['key'] === 'task/ok') {
                        const adapterId = p['value']?.toString()
                        const adapter = Adapters.find(adapter => adapter.id === adapterId)
                        if (!adapter) return

                        const destination = `/tokenizer/${adapter.id}`
                        if (pathname.includes(destination)) return
                        showAlarm({
                            icon: 'fluent-emoji:smiling-face-with-heart-eyes',
                            text: `${adapter.name} Completed`,
                            ttl: 0,
                            onClick: () => navigate(destination)
                        })
                    }
                    break
                }
                case BridgeSig.mediaPick: {
                    const tag = localStorage.getItem(BridgeSig.mediaPick)
                    if (isEmpty(p['image'])) {
                        localStorage.removeItem(BridgeSig.mediaPick)
                        return
                    }

                    setMediaPickRestore(p['image'])
                    switch (tag) {
                        case 'nrc':
                            navigate('/tokenizer/nrc?restore=true')
                            break
                        default:
                            break
                    }
                    break
                }
            }
        } catch (e) {
            console.error('handleBridgeEvent', e)
        }
    }, [navigate, pathname, setMediaPickRestore, showAlarm])

    useEffect(() => {
        if (isWebEnv || !window.bridge) return

        window.bridge.invoke = handleInvocation
    }, [handleInvocation])

    return <React.Fragment/>
}
