import type { HTMLAttributes } from 'react'
import React from 'react'


export interface SpinnerDot3Props extends HTMLAttributes<SVGElement> {
}

export const SpinnerDot3 = ({
    ...props
}: SpinnerDot3Props) => {
    return (
        <svg xmlns='http://www.w3.org/2000/svg' width={48} height={48} viewBox='0 0 24 24' {...props}>
            <circle cx={4} cy={12} r={0} fill='currentColor'>
                <animate fill='freeze' attributeName='r' begin='0;svgSpinners3DotsMove1.end' calcMode='spline' dur='0.5s' keySplines='.36,.6,.31,1' values='0;3'></animate>
                <animate fill='freeze' attributeName='cx' begin='svgSpinners3DotsMove7.end' calcMode='spline' dur='0.5s' keySplines='.36,.6,.31,1' values='4;12'></animate>
                <animate fill='freeze' attributeName='cx' begin='svgSpinners3DotsMove5.end' calcMode='spline' dur='0.5s' keySplines='.36,.6,.31,1' values='12;20'></animate>
                <animate id='svgSpinners3DotsMove0' fill='freeze' attributeName='r' begin='svgSpinners3DotsMove3.end' calcMode='spline' dur='0.5s' keySplines='.36,.6,.31,1' values='3;0'></animate>
                <animate id='svgSpinners3DotsMove1' fill='freeze' attributeName='cx' begin='svgSpinners3DotsMove0.end' dur='0.001s' values='20;4'></animate>
            </circle>
            <circle cx={4} cy={12} r={3} fill='currentColor'>
                <animate fill='freeze' attributeName='cx' begin='0;svgSpinners3DotsMove1.end' calcMode='spline' dur='0.5s' keySplines='.36,.6,.31,1' values='4;12'></animate>
                <animate fill='freeze' attributeName='cx' begin='svgSpinners3DotsMove7.end' calcMode='spline' dur='0.5s' keySplines='.36,.6,.31,1' values='12;20'></animate>
                <animate id='svgSpinners3DotsMove2' fill='freeze' attributeName='r' begin='svgSpinners3DotsMove5.end' calcMode='spline' dur='0.5s' keySplines='.36,.6,.31,1' values='3;0'></animate>
                <animate id='svgSpinners3DotsMove3' fill='freeze' attributeName='cx' begin='svgSpinners3DotsMove2.end' dur='0.001s' values='20;4'></animate>
                <animate fill='freeze' attributeName='r' begin='svgSpinners3DotsMove3.end' calcMode='spline' dur='0.5s' keySplines='.36,.6,.31,1' values='0;3'></animate>
            </circle>
            <circle cx={12} cy={12} r={3} fill='currentColor'>
                <animate fill='freeze' attributeName='cx' begin='0;svgSpinners3DotsMove1.end' calcMode='spline' dur='0.5s' keySplines='.36,.6,.31,1' values='12;20'></animate>
                <animate id='svgSpinners3DotsMove4' fill='freeze' attributeName='r' begin='svgSpinners3DotsMove7.end' calcMode='spline' dur='0.5s' keySplines='.36,.6,.31,1' values='3;0'></animate>
                <animate id='svgSpinners3DotsMove5' fill='freeze' attributeName='cx' begin='svgSpinners3DotsMove4.end' dur='0.001s' values='20;4'></animate>
                <animate fill='freeze' attributeName='r' begin='svgSpinners3DotsMove5.end' calcMode='spline' dur='0.5s' keySplines='.36,.6,.31,1' values='0;3'></animate>
                <animate fill='freeze' attributeName='cx' begin='svgSpinners3DotsMove3.end' calcMode='spline' dur='0.5s' keySplines='.36,.6,.31,1' values='4;12'></animate>
            </circle>
            <circle cx={20} cy={12} r={3} fill='currentColor'>
                <animate id='svgSpinners3DotsMove6' fill='freeze' attributeName='r' begin='0;svgSpinners3DotsMove1.end' calcMode='spline' dur='0.5s' keySplines='.36,.6,.31,1' values='3;0'></animate>
                <animate id='svgSpinners3DotsMove7' fill='freeze' attributeName='cx' begin='svgSpinners3DotsMove6.end' dur='0.001s' values='20;4'></animate>
                <animate fill='freeze' attributeName='r' begin='svgSpinners3DotsMove7.end' calcMode='spline' dur='0.5s' keySplines='.36,.6,.31,1' values='0;3'></animate>
                <animate fill='freeze' attributeName='cx' begin='svgSpinners3DotsMove5.end' calcMode='spline' dur='0.5s' keySplines='.36,.6,.31,1' values='4;12'></animate>
                <animate fill='freeze' attributeName='cx' begin='svgSpinners3DotsMove3.end' calcMode='spline' dur='0.5s' keySplines='.36,.6,.31,1' values='12;20'></animate>
            </circle>
        </svg>
    )
}
