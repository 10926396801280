import { useContext } from 'react'

import { Context } from '../contexts/chain'


export const useActiveChain = () => {
    const { chain, switchChain } = useContext(Context)

    return {
        activeChain: chain,
        switchChain
    }
}
