import moment from 'moment'
import React, { HTMLAttributes, useMemo } from 'react'

import Card from '../../components/card'
import { cn } from '../../libs/utils'
import { ImgPosition } from '../adapter-data-placeholder'
import { IgPost } from './ig.types'


export interface IgDataCellProps extends HTMLAttributes<HTMLDivElement> {
    post: IgPost
}

export const IgDataCell = ({
    post,
    ...props
}: IgDataCellProps) => {
    const ImageViews = useMemo(() => {
        const displayUrls = post.displayUrls ?? []
        if (displayUrls.length === 0) return null
        switch (displayUrls.length) {
            case 1:
                return [
                    <ImgPosition key={0} src={displayUrls[0]} className='col-span-4 row-span-2'/>
                ]
            case 2:
                return [
                    <ImgPosition key={0} src={displayUrls[0]} className='col-span-2 row-span-2'/>,
                    <ImgPosition key={1} src={displayUrls[1]} className='col-span-2 row-span-2'/>
                ]
            case 3:
                return [
                    <ImgPosition key={0} src={displayUrls[0]} className='col-span-2 row-span-2'/>,
                    <ImgPosition key={1} src={displayUrls[1]} className='col-span-2 row-span-1'/>,
                    <ImgPosition key={2} src={displayUrls[2]} className='col-span-2 row-span-1'/>
                ]
            case 4:
                return [
                    <ImgPosition key={0} src={displayUrls[0]} className='col-span-2 row-span-2'/>,
                    <ImgPosition key={1} src={displayUrls[1]} className='col-span-2 row-span-1'/>,
                    <ImgPosition key={2} src={displayUrls[2]} className='col-span-1 row-span-1'/>,
                    <ImgPosition key={3} src={displayUrls[3]} className='col-span-1 row-span-1'/>
                ]
            default:
                return [
                    <ImgPosition key={0} src={displayUrls[0]} className='col-span-2 row-span-2'/>,
                    <ImgPosition key={1} src={displayUrls[1]} className='col-span-1 row-span-1'/>,
                    <ImgPosition key={2} src={displayUrls[2]} className='col-span-1 row-span-1'/>,
                    <ImgPosition key={3} src={displayUrls[3]} className='col-span-1 row-span-1'/>,
                    <ImgPosition key={4} src={displayUrls[4]} className='col-span-1 row-span-1'/>
                ]
        }
    }, [post.displayUrls])

    return (
        <Card.Blurry className={cn(
            'p-2',
            'text-sm',
            props.className
        )}>
            <div className={cn(
                'w-full',
                'grid grid-cols-4 grid-rows-2 gap-0.5',
                'aspect-[2/1]',
                'rounded-xl overflow-hidden'
            )}>
                {ImageViews}
            </div>

            <div className={cn(
                'w-full flex justify-between items-center gap-x-2'
            )}>
                <span className='px-1 mb-0.5 text-xs text-slate-600'>{moment(post.timestamp).format('LL')}</span>
            </div>
        </Card.Blurry>
    )
}

