import { atom, useSetAtom } from 'jotai'
import { useCallback } from 'react'


export type AgreementSheetMode = 'create' | 'confirm'

export interface AgreementSheetProps {
    title?: string
    texts: string[]
    agreement: string
    confirm?: string
    cancel?: string
    onSelect?: (value: boolean) => void
}

export const agreementSheetPropsAtom = atom<AgreementSheetProps | null>()

export const useSheetAgreement = () => {
    const setAgreementSheetProps = useSetAtom(agreementSheetPropsAtom)

    const hideAgreementSheet = useCallback(() => setAgreementSheetProps(null), [setAgreementSheetProps])

    const showAgreementSheet = useCallback((props: AgreementSheetProps) => {
        setAgreementSheetProps(props)
    }, [setAgreementSheetProps])

    const showAgreementSheetAsync = useCallback((props: Partial<AgreementSheetProps>): Promise<boolean> => {
        return new Promise((resolve) => {
            setAgreementSheetProps({
                ...props,
                texts: props.texts ?? [],
                agreement: props.agreement ?? '',
                onSelect: (value) => {
                    try {
                        if (props.onSelect) {
                            props.onSelect(value)
                        }
                        resolve(value)
                    } catch (e) {
                        resolve(false)
                    }
                }
            })
        })
    }, [setAgreementSheetProps])

    const showPasscodeForgotAgreementAsync = useCallback(async () => {
        return await showAgreementSheetAsync({
            title: 'Forgot passcode?',
            texts: [
                'ZING Wallet is a non-custodial wallet, meaning ZING does not store your passcode. If you encounter issues recovering your wallet, you will need to reset it.',
                'For enhanced security, ZING divides your private key into 3 parts and stores them separately. If you forget your passcode, please reset it on the device where you last successfully signed in.'
            ],
            agreement: 'Signing out will remove all wallet information from this device.',
            confirm: 'Sign Out',
            cancel: 'Cancel'
        })
    }, [showAgreementSheetAsync])

    const showSignOutAgreementAsync = useCallback(async () => {
        return await showAgreementSheetAsync({
            title: 'Sign Out',
            texts: [
                'ZING Wallet is a non-custodial wallet, meaning ZING does not store your passcode.'
            ],
            agreement: 'Signing out will remove all wallet information from this device.',
            confirm: 'Sign Out',
            cancel: 'Cancel'
        })
    }, [showAgreementSheetAsync])

    const showOptOutAgreementAsync = useCallback(async () => {
        return await showAgreementSheetAsync({
            title: 'Stop Monetizing',
            texts: [
                'This will stop the use of data collected from the selected Data Channel, and you will no longer receive rewards for data utilization.'
            ],
            agreement: 'Would you like to continue?',
            confirm: 'Stop Monetizing',
            cancel: 'Continue'
        })
    }, [showAgreementSheetAsync])

    return {
        hideAgreementSheet,
        showAgreementSheet,
        showAgreementSheetAsync,
        showPasscodeForgotAgreementAsync,
        showSignOutAgreementAsync,
        showOptOutAgreementAsync,

    }
}
