import { useCallback } from 'react'
import { NavigateOptions, useNavigate } from 'react-router-dom'

import { KeyringState } from '../contexts/keyring/keyring-provider'
import { getFirstPathSegment } from '../libs/utils'
import { useKeyring } from './use-keyring'
import { useSheetAuth } from './use-sheet-auth'


const ProtectedPaths = [
    '/settings',
    '/adapter'
]

export const useSafeNavigate = () => {
    const navigate = useNavigate()
    const { state } = useKeyring()
    const { showAuthSheet } = useSheetAuth()

    const safeNavigate = useCallback((to: string, options?: NavigateOptions): boolean => {
        const segment = getFirstPathSegment(to)
        if (ProtectedPaths.includes(segment) && state !== KeyringState.KeyringLoaded) {
            showAuthSheet()
            return false
        }

        navigate(to, options)
        return true
    }, [navigate, showAuthSheet, state])

    return {
        safeNavigate
    }
}
