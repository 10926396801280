import { IChain } from '../types/blockchain.types'


export const ChainMap: { [key: string]: IChain } = {
    scroll: {
        id: 534352,
        name: 'Scroll',
        image: 'https://assets.coingecko.com/coins/images/50571/standard/scroll.jpg',
        platform: 'ethereum',
        currency: 'ETH',
        decimals: 18,
        endpoints: [
            'https://rpc.ankr.com/scroll',
            'https://rpc.scroll.io'
        ]
    },
    sepolia: {
        id: 11155111,
        name: 'Sepolia',
        image: 'https://assets.coingecko.com/coins/images/279/standard/ethereum.png',
        platform: 'ethereum',
        currency: 'SepoliaETH',
        decimals: 18,
        endpoints: [
            'https://sepolia.drpc.org',
            'https://sepolia.gateway.tenderly.co'
        ],
        testnet: true
    }
}

const Chains: IChain[] = Object.values(ChainMap)

export default Chains
