import { isEmpty } from 'lodash'
import React, { useCallback, useEffect } from 'react'
import { useNavigate, useParams, useSearchParams } from 'react-router-dom'

import { useToasts } from '../../hooks/use-toasts'
import { getRedirectUrlOf } from '../../libs/utils'
import Zing from '../../libs/zing'
import ViewPending from '../view-pending'


const ViewKeyringOAuth = () => {
    const navigate = useNavigate()
    const { provider } = useParams()
    const [searchParams] = useSearchParams()

    const { showOopsAlarm } = useToasts()

    const handleAuthProvider = useCallback(async (provider: string) => {
        switch (provider) {
            case 'google': {
                const code = searchParams.get('code')
                if (!code || isEmpty(code)) {
                    showOopsAlarm()
                    navigate('/', { replace: true })
                    return
                }

                const uid = await Zing.auth.authorize('google', code, {
                    redirect: getRedirectUrlOf('google')
                })

                if (!uid || isEmpty(uid)) {
                    showOopsAlarm()
                    navigate('/', { replace: true })
                    return
                }

                navigate('/keyring', { replace: true })
                break
            }
            case 'discord': {
                const code = searchParams.get('code')
                if (!code || isEmpty(code)) {
                    showOopsAlarm()
                    navigate('/', { replace: true })
                    return
                }

                const uid = await Zing.auth.authorize('discord', code, {
                    redirect: getRedirectUrlOf('discord')
                })

                if (!uid || isEmpty(uid)) {
                    showOopsAlarm()
                    navigate('/', { replace: true })
                    return
                }

                navigate('/keyring', { replace: true })
                break
            }
            case 'x': {
                const state = searchParams.get('state')
                const code = searchParams.get('code')
                const challenge = localStorage.getItem(getRedirectUrlOf('x'))
                if (state !== 'zing' || !code || isEmpty(code) || !challenge) {
                    showOopsAlarm()
                    navigate('/', { replace: true })
                    return
                }

                const uid = await Zing.auth.authorize('x', code, {
                    redirect: getRedirectUrlOf('x'),
                    challenge: challenge ?? ''
                })

                if (!uid || isEmpty(uid)) {
                    showOopsAlarm()
                    navigate('/', { replace: true })
                    return
                }

                localStorage.removeItem('keyring/x#challenge')
                navigate('/keyring', { replace: true })
                break
            }
            case 'reddit': {
                const state = searchParams.get('state')
                const code = searchParams.get('code')
                if (state !== 'zing' || !code || isEmpty(code)) {
                    showOopsAlarm()
                    navigate('/', { replace: true })
                    return
                }

                const uid = await Zing.auth.authorize('reddit', code, {
                    redirect: getRedirectUrlOf('reddit')
                })

                if (!uid || isEmpty(uid)) {
                    showOopsAlarm()
                    navigate('/', { replace: true })
                    return
                }
                navigate('/keyring', { replace: true })
                break
            }
            default:
                break
        }
    }, [navigate, searchParams, showOopsAlarm])

    useEffect(() => {
        if (!provider) {
            navigate('/', { replace: true })
            return
        }
        handleAuthProvider(provider)
    }, [handleAuthProvider, navigate, provider])

    return (
        <ViewPending/>
    )
}

export default ViewKeyringOAuth
