import { Icon } from '@iconify/react'
import React, { useCallback, useState } from 'react'

import Button from '../../components/button'
import Card from '../../components/card'
import Label from '../../components/label'
import Page from '../../components/page'
import Profile from '../../components/profile'
import Sheet from '../../components/sheet'
import { useBridgeClipboard } from '../../hooks/use-bridge'
import { useKeyring } from '../../hooks/use-keyring'
import { useSafeNavigate } from '../../hooks/use-safe-navigate'
import { useTokenWatchlist } from '../../hooks/use-token'
import { IToken } from '../../libs/types/blockchain.types'
import { cn } from '../../libs/utils'


export const ViewMainWallet = () => {
    const { safeNavigate } = useSafeNavigate()
    const { keyring } = useKeyring()
    const watchlist = useTokenWatchlist()

    const { clipboardSet } = useBridgeClipboard()

    const [openReceive, setOpenReceive] = useState(false)

    const handleOpenReceive = useCallback(() => setOpenReceive(true), [])

    const handleCopyAddress = useCallback(async () => {
        if (!keyring) return
        await clipboardSet(keyring.address, 'Address Copied!')
    }, [clipboardSet, keyring])

    return (
        <React.Fragment>
            <Page.AnimatedTab className='px-4 gap-y-4'>
                <div className={cn(
                    'w-full mt-24',
                    'flex flex-col justify-center items-center shrink-0'
                )}>
                    <Profile.Avatar avatar={keyring?.avatar} size='lg' className='shadow-lg'/>
                    <Profile.Name name={keyring?.name} size='xl' className='w-fit mt-1'/>
                    <Profile.Address address={keyring?.address} format='ellipsis6' className='w-fit text-sm font-semibold'/>
                </div>

                <Card.Blurry>
                    <div className={cn(
                        'w-full flex justify-between items-center'
                    )}>
                        <Label.Section text={'Wallet'} subtext={'Manage your data with your wallet'} className='ml-2'/>
                        <Button.Solid className={cn(
                            'flex justify-center items-center gap-x-1'
                        )} onClick={handleOpenReceive}>
                            <Icon icon='mage:qr-code' className='size-6'/>
                        </Button.Solid>
                    </div>

                    <Button.Solid className={cn(
                        'w-full h-11 flex justify-center items-center gap-x-1',
                        'mt-4'
                    )} onClick={handleCopyAddress}>
                        <Icon icon='mingcute:copy-2-line' className='size-6'/>
                        <span className='text-sm'>Copy Address</span>
                    </Button.Solid>
                </Card.Blurry>

                <div className={cn(
                    'w-full',
                    'flex flex-col justify-center items-center gap-y-2 shrink-0'
                )}>
                    {
                        watchlist.map((token: IToken) => {
                            return <Card.Token key={token.id} token={token}/>
                        })
                    }
                </div>
            </Page.AnimatedTab>

            <Sheet.Receive open={openReceive} onDismiss={() => setOpenReceive(false)}/>
        </React.Fragment>
    )
}
