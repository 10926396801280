import { AnimatePresence } from 'framer-motion'
import React, { useCallback, useEffect, useMemo, useState } from 'react'

import { useActiveAdapter } from '../../hooks/use-adapter'
import { snooze } from '../../libs/utils'
import ViewPending from '../../views/view-pending'
import { IgPageCollect } from './ig.page-collect'
import { IgPageConnected } from './ig.page-connected'
import { IgPageConnecting } from './ig.page-connecting'
import { AdapterIgState } from './ig.types'


export const AdapterIgSteps = () => {
    const [state, setState] = useState(AdapterIgState.Undetermined)
    const [forward, setForward] = useState(true)
    const { profile } = useActiveAdapter()

    const goto = useCallback((next: number) => {
        setForward(next >= state)
        setState(next)
    }, [setState, state])

    const activeView = useMemo(() => {
        switch (state) {
            case AdapterIgState.Connecting:
                return <IgPageConnecting goto={goto} forward={forward}/>
            case AdapterIgState.Connected:
                return <IgPageConnected goto={goto} forward={forward}/>
            case AdapterIgState.Collect:
                return <IgPageCollect goto={goto} forward={forward}/>
            default:
                return <ViewPending/>
        }
    }, [forward, goto, state])

    const handleStates = useCallback(async () => {
        switch (state) {
            case AdapterIgState.Undetermined:
                await snooze(800)
                setState(AdapterIgState.Collect)
                break
            case AdapterIgState.Connecting:
                setState(AdapterIgState.Connected)
                break
            default:
                break
        }
    }, [state])

    useEffect(() => {
        if (!profile) {
            setState(AdapterIgState.Connecting)
            return
        }

        handleStates()
    }, [handleStates, profile])

    return (
        <AnimatePresence initial={false} custom={forward}>
            {activeView}
        </AnimatePresence>
    )
}
