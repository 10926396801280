import { HDNodeWallet, Wallet } from 'ethers'
import { AnimatePresence } from 'framer-motion'
import React, { useCallback, useEffect, useMemo, useState } from 'react'
import { useNavigate } from 'react-router-dom'

import Page from '../../components/page'
import { KeyringState } from '../../contexts/keyring/keyring-provider'
import { useKeyring } from '../../hooks/use-keyring'
import { useToasts } from '../../hooks/use-toasts'
import { Avatars } from '../../libs/avatars'
import logger from '../../libs/helper/helper.logger'
import { Keyring } from '../../libs/types/account.types'
import { randomOf } from '../../libs/utils'
import Zing from '../../libs/zing'
import { PageCreate } from './page-create'
import { PageRecover } from './page-recover'
import { PageReview } from './page-review'


enum KeyringCreateSteps {
    Recover,
    Create,
    Review
}

const ViewKeyringCreate = () => {
    const navigate = useNavigate()
    const { keyring, initialize } = useKeyring()
    const { showOopsAlarm } = useToasts()

    const [step, setStep] = useState(KeyringCreateSteps.Recover)
    const [wallet, setWallet] = useState<HDNodeWallet>(Wallet.createRandom())
    const [avatar, setAvatar] = useState('')
    const [done, setDone] = useState(false)

    const handleShuffle = useCallback(() => {
        const r1 = randomOf(Avatars.icon.length - 1)
        const r2 = randomOf(Avatars.colors.length - 1)
        setAvatar(`${Avatars.icon[r1]}/${Avatars.colors[r2]}`)
        setWallet(Wallet.createRandom())
    }, [])

    const checkKeyringState = useCallback(async () => {
        const user = Zing.auth.currentUser()
        if (!user) {
            navigate('/', { replace: true })
            return
        }

        const state = await initialize()
        switch (state) {
            case KeyringState.KeyringLoaded:
                navigate('/', { replace: true })
                break
            case KeyringState.KeyringRequired:
                setStep(KeyringCreateSteps.Create)
                break
            default:
                break
        }
    }, [initialize, navigate])

    const handleCreate = useCallback(async (name: string, passcode: string) => {
        const user = Zing.auth.currentUser()
        if (!user || !wallet || !wallet.mnemonic) return

        const keyring: Keyring = {
            address: wallet.address,
            avatar: avatar,
            name: name,
            uid: user.uid,
            s0: wallet.mnemonic.entropy
        }

        try {
            setStep(KeyringCreateSteps.Review)
            const result = await Zing.auth.createAccount(keyring, passcode)
            if (!result) {
                showOopsAlarm()
                return
            }

            await initialize()
            setDone(true)
        } catch (e: any) {
            logger.error('ViewKeyringCreate/onboarding', e)
            showOopsAlarm()
        }
    }, [avatar, initialize, showOopsAlarm, wallet])

    const renderPage = useCallback(() => {
        switch (step) {
            case KeyringCreateSteps.Recover:
                return <PageRecover onRecover={checkKeyringState}/>
            case KeyringCreateSteps.Create:
                return <PageCreate avatar={avatar} onShuffle={handleShuffle} onNext={handleCreate}/>
            case KeyringCreateSteps.Review:
                return (
                    <PageReview keyring={keyring} authenticated={done} onNext={() => {
                        navigate('/', { replace: true })
                    }}/>
                )
        }
    }, [avatar, checkKeyringState, done, handleCreate, handleShuffle, keyring, navigate, step])

    const [title, back] = useMemo(() => {
        switch (step) {
            case KeyringCreateSteps.Recover:
                return ['', false]
            default:
                return ['Create Wallet', true]
        }
    }, [step])

    useEffect(() => {
        handleShuffle()
        checkKeyringState()
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    return (
        <Page.Nested back={back} to={'/'} icon={'mingcute:close-line'} title={title}>
            <AnimatePresence initial={false}>
                {renderPage()}
            </AnimatePresence>
        </Page.Nested>
    )
}

export default ViewKeyringCreate
