import { isEmpty } from 'lodash'
import React, { HTMLAttributes, useCallback, useEffect, useState } from 'react'

import { Adapter } from '../../adapter/adapter.types'
import { AppState, useBridgeApp } from '../../hooks/use-bridge'
import { useUserAgent } from '../../hooks/use-user-agent'
import { cn } from '../../libs/utils'
import { ButtonIcon } from '../button/button-icon'
import { CardBlurry } from './card-blurry'


export interface CardAppProps extends HTMLAttributes<HTMLDivElement> {
    app?: Pick<Adapter, 'icon' | 'name' | 'description' | 'scheme'>
    flat?: boolean
}

export const CardApp = ({
    app,
    flat,
    ...props
}: CardAppProps) => {
    const { appCheck, appLaunch, appUri } = useBridgeApp()
    const [appState, setAppState] = useState(AppState.Unknown)
    const { isApple, isAndroid } = useUserAgent()

    const handleAppCheck = useCallback(async () => {
        if (!app) return
        setAppState(await appCheck(app.scheme))
    }, [app, appCheck])

    const handleAppLaunch = useCallback(async () => {
        if (!app) return
        await appLaunch(app.scheme)
    }, [app, appLaunch])

    const handleLink = useCallback(async (link?: string) => {
        if (isEmpty(link)) return

        await appUri(link)
    }, [appUri])

    const renderActionView = useCallback(() => {
        switch (appState) {
            case AppState.Installed:
                return (
                    <ButtonIcon text={'Open'} icon={'heroicons:arrow-top-right-on-square-solid'}
                                reverse={true}
                                onClick={handleAppLaunch}
                                className='shrink-0'
                    />
                )
            case AppState.NotInstalled:
                if (isApple && isEmpty(app?.scheme.apple)) {
                    return (
                        <ButtonIcon
                            icon={'mdi:apple'} reverse={true}
                            className='stroke-0 text-slate-600 px-4 shadow-md'
                            onClick={() => handleLink(app?.scheme.appStore)}
                        />
                    )
                }

                return (
                    <ButtonIcon text={'Install'} icon={'heroicons:arrow-down-tray-solid'}
                                onClick={handleAppLaunch}
                                className='shrink-0'
                    />
                )
            default:
                return (
                    <div className='flex items-center gap-2 shrink-0'>
                        <ButtonIcon
                            icon={'mdi:apple'} reverse={true}
                            className='stroke-0 text-slate-600 px-4 shadow-md'
                            onClick={() => handleLink(app?.scheme.appStore)}
                        />
                        <ButtonIcon
                            icon={'mdi:google-play'} reverse={true}
                            className='stroke-0 text-slate-600 px-4 shadow-md'
                            onClick={() => handleLink(app?.scheme.googlePlay)}
                        />
                    </div>
                )
        }
    }, [app?.scheme.appStore, app?.scheme.apple, app?.scheme.googlePlay, appState, handleAppLaunch, handleLink, isApple])

    useEffect(() => {
        handleAppCheck()
    }, [handleAppCheck])

    return (
        <CardBlurry className={cn(
            'text-sm gap-y-4',
            flat ? 'ring-0 shadow-none p-0' : '',
            'overflow-visible',
            props.className
        )} onClick={props.onClick}>
            <div className={cn(
                'w-full flex items-center gap-x-2'
            )}>
                <div className={cn(
                    'size-10 aspect-square',
                    'flex justify-center items-center',
                    'overflow-hidden',
                    'bg-slate-300/30',
                    'shrink-0',
                    'shadow-md',
                    'rounded-xl'
                )}>
                    {app?.icon && <img src={app?.icon} alt={''}/>}
                </div>
                <div className='grow flex flex-col justify-center items-start'>
                    <h3 className='text-base font-semibold text-slate-900 leading-tight'>{app?.name}</h3>
                    {app?.description && (
                        <span className='text-sm text-slate-900 leading-tight'>{app?.description}</span>
                    )}
                </div>

                {renderActionView()}
            </div>
        </CardBlurry>
    )
}
